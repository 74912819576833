.page-user .tabs {
  margin-bottom: 0;
}
.page-user #content form {
  overflow: hidden;
  border: none;
  padding: 1em 0 2em;
  .form-text {
    width: 100%;
    @include bp-nq($medium) { width: auto; }
  }
}

