%listing {

  h3 {
    line-height: 1.3em;
    margin-top: 0;
    color: #1f4ea1;
    font-weight: 500;
    margin-bottom: 0.2em;
    font-size: 1.15em;

    a {
      font-weight: 400;
      color: #1f4ea1;
      &:hover {
        text-decoration: none;
        color: #333;
      }
    }
  }
  .field-body {
  	font-size: 0.933em;
  	line-height: 1.428em;
    display: block;
  }
  .field-datebox {
    color: #9c9c9c;
    font-size: 0.86667em;
    line-height: 1.84615em;
    font-family: "Open Sans", sans-serif;
  }
    .field-date {
     @include adjust-font-size-to(11px);
    font-family: $base-font-family;
  }
    .field-comment a {
    color: #9c9c9c;
     @include adjust-font-size-to(13px);
    padding-left: 20px;
    background: transparent url("../images/bubble.png") no-repeat scroll left 1px;
    margin-left: 0.6em;

      &:hover {
        background-position: left -25px;
        color: #ef4d64;
        text-decoration: none;
    }
  }
  .field-teaser-icon,
  .field-feature-image,
  .field-image {
    margin-bottom: 5px;

    img {

    }
  }

}

%listing--header {
  @include adjust-font-size-to(14px);
  padding-bottom: rhythm(.25);
}

.view-events,
.view-news,
.view-news-by-topic,
.front {
  .views-row {

    h3 {
      margin-bottom: 0;
    }
  }
}

#block-views-success-stories-block {
   .view .views-row {
    h3 {
      @include adjust-font-size-to(15px);
      line-height: 1.3em;
          margin-bottom: 0.3em;
    }
   .field-image {
    padding-bottom: 5px;
   }
 }
}
