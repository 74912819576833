div.workbench-info-block {
  padding: 1em;
  line-height: 1.5em;
  border-style: solid;

   select, input {
   	width: auto;
   }
}

#admin-menu-icon {
	li img {
	 width: 15px;
	 height: 15px;
	}
}

html body.admin-menu {
  @media all and (max-width: 331px) {
    margin-top: 101px !important;
  }
  @media all and (min-width: 332px) and (max-width: 462px) {
    margin-top: 77px !important;
  }
  @media all and (min-width: 463px) and (max-width: 862px) {
    margin-top: 58px !important;
  }
}

#admin-menu, #admin-menu .dropdown {
  z-index: 9999;
}

