ul.sf-menu > li:hover > ul,
ul.sf-menu > li.sfHover > ul {
  left: 0;
  top: 45px;

  .large-fonts & {
    top: 47px;
  }
}


.region-navigation {

    .band {
       .not-front & {
          @include blue-bg();
          height: 10px;
          clear: both;
       }
    }

    .contextual-links-region {
      position: static;
    }
}
ul.sf-menu.sf-accordion.sf-expanded {
    background-color: #efefef;
    position: relative;
    z-index: 100;
    font-size: 1.066em;
    border-top: 3px solid #8CC53F;
}
@media all and (min-width: 680px) {
    ul.sf-menu.sf-accordion.sf-expanded {
     background-position: right -85px top;
   }
}

#superfish-1-toggle {
  background: #efefef url("../images/hamburger.svg") no-repeat scroll center center;
  display: block;
  height: 38px;
  width: 38px;
  text-indent: -9999px;
  overflow: hidden;
  outline: none;
  background-size: 28px 28px;

    &.sf-expanded {
      background-size: 16px 16px;
      background-color: #8CC53F;
      background-image: url("../images/hamburger-close.svg");
    }
}

#superfish-1 {
  display:none;

  @include bp-nq($medium) {
    display: block;
  }
}

ul.sf-menu.sf-accordion {
  left: -9999px;
    li.menuparent > a.sf-accordion-button {
    background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll center center;
    background-size: 10px 10px;
    display: block;
    text-indent: -9999px;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 53px;
    height: 53px;
    z-index: 5000;
  }
  li {
    position: relative;
  }
  li a {
    &.sf-depth-1,
    &.sf-depth-2,
    &.sf-depth-3,
    &.sf-depth-4,
    &.sf-depth-5 {
    padding: 20px 15px 20px;
    color: #1f4ea1;
    outline: none;
    border-bottom: 1px solid #d7d6db;

        &.active {

      }
          &:hover,
          &:active {
        color: #5c626c;
      }
    }
  }
  li.sf-expanded {
    > a.sf-accordion-button {
      background-image: url("../images/blue-arrow-down.svg");
    }
    a {
      background-color: #efeff0;
      border-bottom-color: #d7d6db;
    }
    > ul > li a {
      padding-left: 30px;
      color: #8cc63e;
      font-size: 0.9em;

       &:hover,
       &.active {
        color: #6db111;
       }
    }
  }
  li li.sf-expanded {
    a {
    }
    > ul > li a {
      padding-left: 37px;
      color: #5c626c;

        &::before {
          content: "– ";
        }

        &:hover,
        &.active {
          color: #000;
        }
      }
  }
  li li li.sf-expanded {
    a {

    }
    > ul > li a {
      padding-left: 49px;

        &:hover,
        &.active {
          color: #000;
        }
    }
  }
}

li#menu-613-1 {
  > ul {
    display: none !important;
  }
}

// Used for calculation is sub-menu below.
$firstLiPaddingX: 15px;

#block-superfish-1 {
  margin-top: 0;
  margin-bottom: 0;

  .layout-mobile &,
  .layout-tablet & {
    position: absolute;
    left: 0;
    right: 0;
    top: 6.4em;
  }
  .layout-tablet & {
     top: 8.5em;
  }
  .large-fonts.layout-tablet & {
    top: 8em;
  }
  #superfish-1-toggle {
      .layout-mobile &,
      .layout-tablet & {
    position: absolute;
    }
      .layout-mobile & {
        top: -2.9em;
        left: 12px;
      }
      .layout-tablet & {
        top: -2.83em;
        right: 12px;
        left: auto;
      }
      .large-fonts.layout-tablet & {
        top: -2.2em;
      }
  }
}
#superfish-1 {
  position: relative;

  > li {
    @media all and (max-width: 1100px) {
      position: static;
    }
  }

  ul.sf-megamenu {
    font-size: 0.9em;
    max-width: 711px;

     li.sf-megamenu-wrapper {
       max-width: 711px;
       > ol {
        display: flex;
        flex-wrap: wrap;
        width: 711px !important;

        > li {
          float: none;
          border-bottom: 1px solid #d7d6db;
          width: 33.33% !important;
          padding-bottom: 1em;
        }
      }
     }
  }

  ul.sf-megamenu {
    background-color: #efeff0;
    border-bottom: 7px solid #8dc73f;
  }
  .sf-depth-2 .sf-megamenu-column > a,
  .sf-depth-2 > a {
    color: #8cc63e;

     &.active,
     &:hover {
      color: #6db111;
     }
  }
    > li {
    &.sfHover > a {
       color: #1f4ea1;
       border-bottom-color: #8dc73f;
    }
    &.active-trail > a {
        color: #1f4ea1;
        border-bottom-color: #8dc73f;
    }
    > a {
      padding: 12px 6px 16px;
      color: #5c626c;
       line-height: 1em;
      border-bottom: 3px solid transparent;
        @media all and (min-width: 840px) {
          padding-left: 8px;
          padding-right: 8px;
          font-size: 1.0769em;
        }
        @include bp-nq($large) {
          padding-left: 18px;
          padding-right: 18px;
          font-size: 1.0769em;
        }
        @include bp-nq($xlarge) {
          font-size: 1.1538em;
          padding-left: 29px;
          padding-right: 29px;
        }

        &:hover {
           color: #1f4ea1;
           border-bottom-color: #8dc73f;
        }
        .large-fonts & {
          padding: 13px 7px;
          font-size: 0.8em;

          @media all and (min-width: 900px) {
            padding-left: 13px;
            padding-right: 13px;
          }
          @include bp-nq($xlarge) {
            font-size: 1.07em;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
    }
    li.sf-depth-2 .sf-megamenu-column ol li {
      ul {
        display: none !important;
      }
        a {
        background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 0 center;
        background-size: 8px 8px;
        margin-left: 12px;
        padding: 4px 0 3px 17px;
        font-size: 1em;

         &:hover,
         &.active {
          color: #000;
         }
      }
    }

    li a {
      color: #5c626c;
      font-size: 1.1538em;
      line-height: 1.35em;
      padding: 8px 12px;

        &:hover {

        }
    }
    li.menuparent > a {
        &:hover {
          background-position: right 15px top -253px;
          background-color: #534c4b;
          color: #fff;
        }
    }
  }
}

.block-superfish li#menu-2664-1 {
  float: right;
  > a {
  background-color: #93c74e;
  border-left-color: #93c74e;
  border-right-color: #93c74e;
  border-top-color: #93c74e;
  border-bottom: 3px solid #fff;
  color: #fff;

     &:hover {
      background-color: #83bd38;
      border-color: #83bd38;
      border-bottom-color: #fff;
     }
  }
}

#superfish-1 li#menu-2664-1 {
  a {
    padding-bottom: 11px;
  }
}

/* First-level Menu */

.region-navigation .block__content > .menu {
  li a {
    display: block;
    //color: #F4F4F4;

      &:hover {
         text-decoration: none;
      }
  }
  > li {
    // Remove border on hover, sfHover, active link, and first link.
    &.sfHover, &:hover, &.active-trail {
      &, & + li { // removes this border as well as ajacent border
        a:before {
          border: 0;
        }
      }
    }
  }
}

#wrapper {
  clear: both;
}



