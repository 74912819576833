/**
 * Pager.
 */

/* A list of page numbers when more than 1 page of content is available. */

ul.pager {
  list-style: none;
  margin-left: 0;
  clear: both;
  text-align: center;
  padding-top: 1em;
  border-top: 1px solid #d7d6db;

    .date-nav & {
      border: none;
      padding: 0;
    }
}
%pager__item {
  display: inline-block;
  list-style-type: none;
  background-image: none;

   a {
    display: inline-block;
    padding: 0 rhythm(.25);

     &:hover {
      color: #fff;
      background-color: #5c626c;
      text-decoration: none;
     }
   }
}

.pager-item,      /* A list item containing a page number in the list of pages. */
.pager-first,     /* The first page's list item. */
.pager-previous,  /* The previous page's list item. */
.pager-next,      /* The next page's list item. */
.pager-last,      /* The last page's list item. */
.pager-ellipsis { /* A concatenation of several list items using an ellipsis. */
  @extend %pager__item;
}

/* The current page's list item. */
.pager-current {
  @extend %pager__item;
  color: #fff !important;
  background: $color-primary;
  padding: 0 rhythm(.25);
}