
.search-results {

  p {
  margin: 0;
  }
  li {
    @extend %listing;
    padding: 12px;
  }

  h3.title {

    a {
      @include adjust-font-size-to(14px);
    }
  }

  .search-snippet-info {
    padding: 0;
    font-style: italic;
  }
}

@mixin search-form {
  @include pie-clearfix;
  padding: 15px;
  background-color: $color-tertiary-light;
  border-top: solid 3px $color-primary;

  label {
    float: left;
    margin-right: 15px;
    margin-bottom: 0px;
    margin-top: 0.6em;
  }
  fieldset {
    display: none;
  }
  .search-form-description {
    font-size: 1.1em;
  }
}

.search-form {
  @include search-form();

  .form-text {
    float: left;
    min-width: 75%;
    @include bp-nq($medium) { min-width: 50%; }
    margin: rhythm(.5) 0 0;
  }
}

.view-search form {
  @include search-form();

  label {
    margin-top: 5px;
  }

  .views-exposed-form,
  .views-exposed-widget {
    padding: 0;
  }

  .views-widget-filter-keys {
    display: inline-block;
    min-width: 75%;
    @include bp-nq($medium) { min-width: 50%; }
  }

  .views-widget-filter-keys input {
    float: left;
    width: 100%;
    margin: rhythm(.5) 0 0;
  }
  .views-submit-button {
    padding: 0;

    input {
      clear: left;
      height: 2.1em;
      margin: 0.8em;
      display: inline-block;
      @include adjust-font-size-to(12px);
      @include bp-nq($large) { clear: none; }
    }
  }

  .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 2.7em;
  }
}

#search-api-page-search-form {
  position: relative;
  form > div {
    @include bp-nq($minor) {
      input.form-text {
         margin-right: 1em;
      }
    }
  }
  .block-inner {
    position: relative;
  }
  .form-item {
    margin-bottom: 0.75em;
    display: inline-block;
    width: 100%;
     @include bp-nq($minor) {
      width: calc(100% - 12.5em);
      margin-right: 1em;
    }
  }
  .reset-search {
    font-size: 0.9em;
    position: absolute;
    bottom: 0.75em;
    left: 80px;
    @include bp-nq($minor) {
      left: auto;
      right: 0;
      bottom: 1.5em;
    }

    a {
      color: #1f4ea1;

       &:hover {

       }
    }
  }
}


.current-search-item-active ul li{
        list-style: none;
        font-size: 1.1em;

         a .close {
          padding: 0 5px;
          float: left;
          position: relative;
          top: -2px;
          display: block;
          color: #f22e2e;
         }
}

.block-facetapi {
    ul li {
        list-style: none;
        font-size: 1.1em;

         a .close {
          padding: 0 5px;
          float: left;
          position: relative;
          top: -2px;
          display: block;
          color: #f22e2e;
         }
    }
    ul.facetapi-search-api-glossary {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        li {
            font-size: 1.1em;
            list-style: none;
            flex: 0 20%;
            color: #c1c1c1;
            background-color: #16c1f3;
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
            text-align: center;

              &:hover {
                background-color: #24ace0;
              }
             a {
             color: #fff;
             display: block;

              &:hover {
                text-decoration: none;
              }
         }

            &.no-content {
            background-color: #fff;
            }
            &.active {
            background-color: #24ace0;
            color: #fff;

                a {
                    .close {
                        display: none;
                    }
                }
                &.no-content {
                    padding: 5px;
                }
            }
        }
    }
}


.header div.block-search-api-page {
  form .form-submit {
    position: absolute;
    right: 3px;
    text-indent: -1000px;
    background: url("../images/mag-glass.svg") 50% 50% no-repeat;
     background-color: #6d9902;
     background-size: 12px 12px;
    width: 23px;
    height: 23px;
    margin-right: 0;
    border: none;

     .large-fonts & {
      background-size: 16px 16px;
      right: 7px;
      top: 8px;
     }

    &:active {
      background-color: darken($color-highlight, 5%);
    }
  }
}

ol.search-results {
  li {
    list-style: none;
  }
}

.block-facetapi {
   @media all and (max-width: 767px) {
    display: none;
   }
}