.not-front {
  #block-menu-block-2 {
    h2.block__title {
       line-height: 1.3em;
      @media all and (max-width: 767px) {
       @include button-blue();
       text-align: left;
       font-size: 1.3em;
       text-indent: 1em;
         a {
          color: #fff;
         }
      }
        @include bp-nq($medium) {
        border-bottom: 5px solid #4eb856;
        a {
          color: #1f4ea1;
            &:hover {
              text-decoration: none;
              color: #333;
            }
        }
      }
    }
    .block__content {
     padding: 0;
     @media all and (max-width: 767px) {
        background-color: #efefef;

        ul.menu {
         margin: 0;
       }
      .menu-block-wrapper > ul.menu {
          margin-top: 0;
          > li {
            border-bottom: 1px solid #d7d6db;
            > a {
            color: #8cc63e;
            background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left 16px center;
            background-size: 10px 9px;

           &:hover,
           &.active {
              color: #6db111;
              text-decoration: none;
              }
            }
            &.active {
              color: #6db111;
            }
        }
        li {
          list-style: none;
           a {
            padding: 6px 15px 6px 37px;
          }
        }
        li li a {
          padding-left: 40px;
          color: #5c626c;

            &::before {
              content: "– ";
           }

            &:hover,
            &.active {
            color: #000;
           }
        }

        li li li a {

            &::before {
              content: "○ ";
           }

          padding-left: 52px;
          font-size: 0.9em;
        }

        li li li li a {

            &::before {
              content: "- ";
           }

          padding-left: 52px;
          font-size: 0.9em;
        }
      }
    }
     @include bp-nq($medium) {


    .menu-block-wrapper > ul.menu {
      > li {
        border-bottom: 1px solid #d7d6db;
        > a {
        background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left 9px center;
        background-size: 10px 9px;
      }
      > ul > li.is-expanded > ul {
         //border-bottom: 1px solid #d7d6db;
      }
    }
      ul.menu {
       margin: 0;
     }
      li {
          list-style: none;
          a {
            display: block;
            padding: 6px 15px 6px 27px;
            line-height: 1.7em;
             @include adjust-font-size-to(15px);
            color: #424242;
            outline: none;
            font-weight: normal;
           &.active {
            color: #8cc63e;
           }
           &:hover {
            background-color: #f5f9eb;
            color: #8cc63e;
            }
          }
        }
        li li a {
          padding-left: 30px;

            &::before {
              content: "– ";
             }
           @include adjust-font-size-to(14px);
           &:hover,
           &.active {
            background-color: #c9e4ec;
            color: #1f4ea1;
            }
          }
        li li li a {
          padding-left: 40px;

              &::before {
              content: "○ ";
             }
         &:hover,
         &.active,
         &.active-trail {
          background-color: #daf4fc;
          }
          }
        li li li li a {
           @include adjust-font-size-to(13px);
          padding-left: 50px;

              &::before {
              content: "– ";
             }
         &:hover,
         &.active,
         &.active-trail {
          background-color: #daf4fc;
          }
          }
        }
      }
      }
    }
  }

#block-menu-block-3 {
  .menu-block-wrapper > ul.menu > li {
    ul.menu {
      margin: 0;
      padding: 0;
    }
    > a {
      background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 5px center;
      background-size: 10px 9px;
      padding-left: 1.5em;
    }
    a {
      outline: none;
      line-height: 1.8em;
       padding-left: 1.5em;
    }
    li a {
       padding-left: 2.2em;
      &::before {
        content: "– ";
      }
    }
  }
}


.title-underline {
  .block__title {
    margin-top: 0;
    border-bottom: 1px solid #d7d6db;
    color: #8cc63e;
    font-weight: 400;
    line-height: 1.25em;
    padding-bottom: 0.2em;
    margin-bottom: 0.5em;

    a {
      color: #8cc63e;
    }
  }
}

.region-content-bottom,
.sidebars {
    .block-inner {
       ul.menu {
          @extend %linksArrow;
    }
  }
}

#block-qmhc-social-links {
  .block__content {
    display: flex;
    margin-top: 0.75em;

    .front & {
     @include bp-nq($xlarge) {
      text-align: left;
     }
    }
    .not-front & {
      @include bp-nq($large) {
     }
    }
  }
  .social-link {
    width: 2.666em;
    float: left;
    margin-right: 0.6em;

    img{
    vertical-align: middle;
    display: inline;
    transition: all .2s ease-in-out;

     &:hover {
      transform: scale(1.1);
     }
    }
    a {
      color: #323232;
      outline: none;
      display: block;
       @include adjust-font-size-to(14px);

       &:hover {
        color: #000;
        text-decoration: none;
       }
    }
  }
}

.box-green {
  .block-inner {
    background: #e8f4da url("../images/bg-logo-green.svg") no-repeat scroll bottom right;
    padding: 12px 12px 24px;
    position: relative;
    background-size: 80px 80px;

     h2 {
      border-bottom: none;
      color: #1f4ea1;
      font-size: 1.8461em;
      line-height: 1.1em;
      margin-top: 0.25em;
     }
     .block__content {
      font-size: 1.1538em;
     }
     a.button-blue {
      position: absolute;
      left: 0;
      right: 0;
      font-size: 0.8666em;
     }
  }
}


.more-wrap {
   @include footer-link();
}

#block-views-events-block-1 {
   .view-display-id-block_7 {
     .field-title {
       a:hover {
        text-decoration: underline;
       }
     }
   }
}

#block-views-news-block-1 {
  margin-bottom: 0;
  .block-inner {

    h2.block-title {
        border: none;
        padding: 10px 0 2px 12px;
        line-height: 1em;
       }
    }
  h3.field-title {
    display: inline;

    a:hover {
      text-decoration: none;
    }
   }
   .field-date {
    color: #24ace0;
    padding-left: 0.3em;
    font-size: 1em;
    display: inline;
   }
  .view .view-footer {
    @include footer-link();
   }
  .field-wrap {
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 100%;

     img {
      border: none;
      padding: 0;
      margin: 0 auto;
     }
  }
  .more-wrap {
    padding-bottom: 12px;
  }
}


.block-slider {
   .slick__slider {

      .slide__content {
        padding: 0;
      }

      .u-faux-block-link {
        padding: 12px;
        &:hover {
           img {
            @include image-hover();
           }
        }
      }
   }
  .slick__arrow {
    @include control-nav();
  }
  .view-footer {
    @include footer-link();
  }
}

#block-views-sector-news-block-4 {
  .slick__slider {
    background-color: #f5f9eb;
  }
    .field-image-wrap {
    position: relative;
    margin-bottom: 0.5em;
    background-color: #fff;

       .field-date,
      .field-news-type {
        @include green-box();
        bottom: 0;
        left: 1px;
      }
  }
}

#block-views-news-block-4 {
   .slick__slider {
    background-color: #EEF9FD;
   }
    .field-image-wrap {
      position: relative;
      background-color: #fff;

        @include bp-nq($medium) {

        }
    }
      h4.field-title {
         margin-top: 0.3em;
         margin-bottom: 0.3em;
       }
      .field-date {
         @include green-box();
        bottom: 0;
        left: 0;
      }
      .field-body {
         a {
          @include read-more();
         }
       }
  }

h4.field-title {
  font-weight: normal;
  margin: 0 0 0.5em;
}

.sidebar-block,
.section-block {

  .views-row > div {
    position: relative;
    background-color: #efefef;
     .inner {
    padding: 12px;
    background: transparent url("../images/bg-logo-white.svg") no-repeat scroll bottom right;
   background-size: 80px 80px;
     }
  }
  .block__content ul {
    padding-left: 24px;
   }
  .field-body {
    font-size: 1.1538em;
  }
  h3.field-title {
    border-bottom: none;
    color: #1f4ea1;
   @include adjust-font-size-to(24px);
    line-height: 1.1em;
    margin-top: 0.25em;
   }
   .button-blue {
      a {
        color: #fff;
        text-decoration: none;

         &:hover {

         }
      }
   }
}


.block .view-sidebar-block  .bg-colour-1 {
  background-color: #f5f9eb;

   .inner {
    background-image: url("../images/bg-logo-green.svg");
   }
}
.block .view-sidebar-block .bg-colour-2 {
    background-color: transparent;
   @include background-image(linear-gradient(#8dc44a, #53b75c));

    .inner {
      background-image: url("../images/bg-logo-dark-green.svg");
      color: #fff;
    }
}

#block-views-images-media-centre {
   .slick__slider {
    background-color: #EEF9FD;
   }
    .field-image-wrap {
      position: relative;
      background-color: #fff;
      padding: 0 20px 26px;
      min-height: 9em;
    }
      h4.field-title {
         margin-top: 0.3em;
         margin-bottom: 0.3em;
       }
      .field-date {
         @include green-box();
        bottom: 0;
        left: 0;
      }
      .field-body {
         a {
          @include read-more();
         }
       }
  }