.block-filter {
  margin-bottom: 0 !important;
}

#block-views-commissioners-update-block-1,
#block-views-events-block-1 {
  .field-date {
    @include green-box();
  }
  .slick__arrow {
    @include control-nav();
  }
}

#block-block-6 {
  .block-inner {
    background: #e5f6fd;
    padding: 4px 12px 15px; 

    @media all and (max-width: 768px) {
      padding-top: 12px;
    }


    .sane-wrap {
      display: flex;
      .sane-text {
        display: flex;
        align-items: center;
        color: #1f4fa1;
        font-size: 17px;
        padding-right: 10px;
        flex: 1;
      }
      .sane-button {
        a {
          display: block;
          width: 66px;
          height: auto;
        }
      }
    }
    h2 {
      color: #00abf0;
      font-size: 22px;
      line-height: 1.3em;
      margin: 0;

      @media all and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}

#block-block-3 {
  .block-inner {
    background: #f5f9eb;
    padding: 15px 12px;

    .not-front & {
      padding: 4px 12px 15px;

      @media all and (max-width: 768px) {
         padding: 15px 12px;
      }
    }

    .hs_recaptcha {
      display: none;
    }
    input[type=submit] {
    @include input-round();
    width: 3em;
    border: none;

      &:hover {
        background-color: #21a4d6;
      }
    }
    #em_subscribe_form {
      width: 100%;
      font-size: inherit !important;
    }
    form {
      padding: 0.5em 0;
      font-size: 14px;
      width: auto !important;
      display: flex;
      align-items: center;

       .front & {
        padding: 0;
       }

        label {
            display: none;
        }

        .hs_error_rollup,
        .hs-error-msgs {
          display: none !important;
        }
       .not-front & {
        flex-wrap: wrap;
       }

        > div:first-child {
          font-size: 1.2em;
          .not-front & {
            @media all and (max-width: 520px) {
              flex: 0 100%;
              width: 100%;
              max-width: 100%;
            }
            @include bp-nq($medium) {
              flex: 0 0 100%;
              width: 100%;
              max-width: 100%;
           }
          }
          .front & {
          width: 40%;
          max-width: 40%;
          flex: 0 0 40%;

            @include bp-nq($medium) {
              width: 41%;
              max-width: 41%;
              flex: 0 0 41%;
            }
            @include bp-nq($large) {
              width: 50%;
              max-width: 50%;
              flex: 0 0 50%;
            }
          }
        .front.large-fonts & {
            @include bp-nq($large) {
              width: 40%;
              max-width: 40%;
              flex: 0 0 40%;
            }
         }
        }
        .hs_email {
          flex: 1 0 auto;
            input {
              border: none;
              line-height: 3em;
              height: 3em;
              padding-top: 0;
              padding-bottom: 0;
              width: 100% !important;
              background-color: #fff;
               &::placeholder {
                font-style: italic;
               }
            }
      }
       .hs_submit {
          margin: 0 0 0 0.75em !important;
          flex: 0 0 39px;
          width: 39px;
          max-width: 39px;
       }
      table {
        margin: 0;
        box-shadow: none;
        tbody {
          border-top-color: transparent;
        }
        td {
          border: none;
          padding: 0 !important;
          border: 1px solid transparent !important;

          &:first-child {
           display: none;
          }
        }
      }
    }
    .hs-richtext {
      p {
        line-height: 1.3em;
        margin: 0;
        padding: 6px 10px 18px 0;
        text-align: left !important;

            @media all and (max-width: 768px) {
               padding: 12px 15px 12px 0;
            }

        .front & {
        line-height: 1.1em;
        padding: 6px 10px 6px 0;
        }

         span {
          font-weight: 300;
          color: #8cc63e !important;
          font-size: 18px;

           .not-front & {
            font-size: 21px;

                @media all and (max-width: 768px) {
               font-size: 18px;
            }
           }
         }
        }
      }
    }
}

#block-menu-menu-help-menu {
  .block__title {
    margin-bottom: 0;
    border-bottom: none;

      @include bp-nq($medium) {
        border-bottom: 1px solid #d7d6db;
      }
  }
  .block-inner {
    ul.menu {
      margin: 0;
      display: flex;
     flex-wrap: wrap;
      padding-bottom: 0;

      @media all and (min-width: 380px) {
       flex-wrap: nowrap;
      }

      @include bp-nq($medium) {
        display: block;
      }

      li {
      list-style: none;
      text-align: center;
      position: relative;
      flex: 1 50%;
      display: flex;
      justify-content: center;

       @include bp-nq($medium) {
        justify-content: flex-start;
       }

        img {
          width: 2.846em;
          height: 2.461em;
          display: block;
          margin: 0 auto 0.5em;

          @include bp-nq($medium) {
          margin: 0 2em 0 0;
          display: inline;
          }
        }

        @media all and (max-width: 319px) {
          &:nth-child(1),
          &:nth-child(2) {
            &::after {
              content: " ";
              width: 100%;
              height: 1px;
              position: absolute;
              left: 0;
              bottom: 0;

              @include blue-bg();
            }
          }
        }

        @media all and (min-width: 320px) and (max-width: 767px) {
          flex: 1 25%;

          &::after {
            content: " ";
            width: 1px;
            height: 100%;
            right: 0;
            left: auto;
            position: absolute;
            background: transparent url("../images/blue-spacer-v.jpg") no-repeat scroll 0 0;
          }

          &.last:after {
            display: none;
          }
        }

        @include bp-nq($medium) {
          text-align: left;

          &::after {
            content: " ";
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: transparent url("../images/blue-spacer.jpg") no-repeat scroll 0 0;
          }

          &.last:after {
            display: none;
          }

        }

       &:hover {
        @include image-hover();
       }

        a {
          color: #1f4ea1;
          min-height: 3.8em;
          line-height: 1.25em;
          padding: 1.5em 0;
          font-size: 0.9em;
          @media all and (min-width: 320px) {
            padding: 0.5em;
          }
          @media all and (min-width: 380px) {
            padding: 1em;
            font-size: 1em;
          }

          @include bp-nq($medium) {
            display: flex;
            align-items: center;
            padding: 0.9em 0 0.9em 0.65em;
            background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 4em center;
            background-size: 0.8461em 0.8461em;
          }

           &:hover {
            color: #333;
           }
        }
      }
    }
  }
}

#block-views-latest-news-block-1 {
  .block__content {
    padding-bottom: 1em;
  }
}