.text-resize {
  @include inline-list;
  a {
    display: inline-block;
    width: 30px;
    height: 26px;
    line-height: 26px;
    color: #5c626c;
    outline: none;
    text-align: center;
    font-size: 1.4em;

    &:hover {
      color: #333;
      text-decoration: none;
    }
  }
}

.large-fonts .text-resize a {

  &.font-small {
    &:hover {

      }
    }
  &.font-large {

    &:hover {

      }
    }
}

/* Text-resize body text */
body.large-fonts {
  .text-resize {
    li {
      margin-left: 0.3em;
    }
  }
  @include adjust-font-size-to(17px);
  @include reset-baseline();
}