%linksArrow {
  a {
    display: block;
    padding: 1px 15px 1px 3.5em;
    color: #16c1f3;
    outline: none;
     &:hover,
     &.active {
      color: #16c1f3;
      text-decoration: none;
     }
  }
}

%linksTabbed {
  > li {
    border-top: 1px solid #7a7270;
    &:first-child { border-top: none; }
    &:hover { background-color: transparent; }
    > a {
      &:hover { color: #16c1f3; }
    }
  }
}


%inlineLinks {
  @include inline-list;
  > li {
    float: left;
    display: inline-block;
  }
  > li > a { display: block; }
}

%inlineLinks--bordered {
  @extend %inlineLinks;
  > li > a {
    &:before {
      @extend %pseudo-block;
      border-left: solid 1px $color-tertiary-dark;
      height: 60%;
      top: 20%;
      left: 0px;
      position: absolute;
    }
  }
  // Remove border on first link.
  li:first-child a:before { border: 0; }
}