.file {
  margin-bottom: 3em;

   .field-name-field-simplenews-body &,
   .search-results & {
    margin-bottom: 0;
   }
}
.file-video {
   .field-name-field-video-type {
    display: none;
   }
   .field-name-field-pdf {
    margin-top: 1em;
   }
}




