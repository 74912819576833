
// Primary branding colour.
$color-primary: #787878;
$color-primary-contrast: #FFF;

// Highlight colour.
$color-highlight: #16c0f2;
$color-highlight-contrast: #fff;

// Tertiary colours are used for borders and highlights requiring a neutral
// colour.
$color-tertiary-light: #fff;
$color-tertiary-dark: #616161;

// Colours of header and footer areas.
$color-header: #FFF;
$color-header-contrast: #444444;
$color-footer: #4c4442;
$color-footer-contrast: $color-primary-contrast;

// Text colours.
$heading-color: #ef4d64;
$body-text: #5f5f5f;
$body-text2: #444444;

// Form colours.
$form-border: #c0c0c0;
$form-background: #FDFDFD;

// Maintenance colours.
$maintenance_primary: #006893;

// Slideshow colours.
$color-slideshow: #f1efed;
$color-slideshow-contrast: $body-text2;