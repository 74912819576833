#header {
  position: relative;
  padding-bottom: 1em;
}

.header__inner {

   div.contextual-links-wrapper {
    display: none;
    @include bp-nq($medium) {
    right: -30px;
    display: block;
    }
   }

  .header__region {
     @include bp-nq($medium) {
      padding: 0 12px;
      height: 43px;
     }
      order: 2;

      @include bp-nq($medium) {
        order: 1;
       display: flex;
       justify-content: flex-end;
      }

      .form-item {
          margin: 0;
      }
    }
  .header__left {
    text-align: center;

    @include bp-nq($medium) {
      text-align: left;

      order: 2;
      display: flex;

      .large-fonts & {
        margin-top: 2em;
      }
    }
  }
}


.header__logo {
  @include bp-nq($medium) {

  }
  img {
    width: 8em;
    margin: 2em auto 1em;
    @media all and (min-width: 360px) {
       width: 12em;
    }
    @include bp-nq($medium) {
      width: 80px;
      height: 80px;
      margin: 0 1.75em 0 0;
    }
    @include bp-nq($large) {
      width: 90px;
      height: 90px;
      margin: 0 2em 0 0;
    }
  }
}

.header__name-and-slogan {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #5c626c;
}

.header__site-name {
  line-height: 1.3em;
  font-size: 1.923em;
  margin: 0;
  display: none;
   @include bp-nq($medium) {
     display: block;
  }
}
.header__site-slogan {
  margin: 0 auto;
  width: 80%;
  line-height: 1.5em;
  @include bp-nq($minor) {
    font-size: 1.1538em;
    width: 60%;
  }
  @media all and (min-width: 580px) {
    width: 70%;
  }
  @include bp-nq($medium) {
    width: auto;
    margin: 0;
  }
}
.header__secondary-menu {
  margin: rhythm(.5) 0;
  max-width: 50%;
}
.header__inner {

  .block-search-api-page {
    @include bp-nq($medium) {
      margin-top: 1em;
      width: 220px;
      margin-bottom: 0;

      .large-fonts & {
      width: 240px;
      }
    }
  }
  .block-agov-text-resize {
    margin: 1.153em 0 0 9px;
    .large-fonts & {
      margin-top: 1.4em;
    }
    @include show-at($medium, inline-block) {
    }
  }
}

#quick-exit {
  @include input-bg();
  padding: 0.2em 0.8em;

  a {
      color: #000;

    &:hover {
      opacity: 0.9;
      text-decoration: none;
    }
  }
}


div.header-menu  {
  font-size: 0.923em;
  margin-bottom: 0;
  flex: 1;

  @media all and (min-width: 350px) {
    text-align: right;
  }

    .block__content {
      background-color: #204ea3;
      .region-footer-menu & {
        border-top: 1px solid #8fa2cd;
      }

      .header__inner & {
        @include bp-nq($medium) {
          background-color: transparent;
          padding: 0;
        }
      }
    }

     .header__inner & {
    @include bp-nq($medium) {
      margin: 1.5em 0.833em 0;

          .large-fonts & {
            flex: 1 50%;
          }
      }
       @include bp-nq($large) {
          .large-fonts & {
            flex: 1 61%;
          }
        }
    }
  ul.menu {
    margin: 0;
  }
 li {
  display: inline-block;
  word-wrap: normal;

  &.last a {
    padding-right: 0;

    @include bp-nq($medium) {
      padding-right: 0.8em;
    }
  }
  &.first a {
    padding-left: 0;
  }
  > a {
    color: #eee;
    padding: 0 0.8em;
    outline: none;
    border-right: 1px solid #2492d1;

      &:hover,
      &.active {
        text-decoration: none;
        color: #fff;
      }

      .header__inner & {
        @include bp-nq($medium) {
          color: #1f4ea1;
          border-right-color: #1f4ea1;
          &:hover,
          &.active {
            text-decoration: none;
            color: #333;
          }
        }
      }
    }

  &.last a {
    border-right: none;
   }
  }
}

.header .search-form-widget {
height: 0px;
overflow: hidden;
position: relative;
clear: both;
width: 100%;
max-width: 300px;
margin: 0 auto;

  > div {
    margin-top: 12px;
    overflow: hidden;
  }
   .form-item {
    margin: 0;
   }

  @include bp-nq($medium) {
    height: 65px;
    margin: 0;
    float: right;
    max-width: 100%;
  }
}

#block-system-user-menu ul.menu {
  margin-top: 12px;
}

#block-search-api-page-site {
  form {
    margin: 0 auto;
      position: relative;
      max-width: 400px;
  }
}

#header .block-search-api-page {

  .form-text {
    padding: 0 2.6em 0 0.8em;
    height: 3em;
    line-height: 3em;
    width: 100%;
    z-index: 1;
    background-color: #fff;

     @include bp-nq($medium) {
        border: 1px solid #ddd;
      }
  }
  .form-submit {
    background-color: transparent;

     &:hover {
      opacity: 0.7;
     }
      &:hover {
      cursor: pointer;
    }
  }
}

.block-search-api-page {
  form {
    position: relative;
  }
}


.region-header .block-search-api-page {
  .mag-glass {
    position: absolute;
    top: 3.5em;
    right: 15px;
    background-color: #efefef;

     &.mag-close {
      background-size: 16px 16px;
      background-color: #8CC53F;
      background-image: url(../images/hamburger-close.svg);
     }

     @include bp-nq($medium) {
      position: static;
     }
  }

  form {
    position: absolute;
    top: 6.4em;
    left: 15px;
    right: 15px;
    z-index: 100;
    height: 0;
    overflow: hidden;
    @include box-sizing(border-box);

     > div {
      position: relative;
      padding: 3px 5px;
      font-size: 1.3em;
      background-color: #8CC53F;

      @include bp-nq($medium) {
        background-color: transparent;
        padding: 0;
        font-size: 1em;
      }
     }
     @include bp-nq($medium) {
      padding: 0;
      position: static;
      height: 2.6em !important;
      display: block;
      background-color: #fff;
    }
      a {
        font-size: 10px;
        position: absolute;
        left: 190px;
        text-decoration: none;
      }

      .form-submit{
        top: 13px;
        right: 10px;
        background-size: 16px 16px;

        @include bp-nq($medium) {
          top: 4px;
          right: 3px;
          background-size: 12px 12px;
        }
      }
     label {
      display: none;
     }
     input.form-search {
      width: 100%;
      padding: 15px 43px 15px 15px;
      background-color: #E9EDEE;
       -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid #E9EDEE;

      @include bp-nq($medium) {
          padding: 10px 43px 10px 15px;
          height: 40px;
          display: block;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          width: 213px;
        }
        @include bp-nq($medium) {
          width: 250px;
        }
     }
  }
}


.mag-glass {
  height: 38px;
  width: 38px;
  position: relative;
  background: transparent url("../images/search.svg") no-repeat scroll center center;
  background-size: 28px 28px;

  &:hover {
    cursor: pointer;
  }
  @media all and (min-width: 768px) {
    display: none;
  }
}

#block-search-api-page-default-search {
  position: static;
  margin: 1em 0 0;
}



