@mixin paragraph__tile {
  box-sizing: border-box;
  width: 100%;
  float: left;

  @include bp-nq($medium) {

    padding: rhythm(0.5);
    margin-left: rhythm(0.5);
    margin-right: rhythm(0.5);
    margin-bottom: rhythm(0.5);

    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }
    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  }
}

@mixin paragraph__tile-medium {

  @include bp-nq($medium) {
    margin-top: rhythm(0.5);
    margin-bottom: rhythm(0.5);
    width: 47.8%;

    &:nth-child(2) {
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-left: 0;
    }
  }
}

@mixin paragraph__title-reset {
  margin-top: rhythm(0.1);
  margin-bottom: rhythm(0.1);
}

// Base paragraph item styles.
.entity-paragraphs-item {

  float: left;
  clear: left;
  width: 100%;
  padding-bottom: rhythm(0.5);

  h1, h2, h3, h4, h5, h6 {
    @include paragraph__title-reset();
  }

  .field {
    // Paragraphs add extra whitespace by virtue of nesting fields, so we reduce
    // the total margin burden.
    margin-top: rhythm(0.1);
    margin-bottom: rhythm(0.1);
    float: left;
    clear: left;
    width: 100%;
  }
}

.field-type-paragraphs {
  margin: 0;
}

// Paragraph tile styles.
.paragraphs-item_view-mode_tile-text,
.paragraphs-item_view-mode_tile-image {
  @include paragraph__tile;
}

// Paragraph teaser styles.
.paragraphs-item_view-mode_teaser,
.paragraphs-item_view-mode_teaser-title,
.paragraphs-item_view-mode_teaser-image {

  clear: both;
  @include clearfix();

  @include bp-nq($medium) {
    .field {
      float:left;
      box-sizing: border-box;
    }
    .field-name-field-pbundle-title {
      float: none;
    }
    .field-name-field-pbundle-image {
      @include field-image-left-float;
    }
    .field-name-field-pbundle-text {
      @include field-text-right-float;
    }
  }
}

.paragraphs-layout__tiles-2 {
  > .field > .field-items > .field-item {

    @include paragraph__tile();
    @include paragraph__tile-medium();
  }

  &.field-type-viewfield {
    .views-row {
      @include paragraph__tile();
      @include paragraph__tile-medium();
    }
  }
}


%paragraphs-layout__tiles-3 {
  @include paragraph__tile();
  @include paragraph__tile-medium();

  @include bp-nq($large) {

    width: 31.3%;

    &:nth-child(2) {
      margin-right: rhythm(0.5);
    }
    &:nth-child(3) {
      margin-left: rhythm(0.5);
    }

    &:nth-child(3) {
      margin-right: 0;
    }
    &:nth-child(4) {
      margin-left: 0;
    }
  }
}

.paragraphs-layout__tiles-3 {
  > .field > .field-items > .field-item {
    @extend %paragraphs-layout__tiles-3;
  }

  &.field-type-viewfield {
    .views-row {
      @extend %paragraphs-layout__tiles-3;
    }
  }
}

%paragraphs-layout__tiles-4 {
  @include paragraph__tile();
  @include paragraph__tile-medium();

  @include bp-nq($large) {

    width: 24%;

    &:nth-child(2) {
      margin-right: rhythm(0.5);
    }
    &:nth-child(3) {
      margin-left: rhythm(0.5);
    }

    &:nth-child(4) {
      margin-right: 0;
    }
    &:nth-child(5) {
      margin-left: 0;
    }
  }
}

.paragraphs-layout__tiles-4 {
  > .field > .field-items > .field-item {
    @extend %paragraphs-layout__tiles-4;
  }
  &.field-type-viewfield {
    .view-content {
      width: 100%;
      clear: left;
    }
    .views-row {
      @extend %paragraphs-layout__tiles-4;
    }
  }
}
