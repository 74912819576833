@include breakpoint-set('no queries', false)
@include breakpoint-set('no query fallbacks', true)

$minor: 360px;
$xminor: 481px;
$medium: 768px;
$large: 980px;
$xlarge: 1175px;
$xxlarge: 1500px;


@mixin bp-nq($breakpoint) {
  @include breakpoint($breakpoint, $no-query: '.lt-ie9') {
    @content
  }
}

@mixin show-at($breakpoint, $display:'block') {
  display: none;
  @include bp-nq($breakpoint) {
    display: $display;
    @content
  }
}

@mixin small-screens() {
  @include breakpoint(($medium - 1px) max-width) { // subtract 1px to max-width consistent with 1px
    @content
  }
}