/* Headings */
%headings {
  font-weight: 300;
  color: #5c626c;
}

/* Links */

a:link {
    color: #1f4ea1;
    text-decoration: none;
}

a:visited {
    color: #1f4ea1;
}

a:hover {
  text-decoration: underline;
}

a:active {
    color: #16c1f3;
}

h3 a:hover {
  color: #000;
}

.node {
  a:link {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}

.field-name-field-simplenews-body {
  a:link {
    text-decoration: underline !important;
  }
  a:hover {
    text-decoration: none !important;
  }
}

.sidebars {
  .more {
    padding-top: 6px;
  }
  a:link {
  color: #16c1f3;
  }
  a:visited {
  color: #16c1f3;
  }
}

/* ==========================================================================
   Headings
   ========================================================================== */

h1 {
  @include adjust-font-size-to( $h1-font-size, $heading-line-height );
  @include leader(.5, $h1-font-size);
  @include trailer(1, $h1-font-size);
}
h2 {
  @include adjust-font-size-to( $h2-font-size, $heading-line-height );
  @include leader(.5, $h2-font-size);
  @include trailer(.5, $h2-font-size);
}
h3 {
  @include adjust-font-size-to( $h3-font-size, $heading-line-height );
  @include leader(.5, $h3-font-size);
  @include trailer(.5, $h3-font-size);
  color: #8cc63e;
  line-height: 1.5;
}
h4 {
  @include adjust-font-size-to($h4-font-size);
  @include leader(.5, $h4-font-size);
  @include trailer(.5, $h4-font-size);
}

// Declare heading styles globally.
h1, h2 {
  @extend %headings;
}

.node {
  h2, h4, h5, h6 {
  color: #424242;
  font-weight: 300;
  }
  h3 {
    color: #5c626c;
    font-weight: 400;
  }
}

h2.green {
  color: #8cc63e;
}

// Mixin to include body styles into wysiwyg but only inside body fields in default theme.
@mixin body-text-styles {
  /* Content List Styles */
  ol {
    list-style-type: decimal;
    margin-left: 21px;
         li {
      padding-left: 4px;
     }
  }

  ul {
    //@include blue-arrow-list();
  }

  /* Display image inline to the right */
  p.img-float-right,
  img.img-float-right {
    float: right;
    margin: 5px 0 5px 5px;
  }

  /* Display image inline to the left */
  p.img-float-left,
  img.img-float-left {
    float: left;
    margin: 0 5px 5px 5px;
  }
}


// On default theme only implement these styles inside the content.
article {
  @include body-text-styles;
}

.front {
  .page__title {
  @include element-invisible;
  }
}