.page-node .field-name-field-thumbnail {
  margin-top: rhythm(.5);
}

.node {
  margin-bottom: 2em;
  .links {
    overflow: hidden;
    clear: both;
    display: block;
    padding-top: 1.2em;
  }
}

.node-simplenews table {
  margin: 0;
  th,
  td {
    border: none;
  }
  th {
    background-color: none;
    background-image: none;

    strong {
      color: inherit !important;
      font-weight: normal !important;
    }
  }
  @include box-shadow(none);
}


.field-name-field-feature-image {
  width: 100%;
  padding-bottom: 1em;

  @include bp-nq($minor) {
    .file.aspect-align-right &,
     .node.aspect-align-right & {
      width: 45%;
      float: right;
      padding-left: 1em;
    }
  }

  img {
    width: 100%;
    max-width: 100%;

    @include bp-nq($minor) {
      .file.aspect-align-right &,
       .node.aspect-align-right & {
        float: right;
        padding: 0 0 5px 20px;
      }
    }
  }
}

.g-recaptcha {
	margin-top: 1.5em;
}

.u-faux-block-link {
    position: relative;

   a {
	    position: relative;
	    z-index: 1;
	}
}

.u-faux-block-link__overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0); // IE9 fix
}

a.u-faux-block-link__overlay {
    position: absolute;
    z-index: 0;
    opacity: 0;

    &:focus {
        outline: none;
    }
}

.u-faux-block-link--hover .u-faux-block-link__cta {
    text-decoration: underline;
}

.node-simplenews {
  table td {
      border: none;
      padding: 0;
      background-color: transparent;
      font-size: inherit !important;
      line-height: inherit !important;
  }
}

.alignright {
  float: right;
  margin: 0 0 1.5em 1.5em !important;
  width: 250px;
}
.alignleft {
  float: left;
  margin: 0 1.5em 1.5em 0 !important;
  width: 250px;
}

.field-name-field-feature-image {
  .file {
    margin-bottom: 0;
  }
}

.accordion {
  h5 {
    font-size: 1.3em;
    padding-left: 1.5em;
    color: #5c626c;
  }
  .accordion-header {
    background: transparent url(../images/blue-arrow-left.svg) no-repeat scroll left 1em center;
    background-size: 10px 9px;

    background-color: #f5f9eb;
    padding: 4px 1em 4px 2.5em;
    margin-bottom: 2px;

     &:hover {
      cursor: pointer;

       h5 {
        color: #333;
       }
     }
  }
  .accordion-content {
    padding: 2em;
  }
  .open .accordion-header{
    background-image: url(../images/blue-arrow-down.svg);
   }
}

.node-simplenews {
  img.imageCenter {
    margin: 0 auto;
    width: auto;
  }
  .devicewidth {
    img {
      width: auto;
    }
    table[align=left] {
      float: left;
      width: 60%;
      padding-right: 20px;
      box-sizing: border-box;
      display: block;
    }
   table[align=right] {
    float: right;
    width: 40%;
    display: block;
    }
  }
}
