.paragraphs-item-view {
  .view .more-link {
    width: 100%;
    clear: left;
  }
}

.field-type-viewfield {

  &.paragraphs-layout__tiles-2 {
    .views-row {
      @include paragraph__tile();
      @include paragraph__tile-medium();
    }
  }

  &.paragraphs-layout__tiles-3 {
    .views-row {
      @extend %paragraphs-layout__tiles-3;
    }
  }

  &.paragraphs-layout__tiles-4 {
    .views-row {
      @extend %paragraphs-layout__tiles-4;
    }
  }
}

  .date-display-single {
  line-height: 1.8em;

    .node-staff-news & {
    color: #ADA29F;
    }
  }

.block-webform {
  .webform-component-markup {
    margin: 0;

     p {
      margin-top: 0;
     }
  }
    .webform-component--your-idea {
      margin-top: 0;
    }
}


.view .view-empty {
  padding: 1em 0;
}

.front .block .views-row {
  @include bp-nq($medium) {

    }
    @include bp-nq($large) {

    }
}
.wrapper .views-row {
  .group-left {
  float: left;
  width: 95px;
  margin-right: 1em;
  text-align: center;
  min-height: 5.1em;

      @include bp-nq($medium) {
      width: 37%;
      max-width: 95px;
    }

    a {
      display: block;
      width: 100%;
      color: #fff;

      .cal-day {
        color: #323232;
        span {
         color: #323232;
         font-weight: 600;
         font-size: 1.538em;
        }
      }
      > span{
        display: block;

          span {
            line-height: inherit;
          }
      }
      .field-location {
        font-size: 1.153em;
        line-height: 1.7em;
      }

      &:hover {
        text-decoration: none;
        opacity: 0.9;
        color: #fff;
      }
    }
  }
  .group-right {
    width: 61%;
    float: right;
  }
}

.view-events {
    .item-list ul li {
      font-size: 1em !important;
    }
}

#page #block-views-newsletters-block {
  .more-link {
    float: right;
  }
}

.view-second-row {
  .view-content {
      display: flex;
      flex-wrap: wrap;

      @include bp-nq($medium) {
        flex-wrap: nowrap;
      }

    .views-row {
      &:hover {
        background: #EEF9FD;

         img {
            @include image-hover();
         }
         h2 {
          color: #333;
         }
      }
      &:first-child h2 {
        @include bp-nq($minor) {
          padding-right: 0;
        }
      }

      &::after {
        content: " ";
         width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        @include blue-bg();

        @include bp-nq($medium) {
          display: none;
        }
       }
    }
    .views-row-last {
       &::after {
        display: none;
       }
    }

    > div {
       padding: 12px 0;
        flex: 0 100%;

         @include bp-nq($medium) {
          flex: 0 25%;
         }

      > div,
      > h2 {
        @include bp-nq($medium) {
          margin-left: 12px;
          margin-right: 12px;
        }
      }

      .field-feature-image {
        display: none;

         @include bp-nq($medium) {
          display: block;
         }
      }

      h2 {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1em;
        margin-top: 0.2em;
        margin-bottom: 0.4em;

         .large-fonts & {
          font-size: 2em;
         }

        @include bp-nq($minor) {
          padding-right: 1em;
        }
        @media all and (min-width: 650px) {
          min-height: 1em;
          padding-right: 2em;
        }
        @include bp-nq($medium) {
          min-height: 2em;
          padding-right: 1em;

            .large-fonts & {
            padding-right: 0;
            min-height: 3em;
            font-size: 1.8em;
           }
        }
        @media all and (min-width: 894px) {
           .large-fonts & {
            min-height: 2em;
           }
        }
         @include bp-nq($large) {
          font-size: 2.2076em;
           padding-right: 2em;

           .large-fonts & {
            font-size: 2em;
           }
         }

         a {
          color: #5c626c;
         }
      }

       img {
        width: 100%;
       }
    }
  }
}

.read-more {
  @include read-more();
}

.expand-collapse-button {
  display: block;
  float: right;
  position: relative;
  top: -20px;
  font-size: 0.9em;
  cursor: pointer;
}

.extra-rows {
  display: none;

}

.view-teaser-icons {
  .views-row {
  padding: 9px 0;
  margin: 0 12px;
    @media all and (min-width: 290px) {
      display: flex;
      .teaser-left {
        flex: 0 80px;
      }
      .teaser-right {
        flex: 1;
        padding-left: 12px;
      }
    }
    .field-image-wrap {
    position: relative;
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 100%;

     img {
      border: none;
      padding: 0;
      margin: 0 auto;
     }
  }
  .icon-media-release {
      img {
        width: 80%;
      }
    }
    .icon-speech {
      img {

      }
    }
    .icon-commissioner-viewpoint {
        img {
          width: 80%;
      }
    }
}
}


#block-views-events-block-6 {
  .views-row {
   padding: 0 12px;

    &:hover .field-teaser-icon,
    &:hover .field-feature-image {
      img {
         @include image-hover();
       }
    }
  }
}

.view-teaser-images {
        .field-feature-video,
        .field-feature-image {
          @include bp-nq($minor) {
            width: 140px;
            margin-right: 18px;
            background-color: #FFF;
          }
        @media all and (min-width: 420px) {
            margin-right: 24px;
        }
      }
}

.view-teaser-logos {
        @include bp-nq($minor) {
        .field-feature-video,
        .field-feature-image {
          width: 140px;
          margin-right: 18px;
        }
      }
}

.view-teaser-sector-logos {
        @include bp-nq($minor) {
        .field-feature-image {
          width: 160px;
          margin-right: 18px;
        }
      }
}

.view-tags {
  margin-bottom: 3em;
  ul {
    @include blue-arrow-list();
  }
  h3 {
    font-weight: normal;
  }
  .field-term {
      background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left center;
      background-size: 10px 9px;
      list-style: none;
      padding-left: 20px;
  }
}

