x/* =============================================================================
   Tabs
   ========================================================================== */


ul.tabs {
  // margin-bottom: 0;
}
#content ul.tabs,
ul.tabs-primary{
  border-bottom:1px solid #ddd;
  padding: 0;
  margin: 1.5em 0 2em;

   .page-node-6 & {
     margin-top: 0;
   }

   &:before, &:after {
    display: table;
    line-height: 0;
    content: "";
  }
  &:after {
    clear: both;
  }

  >li{
  float:left;
  margin-bottom:-1px;
  list-style: none;

      >a{
      margin-right:3px;
      line-height:21px;
      border:1px solid transparent;
      border-radius:4px 4px 0 0;
        padding: 6px 12px;
      display: block;

        &:hover{
          border-color:#eee #eee #ddd;
        }

        &:hover, &:focus {
        text-decoration: none;
        color: #000;
        background-color: #E8E8E8;
        }
    }

    &.active>a, &.active>a:hover, &.active>a:focus, &.is-active>a, a.active{
      color:#000;
      cursor:default;
      background-color:#fff;
      border:1px solid #ddd;
      border-bottom-color:transparent;
    }
  }
}
#quicktabs-list ul.quicktabs-tabs {
    padding: 0;
}
ul.quicktabs-tabs,
ul.tabs-secondary{
  margin-bottom: 1em;
  padding: 10px 0 0;

   &:before, &:after {
    display: table;
    line-height: 0;
    content: "";
  }
  &:after {
    clear: both;
  }

  >li{
  float:left;
  margin-bottom:-1px;
  list-style: none;

      >a{
      margin-right:6px;
      line-height:21px;
      border:1px solid #ddd;
      border-radius:4px;
        padding: 5px 12px;
      display: block;
      color: #B1B1B1;

        &:hover, &:focus {
        text-decoration: none;
        background-color: #EEE;
        }
    }

    &.active>a, &.active>a:hover, &.active>a:focus{
      color:#555;
      cursor:default;
      background-color:#fff;
      border:1px solid #ddd;
    }
  }
}