.slide__content {
  position: relative;
  padding: 0 12px;
}

/* the slides */
.slick-slide {
    margin: 0;
}
/* the parent */
.slick-list {
    margin: 0 -12px;
}

#block-views-statistics-block {
  .slide__content {
    padding: 0;
  }
}