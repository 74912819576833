
@mixin field-image-left-float {
  width: 35%;
  margin-right: rhythm(1);
}
@mixin field-text-right-float {
  width: 60%;
  clear: none;
}

.field h3.field-label {
width: 100%;
}

.view-topics {
  margin-bottom: 1.5em;
  .views-field ul {
    margin-top: 0;
  }
}

.views-label {
  width: 7em;
  margin: rhythm(0.5) 0;
  color: #020202;
    font-weight: 600;
  display: inline-block;
}
.field-label-inline {
  .field-label {
    width: 36%;
    padding-right: 0.5em;
  }
}
.field-label-inline .field-item {
  p:first-child {
  margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.field-label-inline .field-items {
  width: 64%;
}
.field-type-geofield .field-items {
  margin-top: 1em;
}
.field-label-above .field-label {
  width: 100%;
}
.field .ul-formatter {
  margin-top: 0;
  margin-bottom: 0;
}

.field-name-field-tags {
  width: 100%;
  display: block;
  div { display: inline; }
  .field-item {
    &:before { content: ", "; }
    &:first-child:before { content: "";}
  }
  .field-label {
    width: 7em;
    display: inline-block;
  }
}


#block-fieldblock-node-webform-default-field-sidebar {
  .field {
    margin: 0;
  }
  p:first-child {
  margin-top: 0;
  }
  p:last-child {
  margin-bottom: 0;
  }
}

// Publications
.field-name-field-subtitle { font-weight: bold; }

.field-name-field-date.field-label-inline .field-label { width: auto; }

h2#subtitle {
  margin: 0px;
  font-size: 1.2em;
  line-height: 1.4em;
}

.field h2.field-label {
  font-weight: normal;
  width: 100%;
}


.links,
.field-name-field-event-registration {
  a:before {
    content: "> ";
  }
}

.field-name-field-file-image-caption {
  color: #ADA29F;
  line-height: 1.7em;
  font-size: 0.9em;
  margin-top: 0;

  .aspect-align-right & {
    clear: right;
    padding: 0 0 20px 20px;
  }

  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.node .field-name-field-news-date {
   background: transparent url(../images/clock.png) no-repeat scroll 2px 6px;
   padding-left: 20px;
  color: #9c9c9c;
  font-family: $base-font-family;

   .field-label {
    color: #9c9c9c;
    font-weight: normal;
   }
}

.field-name-field-download {
  .field-label {
    margin-bottom: 1em;
  }
}

.field-name-field-fc-link {
  .field-item {
    background: transparent url(../images/icon-link.png) no-repeat scroll 2px 6px;
    padding-left: 19px;
  }
}

.field-name-field-links {
  .field {
    margin: 0;
  }
}

span.date-display-single {
  line-height: inherit;
}

.field-name-field-date {
  @include adjust-font-size-to(15px);
  color: #5c626c;
  margin-bottom: 1em;
}

.field-event-date {
  color: #24ace0;
  padding-left: 0.3em;
  display: inline;
  line-height: 1.3em;
}

.field-name-field-pdf {
  .field-label {
    font-size: 1.3em;
    img {
      width: 19px;
      height: 19px;
      display: inline;
      vertical-align: bottom;
      margin-right: 0.2em;
    }
  }
  .field-items {
    img {
      margin: 0 3px;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
}

.node .field-name-field-pdf {
  margin-top: 1em;
  width: 100%;
  field-label {
    color: #020202;
  }
}

.group-headline-fields {
  .aspect-align-right & {
    width: 55%;
    float: left;
  }

   .field,
   .view {
    margin-bottom: 1em;
    overflow: hidden;
   }
   .field-date-wrapper {
    overflow: hidden;
   }
}

.view-event-date,
.node-event .field-label-inline {
  font-size: 1.1em;
  line-height: 1.5em;
}
 .aspect-align-right .view-event-date,
 .aspect-align-right.node-event .field {
  @include bp-nq($minor) {

  }
 }
.node-event .field-name-body {
  font-size: 1em;
  width: 100%;
  margin-bottom: 1em;
  clear: left;
}


.file-pdf,
.file-media-release,
.file-document {
  .file-label {
    clear: both;
    font-size: 1.3em;
    font-weight: 600;
    img {
      width: 19px;
      height: 19px;
      display: inline;
      vertical-align: top;
      margin-right: 0.2em;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
}

.field-name-field-topics {
  clear: both;
}

