$base-font-family:  Helvetica, Arial, sans-serif; // The font family set on the html element.
$roboto: 'Roboto';
$roboto-slab: 'Roboto Slab';

$header-font-family:  $roboto;
$font-body:           $roboto;
$font-monospace:      sans-serif;

$line-height-ratio: 1.6;
$base-font-size:    13px; // The font size set on the root html element.
$base-line-height:  $base-font-size * $line-height-ratio; // This line-height determines the basic unit of vertical rhythm.
$heading-line-height: $line-height-ratio;

$h1-font-size:      $base-font-size * 2.3076;
$h2-font-size:      $h1-font-size * 0.8;
$h3-font-size:      $h1-font-size * 0.6;
$h4-font-size:      $h1-font-size * 0.5;

// Tab styling.
$tabs-container-bg: #fff;
$tabs-border: #bbb;

$zen-float-direction: left        !default;
$zen-reverse-all-floats: false       !default;

// =============================================================================
// Partials to be shared with all .scss files.
// =============================================================================

// Add Compass.
@import "compass";

// Add Breakpoint.
@import "breakpoint";

// Add the Zen Grids responsive layout mixins.
@import "zen";

// Now we add our custom helper mixins.
@import "mixins";