.block .views-row {
  @extend %listing;
}

.views-exposed-form label {
  font-size: 1em;
}

.views-reset-button .form-submit {
  background-image: none;
  background-color: transparent;
    border: none;
    color: #1f4ea1;
    padding-left: 0;
    padding-right: 0;
    outline: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
}
.views-exposed-form {
  @media all and (min-width: 360px) {
    display: flex;
  }

  .filter-title {
    flex: 0 7em;
    color: #333;
    font-weight: 600;
    margin-top: 2.5em;
    margin-bottom: 1em;

    @media all and (min-width: 360px) {
      padding-left: 12px;
    }

    .exposed-filter-icons & {
      flex: 0 9.5em;
    }

    .exposed-filter-images & {
      flex: 0 14.5em;
    }

    .exposed-filter-sector-logos & {
      flex: 0 7em;
    }

    .exposed-filter-logos & {
      flex: 0 14em;
    }

    .exposed-filter-gallery-images & {
      flex: 0 54%;
    }
  }

  .views-exposed-widgets {
    float: none;
    @media all and (min-width: 360px) {
      margin: 0 -12px;
      flex: 1;
      display: flex;
    }

    .chosen-container .chosen-drop {
      background-color: #EFEFEC;
    }

    .views-exposed-widget {
      flex: 1;
      float: none;

        @media all and (max-width: 359px) {
          padding-left: 0;
          padding-right: 0;
        }

       .chosen-single {
        min-width: 100%;
       }
    }
    .views-submit-button,
    .views-reset-button {
      width: auto;
      display: none;

        #block-views-exp-calendar-block-1 & {
          width: auto;
        }
    }
  }

    .ajax-progress-throbber { display: none;}
  .views-exposed-widget {
    padding: 0.5em 1em 0 0;

  }
  .views-exposed-widget .form-submit {
    margin-top: 0;
  }
  .views-reset-button {
    padding-right: 0;
    padding-top: 0;
  }
  .views-submit-button {
    padding: 0 1em 0 0;
  }
}

.view {
    .views-row {
      @extend %listing;
   }
 }


.block {

  .block__content {

     .views-exposed-widget {

       .container-inline-date > .form-item {
        display: block;

         .date-padding,
         .form-item {
          float: none;
         }
       }
     }
  }
}

.view {
  .content,
  .view-content {
     .item-list ul {
      @include blue-arrow-list();
      margin-top: 0;
      li {
        font-size: 1.2307em;
        line-height: 1.3em;
      }
    }
  }
}

ul.views-summary {
  @include blue-arrow-list();
  margin-top: 0;
  li {
    font-size: 1.2307em;
    line-height: 1.3em;
  }
}

.view-newsletters ul li {
    list-style-image: url("../images/icon-link.png");
    padding-left: 3px;
    margin-left: 5px;
}

.video-filter,
.media-youtube-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
}

.video-filter,
.media-youtube-video {
  iframe,
  object,
  embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
}

.video-filter .video-filter {
  padding: 0;
}
.view-news-topics,
.view-event-topics {
  padding: 1em 0;
  border-top: 1px solid #d7d6db;
  border-bottom: 1px solid #d7d6db;
  clear: both;

  .view-header,
  .view-content {
    display: inline-block;
  }
  .view-label {
    font-weight: bold;
    padding-right: 0.5em;
  }
}

.view-event-date {
  .field-label {
    width: 36%;
    float: left;
    font-weight: bold;
    padding-right: 0.5em;
  }
  .field-date {
    float: left;
    width: 64%;
  }
}

.view-mindframe {
  .views-row {
    .teaser-right {
      display: flex;
    }
    .field-teaser-icon {
      flex: 0 0 70px;
      padding-right: 1em;
     }
     .field-body {
        flex: 1;
     }
  }
}



.view-child-pages {
  .views-row {
    display: flex;
     img {
        @include image-hover();
     }
     h3 {
      color: #333;
     }
     .field-teaser-icon {
      float: left;
      flex: 0 0 70px;
      padding-right: 1em;
     }
     .teaser-icon-right {
      flex: 1;
     }
   }
}

.view-need-help-teasers {
  .views-row {
    display: flex;

    @include bp-nq($medium) {
       .teaser-left {
        flex: 0 25%;
       }
        .teaser-right {
        flex: 0 75%;
       }
    }
  }
}

.view-qmhc-publications {
  .item-list li {
    font-size: 1em !important;
  }
}

.view-teasers,
.block-teaser {
  .views-row {
     padding: 12px;

       &:hover {
        background-color: #f1fafc;
       }

      &.u-faux-block-link {
         @include bp-nq($minor) {
          display: flex;
          padding: 12px;
        }
      }

      .field-body {
        a {
          @include read-more();
        }
      }
      h3.field-title {
        font-size: 1.3em;
        display: inline;
      }
      &:hover .field-teaser-icon,
      &:hover .field-feature-image {
        img {
           @include image-hover();
         }
      }

     @include bp-nq($minor) {
      .teaser-right {
        flex: 1;
      }
   }
  }
}


.view-sector-news {

    .field-logo {
      width: 100%;
      padding-bottom: 1em;

      @include bp-nq($minor) {
        width: 45%;
        float: right;
        padding-left: 1em;
      }

    img {
      width: 100%;
      max-width: 100%;

      @include bp-nq($minor) {
          float: right;
          padding: 0 0 5px 20px;
      }
    }
  }
}

#block-views-sector-news-block-2 .views-row {
       padding: 12px;

         &:hover {
          background-color: #f1fafc;
         }

        &.u-faux-block-link {
           @include bp-nq($minor) {
            padding: 12px;
          }
        }

        .field-body {
          a {
            @include read-more();
          }
        }
        h3.field-title {
          font-size: 1.3em;
          display: inline;
        }
       .field-date {
          color: #24ace0;
          padding-left: 0.3em;
          font-size: 1em;
          display: inline;
      }
        &:hover .field-teaser-icon,
        &:hover .field-feature-image {
          img {
             @include image-hover();
           }
        }

       @include bp-nq($minor) {
        .teaser-right {
          flex: 1;
        }
     }
  }

.view-gallery-teasers,
.block-gallery-teaser {
  .view-content {
    @include bp-nq($minor) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .views-row {
     flex: 0 50%;
      margin-bottom: 1em;

      @include bp-nq($minor) {
        margin-bottom: 0;
      }

       &:hover {
        background-color: #f1fafc;
       }

      &.u-faux-block-link {
         @include bp-nq($minor) {
          padding: 12px;
        }
      }
      h3.field-title {
        font-size: 1.3em;
        display: inline;
      }
      &:hover .field-feature-image {
        img {
           @include image-hover();
         }
      }
  }
}

.view-gallery-other-teasers {
    .view-content {
    @include bp-nq($minor) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .views-row {
     flex: 0 33.3%;
      margin-bottom: 1em;

      @include bp-nq($minor) {
        margin-bottom: 0;
      }

       &:hover {
        background-color: #f1fafc;
       }

      &.u-faux-block-link {
         @include bp-nq($minor) {
          padding: 12px;
        }
      }
      h3.field-title {
        font-size: 1.3em;
        display: inline;
      }
      &:hover .field-feature-image {
        img {
           @include image-hover();
         }
      }
  }
}


#block-views-images-media-centre {
  .view-images {
    overflow: hidden;
  }
  .view-footer {
    float: right;
    a.more-link {
      @include read-more();
  display: inline-block;
  padding-right: 1em;
    }
  }
}

.teaser-vertical {
  h3.field-title {
    font-size: 1.3em;
  }
}

.view-display-id-other_images_field {
  h2.title {
    font-size: 1.5em;
  }
}

figure > figcaption {
  padding: .6em 1em;
}

#block-views-child-pages-block-1 {
  margin-top: -1.5em;
}

