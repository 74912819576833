$inputHeight: 25px;
$inputVPadding: 2px;
$borderWidth: 1px;

.form-textarea-wrapper textarea {
  min-height: 138px;
}

.webform_step input[type=text],
.form-text,
.form-textarea {
  @include input-bg;
  width: 100%;

  #block-search-api-page-site--2 &,
  #block-mailchimp-signup-subscribe &,
  #block-qmhc-mailchimp-subscribe & {
    background-color: #fff;
    color: #ababab;
  }

  .header .block-search-api-page & {
    height: 54px;
    padding: 12px 13px;
    background-color: transparent;
  }
}

input .form-submit {
  text-align: center;
  border: none;
  padding: 0 15px;
  height: $inputHeight;
}

.views-submit-button,
.form-actions {
  .form-submit {
    @include standard-button;
    margin-right: 1em;
    margin-top: 0;
    @include standard-button-hover;
  }
}

#content .block {
  .browse-directory,
  .field-legal-centre {
    a {
    @include standard-button;
    @include border-radius(4px);
    @include box-shadow(#d1d1d1 1px 2px 3px);
    padding-top: 0.3em;
     padding-bottom: 0.3em;

     .large-fonts & {
      padding-left: 0.8em;
      padding-right: 0.8em;
     }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media all and (min-width: 480px) {

.region-bottom-first,
#content {
    .block {
      .more-link {
        float: left;
      }
      .field-legal-centre,
      .browse-directory {
        float: right;
        @include bp-nq($large) { width: 250px; }
      }
      .view-footer {
        @include footer-link();
      }
    }
      .form-actions {
        float: left;
      }
  }
  .field-legal-centre {
      a {
        float: right;
        width: 280px;
      }
  }
}

/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin: rhythm(1) 0;

    .form-item {
      margin: 0;
    }

  /* Pack groups of checkboxes and radio buttons closer together. */
  .form-checkboxes & ,
  .form-radios & {
    /* Drupal core uses "0.4em 0". */
    margin: 0.3em 0;
  }

.form-item-submitted-topics-other {
    @include bp-nq($medium) {
      width: 33.3%;
    }
}
.select-or-other {
  margin: 1em 0 0 1em;

  @include bp-nq($minor) {
    margin-left: 2em;
  }
}

  /* Form items in a table. */
  tr.odd &,
  tr.even & {
    margin: 0;
  }

  /* Highlight the form elements that caused a form submission error. */
  input.error,
  textarea.error,
  select.error {
    border: 1px solid #c00;
  }

  /* The descriptive help text (separate from the label). */
  .description {
    font-size: 0.85em;
    line-height: 1.7em;
    margin: 5px 0 0;
  }
}

.form-type-radio,
.form-type-checkbox {
  .description {
    margin-left: 2.4em;
  }
}

/* The part of the label that indicates a required field. */
.form-required {
  color: #ef4d64;
}

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
  font-size: 1em;
  margin-left: 0.2em;
}

fieldset {
  margin-bottom: 1em;
  legend {
    padding: 0 5px;
    font-size: 1.1em;
    color: #020202;

    a,
    a:visited,
    a:active,
    a:hover {
      color: rgb(75, 75, 75);
    }
  }
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
.fieldset-legend {
  html.js .collapsible & {
    background-image: inline-image("menu-expanded.png");
    background-position: 5px 65%; /* LTR */
    background-repeat: no-repeat;
    padding-left: 15px; /* LTR */
  }
  html.js .collapsed & {
    background-image: inline-image("menu-collapsed.png"); /* LTR */
    background-position: 5px 50%; /* LTR */
  }
  .summary {
    color: #999;
    font-size: 0.9em;
    margin-left: 0.5em;
  }
}

.filter-wrapper {
  display: none;
}

.container-inline-date .date-padding {
  padding: 0;
}

.text-format-wrapper .description {
  font-size: 0.85em;
}

/* Animated throbber */
html.js input.form-autocomplete {
  background-position: 98% center; /* LTR */
}
html.js input.throbbing {
  background-position: 98% center; /* LTR */
}

#edit-field-news-date .fieldset-wrapper {
  .form-required {
  display: none;
  }
  select {
    padding: 10px 6px;
  }
}

.file {
  img {
    width: auto;
    display: inline-block;
    position: relative;
    top: 2px;
  }
}

.ui-accordion-icons .ui-accordion-header a {
  color: #ef4d64;

   &:hover {
    opacity: 0.9;
   }
}

.webform-progressbar-outer {
  margin-top: 2.5em;
  margin-bottom: 4em;
}

fieldset {
  width: 100%;
}

.fieldset-wrapper {
   @include bp-nq($minor) {
    display: flex;
    flex-wrap: wrap;
  }
  > .form-item {
    padding: 0 1em;
   }
   > fieldset {
    margin: 12px;
   }

  @media all and (min-width: 500px) {
       > .form-item {
        flex: 1 50%;
        max-width: 50%;
        width: 50%;
     }
   }
   @include bp-nq($large) {
     > .form-item {
      flex: 1 100%;
      max-width: 100%;
      width: 100%;
   }
 }
}

.fieldset-wrapper > .webform-component--stakeholder-details--stakeholder {
    flex: 1;
}

.webform-client-form {
  clear: both;
  padding: 12px;
  .chosen-container-single .chosen-single,
  input.form-text,
  textarea {
  }
  select {

  }
  label {
    margin-bottom: 0.25em;
  }
  fieldset {
    padding: 10px;
    margin-bottom: 2em;
  }
  .chosen-container-single .chosen-single {
    width: 100%;
  }
  > div {
      @include bp-nq($minor) {
     display: flex;
     flex-wrap: wrap;

      > .form-item {
        flex: 1 50%;
        padding-right: 12px;
        margin: 1.3em 0;
      }
      .form-actions {
        margin: 0 0 1.3em;
      }
      .form-actions,
      .webform-component-markup,
      .webform-component--terms-and-conditions {
        flex: 1 100%;
      }
      .webform-component-markup {
        p:first-child {
          margin-top: 0;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

#webform-client-form-31 {
  .select-or-other {
    margin: 0;
  }
}

.webform-progressbar-outer,
.webform-progressbar-inner {
  height: 12px;
}
.webform-progressbar-outer {
  background-color: #DAEF76;
  height: 12px;
  border: none;
  margin-bottom: 3em;
}
.webform-progressbar-page
.webform-progressbar-page {
  margin-top: -2px;

  .webform-progressbar-page-label {
    @include adjust-font-size-to(13px);
    color: #8cc63e;
  }
}
.form-item .description {
  font-size: 0.75em;
  line-height: 1.45em;
}

.webform-progressbar-page.current {
  color: #A0CE4E;
}
.webform-progressbar-page.completed,
.webform-progressbar-inner {
  background-color: #8cc63e;
 }

.webform-progressbar-page {
  margin-top: -9px;
}

#em_subscribe_form {
  label {
    margin-bottom: 0.25em;
  }
  input[type=text] {
     .node & {
      background-color: #EFEFEC !important;
     }
  }
  .wfe_component {
     margin: 1.3em 0;

     > div {
      padding: 0 !important;
     }
     >.wysiwyg > div {
      font-size: inherit !important;
     }
  }
}
