table {
	width: 100%;
  th,
	td {
		border: 1px solid #E6E6E6;
		padding: 8px;
    background-color: #FFF;
	}
  th {
     color: #fff;
      background: linear-gradient(to right,  #204ea3 0%,#27a8df 100%);

      strong {
        color: #fff !important;
        font-weight: normal !important;
      }
  }
  @include box-shadow(#D9D9D9 2px 2px 3px 0px);
  @include leader(1);
  @include trailer(1);
}

th {
   &.views-field-state {
   	width: 6em;
   }
  a, img {
    display: inline-block;
  }
}

table.views-table td {
	border: none;
}

/* collapse */

.node {
  table.collapsible {
    .file {
      margin-bottom: 0;
    }
      img {
      width: 100% !important;
     }
  }
  @media all and (max-width: 379px) {
    table.collapsible {
        display: block;
    }
    td {
      border-bottom: none;
    }
    .collapsible {
      tbody,
      tr,
      td {
        display: block;
      }
    }
  }
  @media all and (min-width: 380px) {
    table.collapsible {
        display: table;
    }
    .collapsible {
      tbody {
        display: table-row-group;
      }
      tr {
        display: table-row;
      }
      td {
        display: table-cell;
      }
    }
  }
}

.node {
    table.transparent {
      td {
         background-color: transparent;
      }
  }
}

.node .table-scroll {
  @media all and (max-width: 380px) {
    overflow-x: scroll;

    table {
      width: 600px;
      font-size: 0.9em;
    }
  }
}

table.fixed {
  max-width: 100%;
  table-layout: fixed;
}

