@charset "UTF-8";
/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */
/* Import Sass mixins, variables, Compass modules, etc. */
.region-footer .block-menu .menu > li > a:before {
  content: "";
  display: block;
}

/* HTML element (SMACSS base) rules */
article,
aside,
details,
figcaption,
figure,
footer,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 81.25%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.6em;
  word-spacing: -0.05em;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

*:focus {
  outline: none;
}

a:focus {
  outline: thin dotted;
}

a:active,
a:hover {
  outline: 0;
}

p,
pre {
  margin: 0.8em 0;
}

blockquote {
  margin: 0.8em;
  background-color: #F4F4F4;
  padding: 0.8em 1.6em;
}

abbr[title] {
  border-bottom: 1px dotted;
}

#page b,
#page strong {
  font-weight: bold;
  color: #1f4ea1;
}

dfn {
  font-style: italic;
}

hr {
  height: 1px;
  border: 1px solid #d7d6db;
  padding-bottom: -1px;
  margin: 1.6em 0;
  clear: both;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp,
tt,
var {
  font-family: sans-serif;
  font-size: 1em;
  line-height: 1.6em;
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl,
menu,
ol,
ul {
  margin: 0.8em 0;
  padding: 0 0 0 0;
}

.node ul {
  list-style-position: outside;
  padding-left: 2em;
  list-style-type: square;
}

.node ul ul {
  margin: 0;
}

.node ol {
  list-style-position: outside;
}

.node ol ol {
  margin: 0;
}

dl {
  padding: 0;
}

dd {
  margin: 0 0 0 30px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

img {
  border: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -ms-interpolation-mode: bicubic;
  display: block;
  width: 100%;
  height: auto;
}

.node-1285 .align-right {
  float: right;
  margin: 0 0 1.5em 1.5em;
  width: auto;
}

.node img.align-original,
.file img.align-original {
  width: auto;
}

.node .float-right,
.file .float-right {
  float: right;
  margin: 0 0 1.5em 1.5em;
  width: auto;
}

.node .align-center,
.file .align-center {
  text-align: center;
}

.node .align-center img,
.file .align-center img {
  display: inline;
  width: auto;
  max-width: 100%;
}

@media all and (min-width: 481px) {
  .node .align-right,
  .file .align-right {
    margin: 0 0 1.5em 1.5em;
  }
}

figure > figcaption {
  padding: .6em 1em;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #d7d6db;
}

legend {
  border: 0;
  padding: 0 3px;
}

.media-vimeo-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.media-vimeo-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0.7979em 1.1986em;
  vertical-align: middle;
  font-size: 0.8461em;
  line-height: 1.5em;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background-color: #EFEFEC;
}

button,
select {
  width: 100%;
  text-transform: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

select {
  background: #EFEFEC url("../images/blue-arrow-down.png") no-repeat scroll right 10px center !important;
  background-size: 10px 9px !important;
}

html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  border: none;
  background-color: #24ace0;
  color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  *overflow: visible;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  position: relative;
  top: -2px;
  *height: 13px;
  *width: 13px;
}

input[type="search"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  display: none;
}

.no-js button {
  display: block;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

label {
  display: block;
  color: #333;
  font-size: 1.1em;
  line-height: 1.6em;
}

#block-mailchimp-signup-subscribe label,
#block-mailchimp-subscribe label {
  display: none;
}

.comment-form label {
  display: inline-block;
  width: 12em;
  margin-bottom: 0.4em;
  font-weight: 600;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 1.04em;
  margin-bottom: 1.04em;
}

#imce-content img {
  width: auto;
  height: auto;
}

body > #popup {
  display: none;
}

/* Layout rules */
@media (min-width: 768px) {
  .front #content-middle {
    display: flex;
    margin: 0 -12px;
  }
  .front #content-middle > div {
    flex: 0 50%;
    width: 50%;
    max-width: 50%;
    padding: 0 12px;
  }
}

.lt-ie9 .front #content-middle {
  display: flex;
  margin: 0 -12px;
}

.lt-ie9 .front #content-middle > div {
  flex: 0 50%;
  width: 50%;
  max-width: 50%;
  padding: 0 12px;
}

@media (min-width: 768px) {
  .front #content-middle .region-content-middle-first {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.lt-ie9 .front #content-middle .region-content-middle-first {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.front #content-middle .region-content-middle-first > div {
  margin-bottom: 1em;
}

@media (min-width: 768px) {
  .front #content-middle .region-content-middle-first > div {
    margin-bottom: 0;
  }
}

.lt-ie9 .front #content-middle .region-content-middle-first > div {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .front #content-middle .region-content-middle-second {
    display: flex;
  }
  .front #content-middle .region-content-middle-second > div {
    width: 50%;
    flex: 0 50%;
    max-width: 50%;
    margin-bottom: 0;
  }
  .front #content-middle .region-content-middle-second > div:first-child {
    padding-right: 12px;
  }
  .front #content-middle .region-content-middle-second > div:last-child {
    padding: 0 12px;
  }
}

.lt-ie9 .front #content-middle .region-content-middle-second {
  display: flex;
}

.lt-ie9 .front #content-middle .region-content-middle-second > div {
  width: 50%;
  flex: 0 50%;
  max-width: 50%;
  margin-bottom: 0;
}

.lt-ie9 .front #content-middle .region-content-middle-second > div:first-child {
  padding-right: 12px;
}

.lt-ie9 .front #content-middle .region-content-middle-second > div:last-child {
  padding: 0 12px;
}

.front .region-content-bottom {
  margin-top: 1em;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#page {
  flex: 1;
  position: relative;
}

#footer {
  position: relative;
  z-index: 1000;
}

.not-front #main {
  padding-top: 1.2em;
  padding-bottom: 1.5em;
}

@media (min-width: 768px) {
  .not-front #main {
    padding-top: 32px;
    clear: both;
  }
}

.lt-ie9 .not-front #main {
  padding-top: 32px;
  clear: both;
}

@media (max-width: 480px) {
  .sidebars .block .block-inner {
    height: auto !important;
  }
}

@media (min-width: 1175px) {
  .sidebars .block .block-inner {
    height: auto !important;
  }
}

.lt-ie9 .sidebars .block .block-inner {
  height: auto !important;
}

.sidebars #block-views-about-us-block {
  font-size: 0.9333em;
  line-height: 1.4285em;
}

.sidebars #block-views-about-us-block p:first-child {
  margin-top: 0;
}

.sidebars #block-views-about-us-block p:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .front .region-bottom-first {
    display: flex;
    margin-bottom: 1.5em;
  }
  .front .region-bottom-first .block:nth-of-type(1) {
    flex: 0 74%;
    width: 74%;
    max-width: 74%;
    margin-bottom: 0;
  }
  .front .region-bottom-first .block:nth-of-type(2) {
    flex: 1 26%;
    width: 26%;
    max-width: 26%;
    padding: 0 12px;
    margin: 0 -12px;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .front .region-bottom-first .block:nth-of-type(2) {
    padding: 0 0 0 24px;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .lt-ie9 .front .region-bottom-first .block:nth-of-type(2) {
    padding: 0 0 0 24px;
    margin: 0;
  }
}

.lt-ie9 .front .region-bottom-first {
  display: flex;
  margin-bottom: 1.5em;
}

.lt-ie9 .front .region-bottom-first .block:nth-of-type(1) {
  flex: 0 74%;
  width: 74%;
  max-width: 74%;
  margin-bottom: 0;
}

.lt-ie9 .front .region-bottom-first .block:nth-of-type(2) {
  flex: 1 26%;
  width: 26%;
  max-width: 26%;
  padding: 0 12px;
  margin: 0 -12px;
}

@media (min-width: 768px) {
  .lt-ie9 .front .region-bottom-first .block:nth-of-type(2) {
    padding: 0 0 0 24px;
    margin: 0;
  }
}

.lt-ie9 .lt-ie9 .front .region-bottom-first .block:nth-of-type(2) {
  padding: 0 0 0 24px;
  margin: 0;
}

.region-bottom-second {
  margin: 0 -12px;
}

@media (min-width: 768px) {
  .region-bottom-second {
    display: flex;
  }
  .region-bottom-second .block {
    flex: 0 33.33%;
    width: 33.33%;
    max-width: 33.33%;
    padding: 0 12px;
  }
}

.lt-ie9 .region-bottom-second {
  display: flex;
}

.lt-ie9 .region-bottom-second .block {
  flex: 0 33.33%;
  width: 33.33%;
  max-width: 33.33%;
  padding: 0 12px;
}

/**
 * @file
 * Positioning for a responsive layout.
 *
 * Define CSS classes to create a fluid grid layout with optional sidebars
 * depending on whether blocks are placed in the left or right sidebars.
 *
 * This layout uses the Zen Grids plugin for Compass: http://zengrids.com
 */
/**
 * Center the page.
 *
 */
.region-footer-menu,
.header__inner,
.no-sidebars .tabs-primary,
.not-front #main,
.front #content,
.region-navigation .block,
.slideshow-pager-inner,
.not-front .region-hero-first,
.front-slide-inner,
.region-hero-second,
.front .region-content,
.region-bottom-first,
.region-bottom-second,
.breadcrumb,
#footer .region {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 980px) {
  .region-footer-menu,
  .header__inner,
  .no-sidebars .tabs-primary,
  .not-front #main,
  .front #content,
  .region-navigation .block,
  .slideshow-pager-inner,
  .not-front .region-hero-first,
  .front-slide-inner,
  .region-hero-second,
  .front .region-content,
  .region-bottom-first,
  .region-bottom-second,
  .breadcrumb,
  #footer .region {
    max-width: 980px;
  }
}

.lt-ie9 .region-footer-menu, .lt-ie9
.header__inner, .lt-ie9
.no-sidebars .tabs-primary, .lt-ie9
.not-front #main, .lt-ie9
.front #content, .lt-ie9
.region-navigation .block, .lt-ie9
.slideshow-pager-inner, .lt-ie9
.not-front .region-hero-first, .lt-ie9
.front-slide-inner, .lt-ie9
.region-hero-second, .lt-ie9
.front .region-content, .lt-ie9
.region-bottom-first, .lt-ie9
.region-bottom-second, .lt-ie9
.breadcrumb, .lt-ie9
#footer .region {
  max-width: 980px;
}

@media (min-width: 1175px) {
  .region-footer-menu,
  .header__inner,
  .no-sidebars .tabs-primary,
  .not-front #main,
  .front #content,
  .region-navigation .block,
  .slideshow-pager-inner,
  .not-front .region-hero-first,
  .front-slide-inner,
  .region-hero-second,
  .front .region-content,
  .region-bottom-first,
  .region-bottom-second,
  .breadcrumb,
  #footer .region {
    max-width: 1175px;
  }
}

.lt-ie9 .region-footer-menu, .lt-ie9
.header__inner, .lt-ie9
.no-sidebars .tabs-primary, .lt-ie9
.not-front #main, .lt-ie9
.front #content, .lt-ie9
.region-navigation .block, .lt-ie9
.slideshow-pager-inner, .lt-ie9
.not-front .region-hero-first, .lt-ie9
.front-slide-inner, .lt-ie9
.region-hero-second, .lt-ie9
.front .region-content, .lt-ie9
.region-bottom-first, .lt-ie9
.region-bottom-second, .lt-ie9
.breadcrumb, .lt-ie9
#footer .region {
  max-width: 1175px;
}

@media (min-width: 768px) {
  .tablet-layout .tabs {
    margin-bottom: 2em;
  }
}

.lt-ie9 .tablet-layout .tabs {
  margin-bottom: 2em;
}

@media (min-width: 768px) {
  .region-footer-menu,
  .region-footer-first,
  .region-footer-second,
  .region-footer-third,
  .no-sidebars .tabs-primary,
  .header__inner,
  .front-slide-inner,
  .not-front .region-hero-first,
  .not-front .region-hero-second,
  .front .region-content,
  .region-bottom-first,
  .region-bottom-second,
  .region-sidebar-first,
  .region-sidebar-second,
  .region-sidebar-third {
    padding-left: 12px;
    padding-right: 12px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    word-wrap: break-word;
  }
}

.lt-ie9 .region-footer-menu, .lt-ie9
.region-footer-first, .lt-ie9
.region-footer-second, .lt-ie9
.region-footer-third, .lt-ie9
.no-sidebars .tabs-primary, .lt-ie9
.header__inner, .lt-ie9
.front-slide-inner, .lt-ie9
.not-front .region-hero-first, .lt-ie9
.not-front .region-hero-second, .lt-ie9
.front .region-content, .lt-ie9
.region-bottom-first, .lt-ie9
.region-bottom-second, .lt-ie9
.region-sidebar-first, .lt-ie9
.region-sidebar-second, .lt-ie9
.region-sidebar-third {
  padding-left: 12px;
  padding-right: 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
}

.region-footer-menu,
.header-menu .block__content,
#content,
.not-front .region-hero-first,
.not-front .region-hero-second,
.front-slide-inner,
.region-sidebar-first,
.region-sidebar-second,
.region-sidebar-third,
.region-footer-first,
.region-footer-second,
.region-footer-third,
.region-bottom-first,
.region-bottom-second,
.region-highlight,
.no-sidebars .tabs-primary,
.slideshow-pager-inner,
.header__inner .header-menu .block__content,
#block-superfish-1 {
  padding-left: 12px;
  padding-right: 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  word-wrap: break-word;
}

/* Containers for grid items and flow items. */
#header:before, #header:after,
#main:before,
#main:after,
#footer:before,
#footer:after {
  content: "";
  display: table;
}

#header:after,
#main:after,
#footer:after {
  clear: both;
}

#block-views-latest-news-block-2 {
  margin-left: -15px;
  margin-right: -15px;
}

@media all and (min-width: 480px) and (max-width: 979px) {
  /* Start a new row and span all 3 columns. */
  .region-sidebar-second {
    clear: both;
  }
}

@media all and (max-width: 767px) {
  .has-sidebar-block .region-sidebar-second {
    height: auto !important;
  }
}

@media all and (min-width: 980px) {
  .has-sidebar-block .region-sidebar-second {
    height: auto !important;
  }
}

/**
 * Use 3 grid columns for smaller screens.
 */
@media all and (min-width: 768px) and (max-width: 979px) {
  .two-sidebars .region-sidebar-second {
    display: flex;
    flex-direction: column;
    height: 30em;
    flex-wrap: wrap;
  }
  .two-sidebars .region-sidebar-second > div {
    padding: 0 12px;
  }
  /**
   * The layout when there is only one sidebar, the left one.
   */
  .sidebar-first {
    /* Span 2 columns, starting in 2nd column from left. */
    /* Span 1 column, starting in 1st column from left. */
  }
  .sidebar-first #content {
    float: left;
    width: 75%;
    margin-left: 25%;
    margin-right: -100%;
  }
  .sidebar-first .region-sidebar-first {
    float: left;
    width: 25%;
    margin-left: 0%;
    margin-right: -25%;
  }
  /**
   * The layout when there is only one sidebar, the right one.
   */
  .not-front.sidebar-second {
    /* Span 2 columns, starting in 1st column from left. */
    /* Span 1 column, starting in 3rd column from left. */
  }
  .not-front.sidebar-second #content {
    float: left;
    width: 75%;
    margin-left: 0%;
    margin-right: -75%;
    padding-right: 0;
  }
  .not-front.sidebar-second .region-sidebar-second,
  .not-front.sidebar-second .region-sidebar-third {
    float: left;
    width: 25%;
    margin-left: 75%;
    margin-right: -100%;
    margin: 0;
    float: right;
    clear: right;
  }
  /**
   * The layout when there are two sidebars.
   */
  .two-sidebars {
    /* Span 2 columns, starting in 2nd column from left. */
    /* Span 1 column, starting in 1st column from left. */
    /* Span 1 column, starting in 1st column from left. */
  }
  .two-sidebars #highlight,
  .two-sidebars #content {
    float: left;
    width: 75%;
    margin-left: 25%;
    margin-right: -100%;
    padding-right: 25px;
    padding-left: 12px;
  }
  .two-sidebars .region-sidebar-first {
    float: left;
    width: 25%;
    margin-left: 0%;
    margin-right: -25%;
  }
  .two-sidebars .region-sidebar-second {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
}

/**
 * Use 10 grid columns for large screens
 */
@media all and (min-width: 980px) and (max-width: 1174px) {
  .front.sidebar-second {
    /* Span 10 columns, starting in 1st column from left. */
  }
  .front.sidebar-second #content {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  /**
   * The layout when there is only one sidebar, the left one.
   */
  .sidebar-first {
    /* Span 4 columns, starting in 2nd column from left. */
    /* Span 1 column, starting in 1st column from left. */
  }
  .sidebar-first #content {
    float: left;
    width: 80%;
    margin-left: 20%;
    margin-right: -100%;
    padding-left: 12px;
  }
  .sidebar-first .region-sidebar-first {
    float: left;
    width: 20%;
    margin-left: 0%;
    margin-right: -20%;
  }
  /**
   * The layout when there is only one sidebar, the right one.
   */
  .sidebar-second {
    /* Span 4 columns, starting in 1st column from left. */
    /* Span 1 column, starting in 5th column from left. */
  }
  .sidebar-second #content {
    float: left;
    width: 75%;
    margin-left: 0%;
    margin-right: -75%;
    padding-right: 12px;
  }
  .sidebar-second .region-sidebar-second,
  .sidebar-second .region-sidebar-third {
    float: left;
    width: 25%;
    margin-left: 75%;
    margin-right: -100%;
    margin: 0;
    float: right;
    clear: right;
  }
  .sidebar-second .region-sidebar-third {
    margin-top: 3em;
  }
  /**
   * The layout when there are two sidebars.
   */
  .two-sidebars {
    /* Span 3 columns, starting in 2nd column from left. */
    /* Span 1 column, starting in 1st column from left. */
    /* Span 1 column, starting in 5th column from left. */
  }
  .two-sidebars #content {
    float: left;
    width: 50%;
    margin-left: 25%;
    margin-right: -75%;
    padding: 0 12px;
  }
  .two-sidebars .region-sidebar-first {
    float: left;
    width: 25%;
    margin-left: 0%;
    margin-right: -25%;
  }
  .two-sidebars .region-sidebar-second,
  .two-sidebars .region-sidebar-third {
    float: left;
    width: 25%;
    margin-left: 75%;
    margin-right: -100%;
    margin: 0;
    float: right;
    clear: right;
  }
  .two-sidebars .region-sidebar-second {
    margin-top: 3em;
  }
}

/**
 * Use 10 grid columns for x-large screen
 */
@media all and (min-width: 1175px) {
  /**
   * The layout when there is only one sidebar, the left one.
   */
  .sidebar-first {
    /* Span 4 columns, starting in 2nd column from left. */
    /* Span 1 column, starting in 1st column from left. */
  }
  .sidebar-first #content {
    float: left;
    width: 80%;
    margin-left: 20%;
    margin-right: -100%;
    padding-left: 12px;
  }
  .sidebar-first .region-sidebar-first {
    float: left;
    width: 20%;
    margin-left: 0%;
    margin-right: -20%;
  }
  /**
   * The layout when there is only one sidebar, the right one.
   */
  .sidebar-second {
    /* Span 4 columns, starting in 1st column from left. */
    /* Span 1 column, starting in 5th column from left. */
  }
  .sidebar-second #content {
    float: left;
    width: 75%;
    margin-left: 0%;
    margin-right: -75%;
    padding-right: 12px;
  }
  .sidebar-second .region-sidebar-second,
  .sidebar-second .region-sidebar-third {
    float: left;
    width: 25%;
    margin-left: 75%;
    margin-right: -100%;
    clear: none;
    margin: 0;
    float: right;
    clear: right;
  }
  /**
   * The layout when there are two sidebars.
   */
  .two-sidebars {
    /* Span 3 columns, starting in 2nd column from left. */
    /* Span 1 column, starting in 1st column from left. */
    /* Span 1 column, starting in 5th column from left. */
  }
  .two-sidebars #content {
    float: left;
    width: 50%;
    margin-left: 25%;
    margin-right: -75%;
    padding: 0 12px;
  }
  .two-sidebars .region-sidebar-first {
    float: left;
    width: 25%;
    margin-left: 0%;
    margin-right: -25%;
  }
  .two-sidebars .region-sidebar-second,
  .two-sidebars .region-sidebar-third {
    float: left;
    width: 25%;
    margin-left: 75%;
    margin-right: -100%;
    margin: 0;
    float: right;
    clear: right;
  }
  .two-sidebars .region-sidebar-second {
    margin-top: 3em;
  }
  .two-sidebars .region-sidebar-third {
    margin-bottom: 3em;
  }
}

/* Typographic rules */
/* Headings */
h1, h2 {
  font-weight: 300;
  color: #5c626c;
}

/* Links */
a:link {
  color: #1f4ea1;
  text-decoration: none;
}

a:visited {
  color: #1f4ea1;
}

a:hover {
  text-decoration: underline;
}

a:active {
  color: #16c1f3;
}

h3 a:hover {
  color: #000;
}

.node a:link {
  text-decoration: underline;
}

.node a:hover {
  text-decoration: none;
}

.field-name-field-simplenews-body a:link {
  text-decoration: underline !important;
}

.field-name-field-simplenews-body a:hover {
  text-decoration: none !important;
}

.sidebars .more {
  padding-top: 6px;
}

.sidebars a:link {
  color: #16c1f3;
}

.sidebars a:visited {
  color: #16c1f3;
}

/* ==========================================================================
   Headings
   ========================================================================== */
h1 {
  font-size: 2.3076em;
  line-height: 1.10938em;
  margin-top: 0.34668em;
  margin-bottom: 0.69336em;
}

h2 {
  font-size: 1.84608em;
  line-height: 1.38672em;
  margin-top: 0.43335em;
  margin-bottom: 0.43335em;
}

h3 {
  font-size: 1.38456em;
  line-height: 1.84896em;
  margin-top: 0.5778em;
  margin-bottom: 0.5778em;
  color: #8cc63e;
  line-height: 1.5;
}

h4 {
  font-size: 1.1538em;
  line-height: 1.38672em;
  margin-top: 0.69336em;
  margin-bottom: 0.69336em;
}

.node h2, .node h4, .node h5, .node h6 {
  color: #424242;
  font-weight: 300;
}

.node h3 {
  color: #5c626c;
  font-weight: 400;
}

h2.green {
  color: #8cc63e;
}

article {
  /* Content List Styles */
  /* Display image inline to the right */
  /* Display image inline to the left */
}

article ol {
  list-style-type: decimal;
  margin-left: 21px;
}

article ol li {
  padding-left: 4px;
}

article p.img-float-right,
article img.img-float-right {
  float: right;
  margin: 5px 0 5px 5px;
}

article p.img-float-left,
article img.img-float-left {
  float: left;
  margin: 0 5px 5px 5px;
}

.front .page__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  color: inherit !important;
}

/* Global form elements */
.form-textarea-wrapper textarea {
  min-height: 138px;
}

.webform_step input[type=text],
.form-text,
.form-textarea {
  background-color: #EFEFEC;
  font-size: 0.8461em;
  color: #333;
  border: none;
  width: 100%;
}

#block-search-api-page-site--2 .webform_step input[type=text],
#block-mailchimp-signup-subscribe .webform_step input[type=text],
#block-qmhc-mailchimp-subscribe .webform_step input[type=text], #block-search-api-page-site--2
.form-text,
#block-mailchimp-signup-subscribe
.form-text,
#block-qmhc-mailchimp-subscribe
.form-text, #block-search-api-page-site--2
.form-textarea,
#block-mailchimp-signup-subscribe
.form-textarea,
#block-qmhc-mailchimp-subscribe
.form-textarea {
  background-color: #fff;
  color: #ababab;
}

.header .block-search-api-page .webform_step input[type=text], .header .block-search-api-page
.form-text, .header .block-search-api-page
.form-textarea {
  height: 54px;
  padding: 12px 13px;
  background-color: transparent;
}

input .form-submit {
  text-align: center;
  border: none;
  padding: 0 15px;
  height: 25px;
}

.views-submit-button .form-submit,
.form-actions .form-submit {
  border: 1px solid #24ace0;
  color: #fff;
  text-align: center;
  display: inline-block;
  margin-top: 2em;
  font-weight: 600;
  padding: 0.3846em 1em;
  background-position: 0.75em center;
  line-height: 2em;
  -webkit-appearance: none;
  margin-right: 1em;
  margin-top: 0;
}

.views-submit-button .form-submit:hover,
.form-actions .form-submit:hover {
  background-color: #21a4d6;
}

#content .block .browse-directory a,
#content .block .field-legal-centre a {
  border: 1px solid #24ace0;
  color: #fff;
  text-align: center;
  display: inline-block;
  margin-top: 2em;
  font-weight: 600;
  padding: 0.3846em 1em;
  background-position: 0.75em center;
  line-height: 2em;
  -webkit-appearance: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: #d1d1d1 1px 2px 3px;
  -moz-box-shadow: #d1d1d1 1px 2px 3px;
  box-shadow: #d1d1d1 1px 2px 3px;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.large-fonts #content .block .browse-directory a, .large-fonts
#content .block .field-legal-centre a {
  padding-left: 0.8em;
  padding-right: 0.8em;
}

#content .block .browse-directory a:hover,
#content .block .field-legal-centre a:hover {
  text-decoration: none;
}

@media all and (min-width: 480px) {
  .region-bottom-first .block .more-link,
  #content .block .more-link {
    float: left;
  }
  .region-bottom-first .block .field-legal-centre,
  .region-bottom-first .block .browse-directory,
  #content .block .field-legal-centre,
  #content .block .browse-directory {
    float: right;
  }
}

@media all and (min-width: 480px) and (min-width: 980px) {
  .region-bottom-first .block .field-legal-centre,
  .region-bottom-first .block .browse-directory,
  #content .block .field-legal-centre,
  #content .block .browse-directory {
    width: 250px;
  }
}

@media all and (min-width: 480px) {
  .lt-ie9 .region-bottom-first .block .field-legal-centre, .lt-ie9
  .region-bottom-first .block .browse-directory, .lt-ie9
  #content .block .field-legal-centre, .lt-ie9
  #content .block .browse-directory {
    width: 250px;
  }
  .region-bottom-first .block .view-footer,
  #content .block .view-footer {
    padding: 6px 12px 0;
    text-align: right;
  }
  .region-bottom-first .block .view-footer a,
  #content .block .view-footer a {
    padding-left: 17px;
  }
  .region-bottom-first .form-actions,
  #content .form-actions {
    float: left;
  }
  .field-legal-centre a {
    float: right;
    width: 280px;
  }
}

/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin: 1.6em 0;
  /* Pack groups of checkboxes and radio buttons closer together. */
  /* Form items in a table. */
  /* Highlight the form elements that caused a form submission error. */
  /* The descriptive help text (separate from the label). */
}

.form-item .form-item {
  margin: 0;
}

.form-checkboxes .form-item,
.form-radios .form-item {
  /* Drupal core uses "0.4em 0". */
  margin: 0.3em 0;
}

@media (min-width: 768px) {
  .form-item .form-item-submitted-topics-other {
    width: 33.3%;
  }
}

.lt-ie9 .form-item .form-item-submitted-topics-other {
  width: 33.3%;
}

.form-item .select-or-other {
  margin: 1em 0 0 1em;
}

@media (min-width: 360px) {
  .form-item .select-or-other {
    margin-left: 2em;
  }
}

.lt-ie9 .form-item .select-or-other {
  margin-left: 2em;
}

tr.odd .form-item,
tr.even .form-item {
  margin: 0;
}

.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-item .description {
  font-size: 0.85em;
  line-height: 1.7em;
  margin: 5px 0 0;
}

.form-type-radio .description,
.form-type-checkbox .description {
  margin-left: 2.4em;
}

/* The part of the label that indicates a required field. */
.form-required {
  color: #ef4d64;
}

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
  font-size: 1em;
  margin-left: 0.2em;
}

fieldset {
  margin-bottom: 1em;
}

fieldset legend {
  padding: 0 5px;
  font-size: 1.1em;
  color: #020202;
}

fieldset legend a,
fieldset legend a:visited,
fieldset legend a:active,
fieldset legend a:hover {
  color: #4b4b4b;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
html.js .collapsible .fieldset-legend {
  background-image: inline-image("menu-expanded.png");
  background-position: 5px 65%;
  /* LTR */
  background-repeat: no-repeat;
  padding-left: 15px;
  /* LTR */
}

html.js .collapsed .fieldset-legend {
  background-image: inline-image("menu-collapsed.png");
  /* LTR */
  background-position: 5px 50%;
  /* LTR */
}

.fieldset-legend .summary {
  color: #999;
  font-size: 0.9em;
  margin-left: 0.5em;
}

.filter-wrapper {
  display: none;
}

.container-inline-date .date-padding {
  padding: 0;
}

.text-format-wrapper .description {
  font-size: 0.85em;
}

/* Animated throbber */
html.js input.form-autocomplete {
  background-position: 98% center;
  /* LTR */
}

html.js input.throbbing {
  background-position: 98% center;
  /* LTR */
}

#edit-field-news-date .fieldset-wrapper .form-required {
  display: none;
}

#edit-field-news-date .fieldset-wrapper select {
  padding: 10px 6px;
}

.file img {
  width: auto;
  display: inline-block;
  position: relative;
  top: 2px;
}

.ui-accordion-icons .ui-accordion-header a {
  color: #ef4d64;
}

.ui-accordion-icons .ui-accordion-header a:hover {
  opacity: 0.9;
}

.webform-progressbar-outer {
  margin-top: 2.5em;
  margin-bottom: 4em;
}

fieldset {
  width: 100%;
}

@media (min-width: 360px) {
  .fieldset-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

.lt-ie9 .fieldset-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.fieldset-wrapper > .form-item {
  padding: 0 1em;
}

.fieldset-wrapper > fieldset {
  margin: 12px;
}

@media all and (min-width: 500px) {
  .fieldset-wrapper > .form-item {
    flex: 1 50%;
    max-width: 50%;
    width: 50%;
  }
}

@media (min-width: 980px) {
  .fieldset-wrapper > .form-item {
    flex: 1 100%;
    max-width: 100%;
    width: 100%;
  }
}

.lt-ie9 .fieldset-wrapper > .form-item {
  flex: 1 100%;
  max-width: 100%;
  width: 100%;
}

.fieldset-wrapper > .webform-component--stakeholder-details--stakeholder {
  flex: 1;
}

.webform-client-form {
  clear: both;
  padding: 12px;
}

.webform-client-form label {
  margin-bottom: 0.25em;
}

.webform-client-form fieldset {
  padding: 10px;
  margin-bottom: 2em;
}

.webform-client-form .chosen-container-single .chosen-single {
  width: 100%;
}

@media (min-width: 360px) {
  .webform-client-form > div {
    display: flex;
    flex-wrap: wrap;
  }
  .webform-client-form > div > .form-item {
    flex: 1 50%;
    padding-right: 12px;
    margin: 1.3em 0;
  }
  .webform-client-form > div .form-actions {
    margin: 0 0 1.3em;
  }
  .webform-client-form > div .form-actions,
  .webform-client-form > div .webform-component-markup,
  .webform-client-form > div .webform-component--terms-and-conditions {
    flex: 1 100%;
  }
  .webform-client-form > div .webform-component-markup p:first-child {
    margin-top: 0;
  }
  .webform-client-form > div .webform-component-markup p:last-child {
    margin-bottom: 0;
  }
}

.lt-ie9 .webform-client-form > div {
  display: flex;
  flex-wrap: wrap;
}

.lt-ie9 .webform-client-form > div > .form-item {
  flex: 1 50%;
  padding-right: 12px;
  margin: 1.3em 0;
}

.lt-ie9 .webform-client-form > div .form-actions {
  margin: 0 0 1.3em;
}

.lt-ie9 .webform-client-form > div .form-actions,
.lt-ie9 .webform-client-form > div .webform-component-markup,
.lt-ie9 .webform-client-form > div .webform-component--terms-and-conditions {
  flex: 1 100%;
}

.lt-ie9 .webform-client-form > div .webform-component-markup p:first-child {
  margin-top: 0;
}

.lt-ie9 .webform-client-form > div .webform-component-markup p:last-child {
  margin-bottom: 0;
}

#webform-client-form-31 .select-or-other {
  margin: 0;
}

.webform-progressbar-outer,
.webform-progressbar-inner {
  height: 12px;
}

.webform-progressbar-outer {
  background-color: #DAEF76;
  height: 12px;
  border: none;
  margin-bottom: 3em;
}

.webform-progressbar-page .webform-progressbar-page {
  margin-top: -2px;
}

.webform-progressbar-page .webform-progressbar-page .webform-progressbar-page-label {
  font-size: 1em;
  line-height: 1.6em;
  color: #8cc63e;
}

.form-item .description {
  font-size: 0.75em;
  line-height: 1.45em;
}

.webform-progressbar-page.current {
  color: #A0CE4E;
}

.webform-progressbar-page.completed,
.webform-progressbar-inner {
  background-color: #8cc63e;
}

.webform-progressbar-page {
  margin-top: -9px;
}

#em_subscribe_form label {
  margin-bottom: 0.25em;
}

.node #em_subscribe_form input[type=text] {
  background-color: #EFEFEC !important;
}

#em_subscribe_form .wfe_component {
  margin: 1.3em 0;
}

#em_subscribe_form .wfe_component > div {
  padding: 0 !important;
}

#em_subscribe_form .wfe_component > .wysiwyg > div {
  font-size: inherit !important;
}

/* Component */
.field h3.field-label {
  width: 100%;
}

.view-topics {
  margin-bottom: 1.5em;
}

.view-topics .views-field ul {
  margin-top: 0;
}

.views-label {
  width: 7em;
  margin: 0.8em 0;
  color: #020202;
  font-weight: 600;
  display: inline-block;
}

.field-label-inline .field-label {
  width: 36%;
  padding-right: 0.5em;
}

.field-label-inline .field-item p:first-child {
  margin-top: 0;
}

.field-label-inline .field-item p:last-child {
  margin-bottom: 0;
}

.field-label-inline .field-items {
  width: 64%;
}

.field-type-geofield .field-items {
  margin-top: 1em;
}

.field-label-above .field-label {
  width: 100%;
}

.field .ul-formatter {
  margin-top: 0;
  margin-bottom: 0;
}

.field-name-field-tags {
  width: 100%;
  display: block;
}

.field-name-field-tags div {
  display: inline;
}

.field-name-field-tags .field-item:before {
  content: ", ";
}

.field-name-field-tags .field-item:first-child:before {
  content: "";
}

.field-name-field-tags .field-label {
  width: 7em;
  display: inline-block;
}

#block-fieldblock-node-webform-default-field-sidebar .field {
  margin: 0;
}

#block-fieldblock-node-webform-default-field-sidebar p:first-child {
  margin-top: 0;
}

#block-fieldblock-node-webform-default-field-sidebar p:last-child {
  margin-bottom: 0;
}

.field-name-field-subtitle {
  font-weight: bold;
}

.field-name-field-date.field-label-inline .field-label {
  width: auto;
}

h2#subtitle {
  margin: 0px;
  font-size: 1.2em;
  line-height: 1.4em;
}

.field h2.field-label {
  font-weight: normal;
  width: 100%;
}

.links a:before,
.field-name-field-event-registration a:before {
  content: "> ";
}

.field-name-field-file-image-caption {
  color: #ADA29F;
  line-height: 1.7em;
  font-size: 0.9em;
  margin-top: 0;
}

.aspect-align-right .field-name-field-file-image-caption {
  clear: right;
  padding: 0 0 20px 20px;
}

.field-name-field-file-image-caption p:first-child {
  margin-top: 0;
}

.field-name-field-file-image-caption p:last-child {
  margin-bottom: 0;
}

.node .field-name-field-news-date {
  background: transparent url(../images/clock.png) no-repeat scroll 2px 6px;
  padding-left: 20px;
  color: #9c9c9c;
  font-family: Helvetica, Arial, sans-serif;
}

.node .field-name-field-news-date .field-label {
  color: #9c9c9c;
  font-weight: normal;
}

.field-name-field-download .field-label {
  margin-bottom: 1em;
}

.field-name-field-fc-link .field-item {
  background: transparent url(../images/icon-link.png) no-repeat scroll 2px 6px;
  padding-left: 19px;
}

.field-name-field-links .field {
  margin: 0;
}

span.date-display-single {
  line-height: inherit;
}

.field-name-field-date {
  font-size: 1.15385em;
  line-height: 1.38667em;
  color: #5c626c;
  margin-bottom: 1em;
}

.field-event-date {
  color: #24ace0;
  padding-left: 0.3em;
  display: inline;
  line-height: 1.3em;
}

.field-name-field-pdf .field-label {
  font-size: 1.3em;
}

.field-name-field-pdf .field-label img {
  width: 19px;
  height: 19px;
  display: inline;
  vertical-align: bottom;
  margin-right: 0.2em;
}

.field-name-field-pdf .field-items img {
  margin: 0 3px;
}

.field-name-field-pdf ul {
  list-style: none;
  padding-left: 0;
}

.node .field-name-field-pdf {
  margin-top: 1em;
  width: 100%;
}

.node .field-name-field-pdf field-label {
  color: #020202;
}

.aspect-align-right .group-headline-fields {
  width: 55%;
  float: left;
}

.group-headline-fields .field,
.group-headline-fields .view {
  margin-bottom: 1em;
  overflow: hidden;
}

.group-headline-fields .field-date-wrapper {
  overflow: hidden;
}

.view-event-date,
.node-event .field-label-inline {
  font-size: 1.1em;
  line-height: 1.5em;
}

.node-event .field-name-body {
  font-size: 1em;
  width: 100%;
  margin-bottom: 1em;
  clear: left;
}

.file-pdf .file-label,
.file-media-release .file-label,
.file-document .file-label {
  clear: both;
  font-size: 1.3em;
  font-weight: 600;
}

.file-pdf .file-label img,
.file-media-release .file-label img,
.file-document .file-label img {
  width: 19px;
  height: 19px;
  display: inline;
  vertical-align: top;
  margin-right: 0.2em;
}

.file-pdf ul,
.file-media-release ul,
.file-document ul {
  list-style: none;
  padding-left: 0;
}

.field-name-field-topics {
  clear: both;
}

.region-content-bottom .block-inner ul.menu a,
.sidebars .block-inner ul.menu a {
  display: block;
  padding: 1px 15px 1px 3.5em;
  color: #16c1f3;
  outline: none;
}

.region-content-bottom .block-inner ul.menu a:hover,
.sidebars .block-inner ul.menu a:hover, .region-content-bottom .block-inner ul.menu a.active,
.sidebars .block-inner ul.menu a.active {
  color: #16c1f3;
  text-decoration: none;
}

.region-footer .block-menu .menu {
  list-style-type: none;
}

.region-footer .block-menu .menu, .region-footer .block-menu .menu li {
  margin: 0px;
  padding: 0px;
  display: inline;
}

.region-footer .block-menu .menu > li {
  float: left;
  display: inline-block;
}

.region-footer .block-menu .menu > li > a {
  display: block;
}

.region-footer .block-menu .menu > li > a:before {
  border-left: solid 1px #616161;
  height: 60%;
  top: 20%;
  left: 0px;
  position: absolute;
}

.region-footer .block-menu .menu li:first-child a:before {
  border: 0;
}

.search-results li h3, .block .views-row h3, .view .views-row h3 {
  line-height: 1.3em;
  margin-top: 0;
  color: #1f4ea1;
  font-weight: 500;
  margin-bottom: 0.2em;
  font-size: 1.15em;
}

.search-results li h3 a, .block .views-row h3 a, .view .views-row h3 a {
  font-weight: 400;
  color: #1f4ea1;
}

.search-results li h3 a:hover, .block .views-row h3 a:hover, .view .views-row h3 a:hover {
  text-decoration: none;
  color: #333;
}

.search-results li .field-body, .block .views-row .field-body, .view .views-row .field-body {
  font-size: 0.933em;
  line-height: 1.428em;
  display: block;
}

.search-results li .field-datebox, .block .views-row .field-datebox, .view .views-row .field-datebox {
  color: #9c9c9c;
  font-size: 0.86667em;
  line-height: 1.84615em;
  font-family: "Open Sans", sans-serif;
}

.search-results li .field-date, .block .views-row .field-date, .view .views-row .field-date {
  font-size: 0.84615em;
  line-height: 1.89091em;
  font-family: Helvetica, Arial, sans-serif;
}

.search-results li .field-comment a, .block .views-row .field-comment a, .view .views-row .field-comment a {
  color: #9c9c9c;
  font-size: 1em;
  line-height: 1.6em;
  padding-left: 20px;
  background: transparent url("../images/bubble.png") no-repeat scroll left 1px;
  margin-left: 0.6em;
}

.search-results li .field-comment a:hover, .block .views-row .field-comment a:hover, .view .views-row .field-comment a:hover {
  background-position: left -25px;
  color: #ef4d64;
  text-decoration: none;
}

.search-results li .field-teaser-icon, .block .views-row .field-teaser-icon, .view .views-row .field-teaser-icon, .search-results li .field-feature-image, .block .views-row .field-feature-image, .view .views-row .field-feature-image, .search-results li .field-image, .block .views-row .field-image, .view .views-row .field-image {
  margin-bottom: 5px;
}

.view-events .views-row h3,
.view-news .views-row h3,
.view-news-by-topic .views-row h3,
.front .views-row h3 {
  margin-bottom: 0;
}

#block-views-success-stories-block .view .views-row h3 {
  font-size: 1.15385em;
  line-height: 1.38667em;
  line-height: 1.3em;
  margin-bottom: 0.3em;
}

#block-views-success-stories-block .view .views-row .field-image {
  padding-bottom: 5px;
}

/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */
/**
 * Wireframes.
 */
.with-wireframes #header,
.with-wireframes #main,
.with-wireframes #content,
.with-wireframes #navigation,
.with-wireframes .region-sidebar-first,
.with-wireframes .region-sidebar-second,
.with-wireframes #footer,
.with-wireframes .region-bottom {
  outline: 1px solid #ccc;
}

/**
 * Accessibility features.
 */
/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
.element-focusable,
#navigation .block-menu .block__title,
#navigation .block-menu-block .block__title,
.region-footer .block-title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  color: inherit !important;
}

/* Turns off the element-invisible effect. */
.element-focusable:active, .element-focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
  display: block;
  padding: 1em;
  background-color: #16c0f2;
  color: #fff;
  z-index: 10;
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0;
}

#skip-link a,
#skip-link a:visited {
  display: block;
  width: 100%;
  padding: 2px 0 3px 0;
  text-align: center;
  background-color: #666;
  color: #fff;
}

/**
 * Branding header.
 */
a#logo {
  outline: none;
}

/* The link around the name of the website. */
.header__site-link:link, .header__site-link:visited {
  color: #5c626c;
  outline: none;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  color: #333;
}

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: right;
  /* LTR */
}

/**
 * Navigation bar.
 */
#navigation {
  /* Sometimes you want to prevent overlapping with main div. */
  /* overflow: hidden; */
  /* Main menu and secondary menu links and menu block links. */
}

#navigation .block {
  margin-bottom: 0;
}

#navigation .links,
#navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
  /* LTR */
}

#navigation .links li,
#navigation .menu li {
  /* A simple method to get navigation links to appear in one line. */
  float: left;
  /* LTR */
  padding: 0 10px 0 0;
  /* LTR */
  list-style-type: none;
  list-style-image: none;
}

/**
 * Breadcrumb navigation.
 */
.breadcrumb ol {
  margin: 0;
  padding: 0;
}

.breadcrumb li {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/**
 * Messages.
 */
.messages, .messages--status, .messages--warning, .messages--error {
  margin: 1.6em 0;
  padding: 10px 10px 10px 50px;
  /* LTR */
  background-image: inline-image("message-24-ok.png");
  background-position: 8px 8px;
  /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}

.messages--warning {
  background-image: inline-image("message-24-warning.png");
  border-color: #ed5;
}

.messages--error {
  background-image: inline-image("message-24-error.png");
  border-color: #ed541d;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages img, .messages--status img, .messages--warning img, .messages--error img {
  width: auto;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333;
}

/* System status report. */
.ok,
.messages--status {
  background-color: #f8fff0;
  color: #234600;
}

.warning,
.messages--warning {
  background-color: #fffce5;
  color: #840;
}

.error,
.messages--error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/**
 * Inline styles.
 */
/* List of links generated by theme_links(). */
.inline {
  list-style-type: none;
}

.inline, .inline li {
  margin: 0px;
  padding: 0px;
  display: inline;
}

/**
 * "More" links.
 */
@media (min-width: 768px) {
  .more-link {
    text-align: right;
  }
}

.lt-ie9 .more-link {
  text-align: right;
}

.more-help-link {
  text-align: right;
  /* LTR */
}

.more-help-link a {
  background-image: inline-image("help.png");
  background-position: 0 50%;
  /* LTR */
  background-repeat: no-repeat;
  padding: 1px 0 1px 20px;
  /* LTR */
}

/**
 * Titles.
 */
.block__title {
  font-weight: 400;
  margin-bottom: 0;
}

h2.block__title {
  line-height: 1.2em;
  font-size: 1.38462em;
  line-height: 2.31111em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

/**
 * Blocks.
 */
/* The active item in a Drupal menu. */
.menu a.active {
  color: #000;
}

/**
 * Marker.
 */
/* The "new" or "updated" marker. */
.new,
.update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
}

/**
 * Unpublished note.
 */
/* The word "Unpublished" displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #dfcdcd;
  font-size: 1.46154em;
  line-height: 2.18947em;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  text-align: right;
  word-wrap: break-word;
  margin: 0 auto;
  display: block;
  top: 5px;
  right: 5px;
  position: relative;
}

/**
 * Comments.
 */
/* Wrapper for the list of comments and its title. */
.comments {
  margin: 1.6em 0;
}

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea;
}

/* Wrapper for a single comment. */
.comment {
  /* Comment's permalink wrapper. */
}

.comment .permalink {
  text-transform: uppercase;
  display: block;
  float: right;
  font-size: 0.9em;
}

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: 30px;
  /* LTR */
}

/* Buttons used by contrib modules like Media. */
a.button {
  margin-top: 0.5em;
  display: inline-block;
  background: #16c0f2;
  color: #fff;
  text-decoration: none;
  margin-right: 0.5em;
  padding: 0.5em 1em;
}

a.button:active {
  background: #0b9eca;
}

/* Password confirmation. */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */
#user-login-form {
  text-align: left;
  /* LTR */
}

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */
/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0;
}

/* The "Log in using OpenID" and "Cancel OpenID login" links. */
.openid-link,
.user-link {
  margin-top: 1.6em;
}

html.js #user-login-form li.openid-link,
#user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px;
  /* LTR */
}

#user-login ul {
  margin: 1.6em 0;
}

/**
 * Drupal admin tables.
 */
form th {
  text-align: left;
  /* LTR */
  padding-right: 1em;
  /* LTR */
  border-bottom: 3px solid #ccc;
}

form tbody {
  border-top: 1px solid #ccc;
}

form table ul {
  margin: 0;
}

tr.even,
tr.odd {
  background-color: #eee;
  padding: 0.1em 0.6em;
}

tr.even {
  background-color: #fff;
}

/* Markup generated by theme_tablesort_indicator(). */
td.active {
  background-color: #ddd;
}

/* Center checkboxes inside table cell. */
td.checkbox,
th.checkbox {
  text-align: center;
}

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */
/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}

tr.drag-previous {
  background-color: #ffd;
}

.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold;
}

.progress .bar {
  background: #ccc;
  border-color: #666;
  margin: 0 0.2em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.progress .filled {
  background-color: #0072b9;
  background-image: url("../images/progress.gif");
}

#region-content .content ul li {
  list-style: disc;
  margin: 0 0 5px 15px;
}

#sweaver {
  left: inherit;
}

#sweaver .close a,
#sweaver .tab a {
  height: auto !important;
}

.panels-ipe #sweaver {
  bottom: 54px;
}

form#sweaver-frontend > div {
  position: static;
  height: 300px;
}

/**
 * Pager.
 */
/* A list of page numbers when more than 1 page of content is available. */
ul.pager {
  list-style: none;
  margin-left: 0;
  clear: both;
  text-align: center;
  padding-top: 1em;
  border-top: 1px solid #d7d6db;
}

.date-nav ul.pager {
  border: none;
  padding: 0;
}

.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis, .pager-current {
  display: inline-block;
  list-style-type: none;
  background-image: none;
}

.pager-item a,
.pager-first a,
.pager-previous a,
.pager-next a,
.pager-last a,
.pager-ellipsis a, .pager-current a {
  display: inline-block;
  padding: 0 0.4em;
}

.pager-item a:hover,
.pager-first a:hover,
.pager-previous a:hover,
.pager-next a:hover,
.pager-last a:hover,
.pager-ellipsis a:hover, .pager-current a:hover {
  color: #fff;
  background-color: #5c626c;
  text-decoration: none;
}

.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  /* A concatenation of several list items using an ellipsis. */
}

/* The current page's list item. */
.pager-current {
  color: #fff !important;
  background: #787878;
  padding: 0 0.4em;
}


.palette-1 {
  background: #E2E2E2;
  color: #4f4f4f;
}

.search-results p {
  margin: 0;
}

.search-results li {
  padding: 12px;
}

.search-results h3.title a {
  font-size: 1.07692em;
  line-height: 1.48571em;
}

.search-results .search-snippet-info {
  padding: 0;
  font-style: italic;
}

.search-form {
  *zoom: 1;
  padding: 15px;
  background-color: #fff;
  border-top: solid 3px #787878;
}

.search-form:after {
  content: "";
  display: table;
  clear: both;
}

.search-form label {
  float: left;
  margin-right: 15px;
  margin-bottom: 0px;
  margin-top: 0.6em;
}

.search-form fieldset {
  display: none;
}

.search-form .search-form-description {
  font-size: 1.1em;
}

.search-form .form-text {
  float: left;
  min-width: 75%;
  margin: 0.8em 0 0;
}

@media (min-width: 768px) {
  .search-form .form-text {
    min-width: 50%;
  }
}

.lt-ie9 .search-form .form-text {
  min-width: 50%;
}

.view-search form {
  *zoom: 1;
  padding: 15px;
  background-color: #fff;
  border-top: solid 3px #787878;
}

.view-search form:after {
  content: "";
  display: table;
  clear: both;
}

.view-search form label {
  float: left;
  margin-right: 15px;
  margin-bottom: 0px;
  margin-top: 0.6em;
}

.view-search form fieldset {
  display: none;
}

.view-search form .search-form-description {
  font-size: 1.1em;
}

.view-search form label {
  margin-top: 5px;
}

.view-search form .views-exposed-form,
.view-search form .views-exposed-widget {
  padding: 0;
}

.view-search form .views-widget-filter-keys {
  display: inline-block;
  min-width: 75%;
}

@media (min-width: 768px) {
  .view-search form .views-widget-filter-keys {
    min-width: 50%;
  }
}

.lt-ie9 .view-search form .views-widget-filter-keys {
  min-width: 50%;
}

.view-search form .views-widget-filter-keys input {
  float: left;
  width: 100%;
  margin: 0.8em 0 0;
}

.view-search form .views-submit-button {
  padding: 0;
}

.view-search form .views-submit-button input {
  clear: left;
  height: 2.1em;
  margin: 0.8em;
  display: inline-block;
  font-size: 0.92308em;
  line-height: 1.73333em;
}

@media (min-width: 980px) {
  .view-search form .views-submit-button input {
    clear: none;
  }
}

.lt-ie9 .view-search form .views-submit-button input {
  clear: none;
}

.view-search form .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 2.7em;
}

#search-api-page-search-form {
  position: relative;
}

@media (min-width: 360px) {
  #search-api-page-search-form form > div input.form-text {
    margin-right: 1em;
  }
}

.lt-ie9 #search-api-page-search-form form > div input.form-text {
  margin-right: 1em;
}

#search-api-page-search-form .block-inner {
  position: relative;
}

#search-api-page-search-form .form-item {
  margin-bottom: 0.75em;
  display: inline-block;
  width: 100%;
}

@media (min-width: 360px) {
  #search-api-page-search-form .form-item {
    width: calc(100% - 12.5em);
    margin-right: 1em;
  }
}

.lt-ie9 #search-api-page-search-form .form-item {
  width: calc(100% - 12.5em);
  margin-right: 1em;
}

#search-api-page-search-form .reset-search {
  font-size: 0.9em;
  position: absolute;
  bottom: 0.75em;
  left: 80px;
}

@media (min-width: 360px) {
  #search-api-page-search-form .reset-search {
    left: auto;
    right: 0;
    bottom: 1.5em;
  }
}

.lt-ie9 #search-api-page-search-form .reset-search {
  left: auto;
  right: 0;
  bottom: 1.5em;
}

#search-api-page-search-form .reset-search a {
  color: #1f4ea1;
}

.current-search-item-active ul li {
  list-style: none;
  font-size: 1.1em;
}

.current-search-item-active ul li a .close {
  padding: 0 5px;
  float: left;
  position: relative;
  top: -2px;
  display: block;
  color: #f22e2e;
}

.block-facetapi ul li {
  list-style: none;
  font-size: 1.1em;
}

.block-facetapi ul li a .close {
  padding: 0 5px;
  float: left;
  position: relative;
  top: -2px;
  display: block;
  color: #f22e2e;
}

.block-facetapi ul.facetapi-search-api-glossary {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

.block-facetapi ul.facetapi-search-api-glossary li {
  font-size: 1.1em;
  list-style: none;
  flex: 0 20%;
  color: #c1c1c1;
  background-color: #16c1f3;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  text-align: center;
}

.block-facetapi ul.facetapi-search-api-glossary li:hover {
  background-color: #24ace0;
}

.block-facetapi ul.facetapi-search-api-glossary li a {
  color: #fff;
  display: block;
}

.block-facetapi ul.facetapi-search-api-glossary li a:hover {
  text-decoration: none;
}

.block-facetapi ul.facetapi-search-api-glossary li.no-content {
  background-color: #fff;
}

.block-facetapi ul.facetapi-search-api-glossary li.active {
  background-color: #24ace0;
  color: #fff;
}

.block-facetapi ul.facetapi-search-api-glossary li.active a .close {
  display: none;
}

.block-facetapi ul.facetapi-search-api-glossary li.active.no-content {
  padding: 5px;
}

.header div.block-search-api-page form .form-submit {
  position: absolute;
  right: 3px;
  text-indent: -1000px;
  background: url("../images/mag-glass.svg") 50% 50% no-repeat;
  background-color: #6d9902;
  background-size: 12px 12px;
  width: 23px;
  height: 23px;
  margin-right: 0;
  border: none;
}

.large-fonts .header div.block-search-api-page form .form-submit {
  background-size: 16px 16px;
  right: 7px;
  top: 8px;
}

.header div.block-search-api-page form .form-submit:active {
  background-color: #0db1e2;
}

ol.search-results li {
  list-style: none;
}

@media all and (max-width: 767px) {
  .block-facetapi {
    display: none;
  }
}

div.service-links {
  display: inline-block;
}

div.service-links img {
  display: inline-block;
}

table {
  width: 100%;
  -webkit-box-shadow: #D9D9D9 2px 2px 3px 0px;
  -moz-box-shadow: #D9D9D9 2px 2px 3px 0px;
  box-shadow: #D9D9D9 2px 2px 3px 0px;
  margin-top: 1.6em;
  margin-bottom: 1.6em;
}

table th,
table td {
  border: 1px solid #E6E6E6;
  padding: 8px;
  background-color: #FFF;
}

table th {
  color: #fff;
  background: linear-gradient(to right, #204ea3 0%, #27a8df 100%);
}

table th strong {
  color: #fff !important;
  font-weight: normal !important;
}

th.views-field-state {
  width: 6em;
}

th a, th img {
  display: inline-block;
}

table.views-table td {
  border: none;
}

/* collapse */
.node table.collapsible .file {
  margin-bottom: 0;
}

.node table.collapsible img {
  width: 100% !important;
}

@media all and (max-width: 379px) {
  .node table.collapsible {
    display: block;
  }
  .node td {
    border-bottom: none;
  }
  .node .collapsible tbody,
  .node .collapsible tr,
  .node .collapsible td {
    display: block;
  }
}

@media all and (min-width: 380px) {
  .node table.collapsible {
    display: table;
  }
  .node .collapsible tbody {
    display: table-row-group;
  }
  .node .collapsible tr {
    display: table-row;
  }
  .node .collapsible td {
    display: table-cell;
  }
}

.node table.transparent td {
  background-color: transparent;
}

@media all and (max-width: 380px) {
  .node .table-scroll {
    overflow-x: scroll;
  }
  .node .table-scroll table {
    width: 600px;
    font-size: 0.9em;
  }
}

table.fixed {
  max-width: 100%;
  table-layout: fixed;
}

#content ul.tabs,
ul.tabs-primary {
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin: 1.5em 0 2em;
}

.page-node-6 #content ul.tabs, .page-node-6
ul.tabs-primary {
  margin-top: 0;
}

#content ul.tabs:before, #content ul.tabs:after,
ul.tabs-primary:before,
ul.tabs-primary:after {
  display: table;
  line-height: 0;
  content: "";
}

#content ul.tabs:after,
ul.tabs-primary:after {
  clear: both;
}

#content ul.tabs > li,
ul.tabs-primary > li {
  float: left;
  margin-bottom: -1px;
  list-style: none;
}

#content ul.tabs > li > a,
ul.tabs-primary > li > a {
  margin-right: 3px;
  line-height: 21px;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  padding: 6px 12px;
  display: block;
}

#content ul.tabs > li > a:hover,
ul.tabs-primary > li > a:hover {
  border-color: #eee #eee #ddd;
}

#content ul.tabs > li > a:hover, #content ul.tabs > li > a:focus,
ul.tabs-primary > li > a:hover,
ul.tabs-primary > li > a:focus {
  text-decoration: none;
  color: #000;
  background-color: #E8E8E8;
}

#content ul.tabs > li.active > a, #content ul.tabs > li.active > a:hover, #content ul.tabs > li.active > a:focus, #content ul.tabs > li.is-active > a, #content ul.tabs > li a.active,
ul.tabs-primary > li.active > a,
ul.tabs-primary > li.active > a:hover,
ul.tabs-primary > li.active > a:focus,
ul.tabs-primary > li.is-active > a,
ul.tabs-primary > li a.active {
  color: #000;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

#quicktabs-list ul.quicktabs-tabs {
  padding: 0;
}

ul.quicktabs-tabs,
ul.tabs-secondary {
  margin-bottom: 1em;
  padding: 10px 0 0;
}

ul.quicktabs-tabs:before, ul.quicktabs-tabs:after,
ul.tabs-secondary:before,
ul.tabs-secondary:after {
  display: table;
  line-height: 0;
  content: "";
}

ul.quicktabs-tabs:after,
ul.tabs-secondary:after {
  clear: both;
}

ul.quicktabs-tabs > li,
ul.tabs-secondary > li {
  float: left;
  margin-bottom: -1px;
  list-style: none;
}

ul.quicktabs-tabs > li > a,
ul.tabs-secondary > li > a {
  margin-right: 6px;
  line-height: 21px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 12px;
  display: block;
  color: #B1B1B1;
}

ul.quicktabs-tabs > li > a:hover, ul.quicktabs-tabs > li > a:focus,
ul.tabs-secondary > li > a:hover,
ul.tabs-secondary > li > a:focus {
  text-decoration: none;
  background-color: #EEE;
}

ul.quicktabs-tabs > li.active > a, ul.quicktabs-tabs > li.active > a:hover, ul.quicktabs-tabs > li.active > a:focus,
ul.tabs-secondary > li.active > a,
ul.tabs-secondary > li.active > a:hover,
ul.tabs-secondary > li.active > a:focus {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
}

.text-resize {
  list-style-type: none;
}

.text-resize, .text-resize li {
  margin: 0px;
  padding: 0px;
  display: inline;
}

.text-resize a {
  display: inline-block;
  width: 30px;
  height: 26px;
  line-height: 26px;
  color: #5c626c;
  outline: none;
  text-align: center;
  font-size: 1.4em;
}

.text-resize a:hover {
  color: #333;
  text-decoration: none;
}

/* Text-resize body text */
body.large-fonts {
  font-size: 1.30769em;
  line-height: 2.44706em;
  line-height: 1.6em;
}

body.large-fonts .text-resize li {
  margin-left: 0.3em;
}

.view-mode-teaser h3,
.node-teaser h3 {
  font-size: 1.23077em;
  line-height: 1.3em;
  line-height: 1.2;
  margin: 0.4em 0 0.8em;
}

.view-mode-teaser h3 a,
.node-teaser h3 a {
  color: #16c0f2;
}

@media (min-width: 360px) {
  .view-mode-teaser .field-type-image,
  .node-teaser .field-type-image {
    float: left;
    margin-right: 10px;
  }
}

.lt-ie9 .view-mode-teaser .field-type-image, .lt-ie9
.node-teaser .field-type-image {
  float: left;
  margin-right: 10px;
}

.view-mode-compact h3 {
  font-size: 1.23077em;
  line-height: 1.3em;
  line-height: 1.2;
  margin: 0.4em 0 0.8em;
}

.view-mode-compact h3 a {
  color: #16c0f2;
}

@media (min-width: 360px) {
  .view-mode-compact .field-type-image {
    float: left;
    margin-right: 10px;
  }
}

.lt-ie9 .view-mode-compact .field-type-image {
  float: left;
  margin-right: 10px;
}

.views-exposed-form label {
  font-size: 1em;
}

.views-reset-button .form-submit {
  background-image: none;
  background-color: transparent;
  border: none;
  color: #1f4ea1;
  padding-left: 0;
  padding-right: 0;
  outline: none;
}

.views-reset-button .form-submit:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media all and (min-width: 360px) {
  .views-exposed-form {
    display: flex;
  }
}

.views-exposed-form .filter-title {
  flex: 0 7em;
  color: #333;
  font-weight: 600;
  margin-top: 2.5em;
  margin-bottom: 1em;
}

@media all and (min-width: 360px) {
  .views-exposed-form .filter-title {
    padding-left: 12px;
  }
}

.exposed-filter-icons .views-exposed-form .filter-title {
  flex: 0 9.5em;
}

.exposed-filter-images .views-exposed-form .filter-title {
  flex: 0 14.5em;
}

.exposed-filter-sector-logos .views-exposed-form .filter-title {
  flex: 0 7em;
}

.exposed-filter-logos .views-exposed-form .filter-title {
  flex: 0 14em;
}

.exposed-filter-gallery-images .views-exposed-form .filter-title {
  flex: 0 54%;
}

.views-exposed-form .views-exposed-widgets {
  float: none;
}

@media all and (min-width: 360px) {
  .views-exposed-form .views-exposed-widgets {
    margin: 0 -12px;
    flex: 1;
    display: flex;
  }
}

.views-exposed-form .views-exposed-widgets .chosen-container .chosen-drop {
  background-color: #EFEFEC;
}

.views-exposed-form .views-exposed-widgets .views-exposed-widget {
  flex: 1;
  float: none;
}

@media all and (max-width: 359px) {
  .views-exposed-form .views-exposed-widgets .views-exposed-widget {
    padding-left: 0;
    padding-right: 0;
  }
}

.views-exposed-form .views-exposed-widgets .views-exposed-widget .chosen-single {
  min-width: 100%;
}

.views-exposed-form .views-exposed-widgets .views-submit-button,
.views-exposed-form .views-exposed-widgets .views-reset-button {
  width: auto;
  display: none;
}

#block-views-exp-calendar-block-1 .views-exposed-form .views-exposed-widgets .views-submit-button, #block-views-exp-calendar-block-1
.views-exposed-form .views-exposed-widgets .views-reset-button {
  width: auto;
}

.views-exposed-form .ajax-progress-throbber {
  display: none;
}

.views-exposed-form .views-exposed-widget {
  padding: 0.5em 1em 0 0;
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 0;
}

.views-exposed-form .views-reset-button {
  padding-right: 0;
  padding-top: 0;
}

.views-exposed-form .views-submit-button {
  padding: 0 1em 0 0;
}

.block .block__content .views-exposed-widget .container-inline-date > .form-item {
  display: block;
}

.block .block__content .views-exposed-widget .container-inline-date > .form-item .date-padding,
.block .block__content .views-exposed-widget .container-inline-date > .form-item .form-item {
  float: none;
}

.view .content .item-list ul,
.view .view-content .item-list ul {
  color: #16c1f3;
  margin-top: 0;
}

.view .content .item-list ul li,
.view .view-content .item-list ul li {
  color: #5c626c;
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left center;
  background-size: 10px 9px;
  list-style: none;
  padding-left: 20px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 13px;
  line-height: 16px;
}

.view .content .item-list ul li a,
.view .view-content .item-list ul li a {
  color: #1f4ea1;
}

.view .content .item-list ul li a:hover,
.view .view-content .item-list ul li a:hover {
  text-decoration: underline;
}

.view .content .item-list ul li,
.view .view-content .item-list ul li {
  font-size: 1.2307em;
  line-height: 1.3em;
}

ul.views-summary {
  color: #16c1f3;
  margin-top: 0;
}

ul.views-summary li {
  color: #5c626c;
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left center;
  background-size: 10px 9px;
  list-style: none;
  padding-left: 20px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 13px;
  line-height: 16px;
}

ul.views-summary li a {
  color: #1f4ea1;
}

ul.views-summary li a:hover {
  text-decoration: underline;
}

ul.views-summary li {
  font-size: 1.2307em;
  line-height: 1.3em;
}

.view-newsletters ul li {
  list-style-image: url("../images/icon-link.png");
  padding-left: 3px;
  margin-left: 5px;
}

.video-filter,
.media-youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-filter iframe,
.video-filter object,
.video-filter embed,
.media-youtube-video iframe,
.media-youtube-video object,
.media-youtube-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-filter .video-filter {
  padding: 0;
}

.view-news-topics,
.view-event-topics {
  padding: 1em 0;
  border-top: 1px solid #d7d6db;
  border-bottom: 1px solid #d7d6db;
  clear: both;
}

.view-news-topics .view-header,
.view-news-topics .view-content,
.view-event-topics .view-header,
.view-event-topics .view-content {
  display: inline-block;
}

.view-news-topics .view-label,
.view-event-topics .view-label {
  font-weight: bold;
  padding-right: 0.5em;
}

.view-event-date .field-label {
  width: 36%;
  float: left;
  font-weight: bold;
  padding-right: 0.5em;
}

.view-event-date .field-date {
  float: left;
  width: 64%;
}

.view-mindframe .views-row .teaser-right {
  display: flex;
}

.view-mindframe .views-row .field-teaser-icon {
  flex: 0 0 70px;
  padding-right: 1em;
}

.view-mindframe .views-row .field-body {
  flex: 1;
}

.view-child-pages .views-row {
  display: flex;
}

.view-child-pages .views-row img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

.view-child-pages .views-row h3 {
  color: #333;
}

.view-child-pages .views-row .field-teaser-icon {
  float: left;
  flex: 0 0 70px;
  padding-right: 1em;
}

.view-child-pages .views-row .teaser-icon-right {
  flex: 1;
}

.view-need-help-teasers .views-row {
  display: flex;
}

@media (min-width: 768px) {
  .view-need-help-teasers .views-row .teaser-left {
    flex: 0 25%;
  }
  .view-need-help-teasers .views-row .teaser-right {
    flex: 0 75%;
  }
}

.lt-ie9 .view-need-help-teasers .views-row .teaser-left {
  flex: 0 25%;
}

.lt-ie9 .view-need-help-teasers .views-row .teaser-right {
  flex: 0 75%;
}

.view-qmhc-publications .item-list li {
  font-size: 1em !important;
}

.view-teasers .views-row,
.block-teaser .views-row {
  padding: 12px;
}

.view-teasers .views-row:hover,
.block-teaser .views-row:hover {
  background-color: #f1fafc;
}

@media (min-width: 360px) {
  .view-teasers .views-row.u-faux-block-link,
  .block-teaser .views-row.u-faux-block-link {
    display: flex;
    padding: 12px;
  }
}

.lt-ie9 .view-teasers .views-row.u-faux-block-link, .lt-ie9
.block-teaser .views-row.u-faux-block-link {
  display: flex;
  padding: 12px;
}

.view-teasers .views-row .field-body a,
.block-teaser .views-row .field-body a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

.view-teasers .views-row .field-body a:hover,
.block-teaser .views-row .field-body a:hover {
  text-decoration: underline !important;
}

.view-teasers .views-row h3.field-title,
.block-teaser .views-row h3.field-title {
  font-size: 1.3em;
  display: inline;
}

.view-teasers .views-row:hover .field-teaser-icon img,
.view-teasers .views-row:hover .field-feature-image img,
.block-teaser .views-row:hover .field-teaser-icon img,
.block-teaser .views-row:hover .field-feature-image img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

@media (min-width: 360px) {
  .view-teasers .views-row .teaser-right,
  .block-teaser .views-row .teaser-right {
    flex: 1;
  }
}

.lt-ie9 .view-teasers .views-row .teaser-right, .lt-ie9
.block-teaser .views-row .teaser-right {
  flex: 1;
}

.view-sector-news .field-logo {
  width: 100%;
  padding-bottom: 1em;
}

@media (min-width: 360px) {
  .view-sector-news .field-logo {
    width: 45%;
    float: right;
    padding-left: 1em;
  }
}

.lt-ie9 .view-sector-news .field-logo {
  width: 45%;
  float: right;
  padding-left: 1em;
}

.view-sector-news .field-logo img {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 360px) {
  .view-sector-news .field-logo img {
    float: right;
    padding: 0 0 5px 20px;
  }
}

.lt-ie9 .view-sector-news .field-logo img {
  float: right;
  padding: 0 0 5px 20px;
}

#block-views-sector-news-block-2 .views-row {
  padding: 12px;
}

#block-views-sector-news-block-2 .views-row:hover {
  background-color: #f1fafc;
}

@media (min-width: 360px) {
  #block-views-sector-news-block-2 .views-row.u-faux-block-link {
    padding: 12px;
  }
}

.lt-ie9 #block-views-sector-news-block-2 .views-row.u-faux-block-link {
  padding: 12px;
}

#block-views-sector-news-block-2 .views-row .field-body a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

#block-views-sector-news-block-2 .views-row .field-body a:hover {
  text-decoration: underline !important;
}

#block-views-sector-news-block-2 .views-row h3.field-title {
  font-size: 1.3em;
  display: inline;
}

#block-views-sector-news-block-2 .views-row .field-date {
  color: #24ace0;
  padding-left: 0.3em;
  font-size: 1em;
  display: inline;
}

#block-views-sector-news-block-2 .views-row:hover .field-teaser-icon img,
#block-views-sector-news-block-2 .views-row:hover .field-feature-image img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

@media (min-width: 360px) {
  #block-views-sector-news-block-2 .views-row .teaser-right {
    flex: 1;
  }
}

.lt-ie9 #block-views-sector-news-block-2 .views-row .teaser-right {
  flex: 1;
}

@media (min-width: 360px) {
  .view-gallery-teasers .view-content,
  .block-gallery-teaser .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

.lt-ie9 .view-gallery-teasers .view-content, .lt-ie9
.block-gallery-teaser .view-content {
  display: flex;
  flex-wrap: wrap;
}

.view-gallery-teasers .views-row,
.block-gallery-teaser .views-row {
  flex: 0 50%;
  margin-bottom: 1em;
}

@media (min-width: 360px) {
  .view-gallery-teasers .views-row,
  .block-gallery-teaser .views-row {
    margin-bottom: 0;
  }
}

.lt-ie9 .view-gallery-teasers .views-row, .lt-ie9
.block-gallery-teaser .views-row {
  margin-bottom: 0;
}

.view-gallery-teasers .views-row:hover,
.block-gallery-teaser .views-row:hover {
  background-color: #f1fafc;
}

@media (min-width: 360px) {
  .view-gallery-teasers .views-row.u-faux-block-link,
  .block-gallery-teaser .views-row.u-faux-block-link {
    padding: 12px;
  }
}

.lt-ie9 .view-gallery-teasers .views-row.u-faux-block-link, .lt-ie9
.block-gallery-teaser .views-row.u-faux-block-link {
  padding: 12px;
}

.view-gallery-teasers .views-row h3.field-title,
.block-gallery-teaser .views-row h3.field-title {
  font-size: 1.3em;
  display: inline;
}

.view-gallery-teasers .views-row:hover .field-feature-image img,
.block-gallery-teaser .views-row:hover .field-feature-image img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

@media (min-width: 360px) {
  .view-gallery-other-teasers .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

.lt-ie9 .view-gallery-other-teasers .view-content {
  display: flex;
  flex-wrap: wrap;
}

.view-gallery-other-teasers .views-row {
  flex: 0 33.3%;
  margin-bottom: 1em;
}

@media (min-width: 360px) {
  .view-gallery-other-teasers .views-row {
    margin-bottom: 0;
  }
}

.lt-ie9 .view-gallery-other-teasers .views-row {
  margin-bottom: 0;
}

.view-gallery-other-teasers .views-row:hover {
  background-color: #f1fafc;
}

@media (min-width: 360px) {
  .view-gallery-other-teasers .views-row.u-faux-block-link {
    padding: 12px;
  }
}

.lt-ie9 .view-gallery-other-teasers .views-row.u-faux-block-link {
  padding: 12px;
}

.view-gallery-other-teasers .views-row h3.field-title {
  font-size: 1.3em;
  display: inline;
}

.view-gallery-other-teasers .views-row:hover .field-feature-image img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

#block-views-images-media-centre .view-images {
  overflow: hidden;
}

#block-views-images-media-centre .view-footer {
  float: right;
}

#block-views-images-media-centre .view-footer a.more-link {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
  display: inline-block;
  padding-right: 1em;
}

#block-views-images-media-centre .view-footer a.more-link:hover {
  text-decoration: underline !important;
}

.teaser-vertical h3.field-title {
  font-size: 1.3em;
}

.view-display-id-other_images_field h2.title {
  font-size: 1.5em;
}

figure > figcaption {
  padding: .6em 1em;
}

#block-views-child-pages-block-1 {
  margin-top: -1.5em;
}

/* Paragraphs */
.entity-paragraphs-item {
  float: left;
  clear: left;
  width: 100%;
  padding-bottom: 0.8em;
}

.entity-paragraphs-item h1, .entity-paragraphs-item h2, .entity-paragraphs-item h3, .entity-paragraphs-item h4, .entity-paragraphs-item h5, .entity-paragraphs-item h6 {
  margin-top: 0.16em;
  margin-bottom: 0.16em;
}

.entity-paragraphs-item .field {
  margin-top: 0.16em;
  margin-bottom: 0.16em;
  float: left;
  clear: left;
  width: 100%;
}

.field-type-paragraphs {
  margin: 0;
}

.paragraphs-item_view-mode_tile-text,
.paragraphs-item_view-mode_tile-image {
  box-sizing: border-box;
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .paragraphs-item_view-mode_tile-text,
  .paragraphs-item_view-mode_tile-image {
    padding: 0.8em;
    margin-left: 0.8em;
    margin-right: 0.8em;
    margin-bottom: 0.8em;
  }
  .paragraphs-item_view-mode_tile-text:first-child,
  .paragraphs-item_view-mode_tile-image:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .paragraphs-item_view-mode_tile-text:last-child,
  .paragraphs-item_view-mode_tile-image:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}

.lt-ie9 .paragraphs-item_view-mode_tile-text, .lt-ie9
.paragraphs-item_view-mode_tile-image {
  padding: 0.8em;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
}

.lt-ie9 .paragraphs-item_view-mode_tile-text:first-child, .lt-ie9
.paragraphs-item_view-mode_tile-image:first-child {
  margin-left: 0;
  padding-left: 0;
}

.lt-ie9 .paragraphs-item_view-mode_tile-text:last-child, .lt-ie9
.paragraphs-item_view-mode_tile-image:last-child {
  margin-right: 0;
  padding-right: 0;
}

.paragraphs-item_view-mode_teaser,
.paragraphs-item_view-mode_teaser-title,
.paragraphs-item_view-mode_teaser-image {
  clear: both;
  overflow: hidden;
  *zoom: 1;
}

@media (min-width: 768px) {
  .paragraphs-item_view-mode_teaser .field,
  .paragraphs-item_view-mode_teaser-title .field,
  .paragraphs-item_view-mode_teaser-image .field {
    float: left;
    box-sizing: border-box;
  }
  .paragraphs-item_view-mode_teaser .field-name-field-pbundle-title,
  .paragraphs-item_view-mode_teaser-title .field-name-field-pbundle-title,
  .paragraphs-item_view-mode_teaser-image .field-name-field-pbundle-title {
    float: none;
  }
  .paragraphs-item_view-mode_teaser .field-name-field-pbundle-image,
  .paragraphs-item_view-mode_teaser-title .field-name-field-pbundle-image,
  .paragraphs-item_view-mode_teaser-image .field-name-field-pbundle-image {
    width: 35%;
    margin-right: 1.6em;
  }
  .paragraphs-item_view-mode_teaser .field-name-field-pbundle-text,
  .paragraphs-item_view-mode_teaser-title .field-name-field-pbundle-text,
  .paragraphs-item_view-mode_teaser-image .field-name-field-pbundle-text {
    width: 60%;
    clear: none;
  }
}

.lt-ie9 .paragraphs-item_view-mode_teaser .field, .lt-ie9
.paragraphs-item_view-mode_teaser-title .field, .lt-ie9
.paragraphs-item_view-mode_teaser-image .field {
  float: left;
  box-sizing: border-box;
}

.lt-ie9 .paragraphs-item_view-mode_teaser .field-name-field-pbundle-title, .lt-ie9
.paragraphs-item_view-mode_teaser-title .field-name-field-pbundle-title, .lt-ie9
.paragraphs-item_view-mode_teaser-image .field-name-field-pbundle-title {
  float: none;
}

.lt-ie9 .paragraphs-item_view-mode_teaser .field-name-field-pbundle-image, .lt-ie9
.paragraphs-item_view-mode_teaser-title .field-name-field-pbundle-image, .lt-ie9
.paragraphs-item_view-mode_teaser-image .field-name-field-pbundle-image {
  width: 35%;
  margin-right: 1.6em;
}

.lt-ie9 .paragraphs-item_view-mode_teaser .field-name-field-pbundle-text, .lt-ie9
.paragraphs-item_view-mode_teaser-title .field-name-field-pbundle-text, .lt-ie9
.paragraphs-item_view-mode_teaser-image .field-name-field-pbundle-text {
  width: 60%;
  clear: none;
}

.paragraphs-layout__tiles-2 > .field > .field-items > .field-item {
  box-sizing: border-box;
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .paragraphs-layout__tiles-2 > .field > .field-items > .field-item {
    padding: 0.8em;
    margin-left: 0.8em;
    margin-right: 0.8em;
    margin-bottom: 0.8em;
  }
  .paragraphs-layout__tiles-2 > .field > .field-items > .field-item:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .paragraphs-layout__tiles-2 > .field > .field-items > .field-item:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-2 > .field > .field-items > .field-item {
  padding: 0.8em;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
}

.lt-ie9 .paragraphs-layout__tiles-2 > .field > .field-items > .field-item:first-child {
  margin-left: 0;
  padding-left: 0;
}

.lt-ie9 .paragraphs-layout__tiles-2 > .field > .field-items > .field-item:last-child {
  margin-right: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .paragraphs-layout__tiles-2 > .field > .field-items > .field-item {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    width: 47.8%;
  }
  .paragraphs-layout__tiles-2 > .field > .field-items > .field-item:nth-child(2) {
    margin-right: 0;
  }
  .paragraphs-layout__tiles-2 > .field > .field-items > .field-item:nth-child(3) {
    margin-left: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-2 > .field > .field-items > .field-item {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  width: 47.8%;
}

.lt-ie9 .paragraphs-layout__tiles-2 > .field > .field-items > .field-item:nth-child(2) {
  margin-right: 0;
}

.lt-ie9 .paragraphs-layout__tiles-2 > .field > .field-items > .field-item:nth-child(3) {
  margin-left: 0;
}

.paragraphs-layout__tiles-2.field-type-viewfield .views-row {
  box-sizing: border-box;
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .paragraphs-layout__tiles-2.field-type-viewfield .views-row {
    padding: 0.8em;
    margin-left: 0.8em;
    margin-right: 0.8em;
    margin-bottom: 0.8em;
  }
  .paragraphs-layout__tiles-2.field-type-viewfield .views-row:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .paragraphs-layout__tiles-2.field-type-viewfield .views-row:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-2.field-type-viewfield .views-row {
  padding: 0.8em;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
}

.lt-ie9 .paragraphs-layout__tiles-2.field-type-viewfield .views-row:first-child {
  margin-left: 0;
  padding-left: 0;
}

.lt-ie9 .paragraphs-layout__tiles-2.field-type-viewfield .views-row:last-child {
  margin-right: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .paragraphs-layout__tiles-2.field-type-viewfield .views-row {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    width: 47.8%;
  }
  .paragraphs-layout__tiles-2.field-type-viewfield .views-row:nth-child(2) {
    margin-right: 0;
  }
  .paragraphs-layout__tiles-2.field-type-viewfield .views-row:nth-child(3) {
    margin-left: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-2.field-type-viewfield .views-row {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  width: 47.8%;
}

.lt-ie9 .paragraphs-layout__tiles-2.field-type-viewfield .views-row:nth-child(2) {
  margin-right: 0;
}

.lt-ie9 .paragraphs-layout__tiles-2.field-type-viewfield .views-row:nth-child(3) {
  margin-left: 0;
}

.paragraphs-layout__tiles-3 > .field > .field-items > .field-item, .paragraphs-layout__tiles-3.field-type-viewfield .views-row {
  box-sizing: border-box;
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item, .paragraphs-layout__tiles-3.field-type-viewfield .views-row {
    padding: 0.8em;
    margin-left: 0.8em;
    margin-right: 0.8em;
    margin-bottom: 0.8em;
  }
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:first-child, .paragraphs-layout__tiles-3.field-type-viewfield .views-row:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:last-child, .paragraphs-layout__tiles-3.field-type-viewfield .views-row:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item, .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row, .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row {
  padding: 0.8em;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:first-child, .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row:first-child, .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row:first-child {
  margin-left: 0;
  padding-left: 0;
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:last-child, .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row:last-child, .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row:last-child {
  margin-right: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item, .paragraphs-layout__tiles-3.field-type-viewfield .views-row {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    width: 47.8%;
  }
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(2), .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(2) {
    margin-right: 0;
  }
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(3), .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(3) {
    margin-left: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item, .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row, .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  width: 47.8%;
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(2), .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(2), .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row:nth-child(2) {
  margin-right: 0;
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(3), .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(3), .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row:nth-child(3) {
  margin-left: 0;
}

@media (min-width: 980px) {
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item, .paragraphs-layout__tiles-3.field-type-viewfield .views-row {
    width: 31.3%;
  }
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(2), .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(2) {
    margin-right: 0.8em;
  }
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(3), .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(3) {
    margin-left: 0.8em;
  }
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(3), .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(3) {
    margin-right: 0;
  }
  .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(4), .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(4) {
    margin-left: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item, .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row, .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row {
  width: 31.3%;
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(2), .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(2), .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row:nth-child(2) {
  margin-right: 0.8em;
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(3), .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(3), .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row:nth-child(3) {
  margin-left: 0.8em;
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(3), .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(3), .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row:nth-child(3) {
  margin-right: 0;
}

.lt-ie9 .paragraphs-layout__tiles-3 > .field > .field-items > .field-item:nth-child(4), .lt-ie9 .paragraphs-layout__tiles-3.field-type-viewfield .views-row:nth-child(4), .paragraphs-layout__tiles-3.field-type-viewfield .lt-ie9 .views-row:nth-child(4) {
  margin-left: 0;
}

.paragraphs-layout__tiles-4 > .field > .field-items > .field-item, .paragraphs-layout__tiles-4.field-type-viewfield .views-row {
  box-sizing: border-box;
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item, .paragraphs-layout__tiles-4.field-type-viewfield .views-row {
    padding: 0.8em;
    margin-left: 0.8em;
    margin-right: 0.8em;
    margin-bottom: 0.8em;
  }
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:first-child, .paragraphs-layout__tiles-4.field-type-viewfield .views-row:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:last-child, .paragraphs-layout__tiles-4.field-type-viewfield .views-row:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item, .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row, .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row {
  padding: 0.8em;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:first-child, .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row:first-child, .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row:first-child {
  margin-left: 0;
  padding-left: 0;
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:last-child, .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row:last-child, .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row:last-child {
  margin-right: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item, .paragraphs-layout__tiles-4.field-type-viewfield .views-row {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    width: 47.8%;
  }
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(2), .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(2) {
    margin-right: 0;
  }
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(3), .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(3) {
    margin-left: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item, .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row, .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  width: 47.8%;
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(2), .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(2), .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row:nth-child(2) {
  margin-right: 0;
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(3), .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(3), .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row:nth-child(3) {
  margin-left: 0;
}

@media (min-width: 980px) {
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item, .paragraphs-layout__tiles-4.field-type-viewfield .views-row {
    width: 24%;
  }
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(2), .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(2) {
    margin-right: 0.8em;
  }
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(3), .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(3) {
    margin-left: 0.8em;
  }
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(4), .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(4) {
    margin-right: 0;
  }
  .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(5), .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(5) {
    margin-left: 0;
  }
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item, .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row, .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row {
  width: 24%;
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(2), .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(2), .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row:nth-child(2) {
  margin-right: 0.8em;
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(3), .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(3), .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row:nth-child(3) {
  margin-left: 0.8em;
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(4), .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(4), .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row:nth-child(4) {
  margin-right: 0;
}

.lt-ie9 .paragraphs-layout__tiles-4 > .field > .field-items > .field-item:nth-child(5), .lt-ie9 .paragraphs-layout__tiles-4.field-type-viewfield .views-row:nth-child(5), .paragraphs-layout__tiles-4.field-type-viewfield .lt-ie9 .views-row:nth-child(5) {
  margin-left: 0;
}

.paragraphs-layout__tiles-4.field-type-viewfield .view-content {
  width: 100%;
  clear: left;
}

.paragraphs-item-view .view .more-link {
  width: 100%;
  clear: left;
}

.field-type-viewfield.paragraphs-layout__tiles-2 .views-row {
  box-sizing: border-box;
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .field-type-viewfield.paragraphs-layout__tiles-2 .views-row {
    padding: 0.8em;
    margin-left: 0.8em;
    margin-right: 0.8em;
    margin-bottom: 0.8em;
  }
  .field-type-viewfield.paragraphs-layout__tiles-2 .views-row:first-child {
    margin-left: 0;
    padding-left: 0;
  }
  .field-type-viewfield.paragraphs-layout__tiles-2 .views-row:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}

.lt-ie9 .field-type-viewfield.paragraphs-layout__tiles-2 .views-row {
  padding: 0.8em;
  margin-left: 0.8em;
  margin-right: 0.8em;
  margin-bottom: 0.8em;
}

.lt-ie9 .field-type-viewfield.paragraphs-layout__tiles-2 .views-row:first-child {
  margin-left: 0;
  padding-left: 0;
}

.lt-ie9 .field-type-viewfield.paragraphs-layout__tiles-2 .views-row:last-child {
  margin-right: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .field-type-viewfield.paragraphs-layout__tiles-2 .views-row {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    width: 47.8%;
  }
  .field-type-viewfield.paragraphs-layout__tiles-2 .views-row:nth-child(2) {
    margin-right: 0;
  }
  .field-type-viewfield.paragraphs-layout__tiles-2 .views-row:nth-child(3) {
    margin-left: 0;
  }
}

.lt-ie9 .field-type-viewfield.paragraphs-layout__tiles-2 .views-row {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  width: 47.8%;
}

.lt-ie9 .field-type-viewfield.paragraphs-layout__tiles-2 .views-row:nth-child(2) {
  margin-right: 0;
}

.lt-ie9 .field-type-viewfield.paragraphs-layout__tiles-2 .views-row:nth-child(3) {
  margin-left: 0;
}

.date-display-single {
  line-height: 1.8em;
}

.node-staff-news .date-display-single {
  color: #ADA29F;
}

.block-webform .webform-component-markup {
  margin: 0;
}

.block-webform .webform-component-markup p {
  margin-top: 0;
}

.block-webform .webform-component--your-idea {
  margin-top: 0;
}

.view .view-empty {
  padding: 1em 0;
}

.wrapper .views-row .group-left {
  float: left;
  width: 95px;
  margin-right: 1em;
  text-align: center;
  min-height: 5.1em;
}

@media (min-width: 768px) {
  .wrapper .views-row .group-left {
    width: 37%;
    max-width: 95px;
  }
}

.lt-ie9 .wrapper .views-row .group-left {
  width: 37%;
  max-width: 95px;
}

.wrapper .views-row .group-left a {
  display: block;
  width: 100%;
  color: #fff;
}

.wrapper .views-row .group-left a .cal-day {
  color: #323232;
}

.wrapper .views-row .group-left a .cal-day span {
  color: #323232;
  font-weight: 600;
  font-size: 1.538em;
}

.wrapper .views-row .group-left a > span {
  display: block;
}

.wrapper .views-row .group-left a > span span {
  line-height: inherit;
}

.wrapper .views-row .group-left a .field-location {
  font-size: 1.153em;
  line-height: 1.7em;
}

.wrapper .views-row .group-left a:hover {
  text-decoration: none;
  opacity: 0.9;
  color: #fff;
}

.wrapper .views-row .group-right {
  width: 61%;
  float: right;
}

.view-events .item-list ul li {
  font-size: 1em !important;
}

#page #block-views-newsletters-block .more-link {
  float: right;
}

.view-second-row .view-content {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .view-second-row .view-content {
    flex-wrap: nowrap;
  }
}

.lt-ie9 .view-second-row .view-content {
  flex-wrap: nowrap;
}

.view-second-row .view-content .views-row:hover {
  background: #EEF9FD;
}

.view-second-row .view-content .views-row:hover img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

.view-second-row .view-content .views-row:hover h2 {
  color: #333;
}

@media (min-width: 360px) {
  .view-second-row .view-content .views-row:first-child h2 {
    padding-right: 0;
  }
}

.lt-ie9 .view-second-row .view-content .views-row:first-child h2 {
  padding-right: 0;
}

.view-second-row .view-content .views-row::after {
  content: " ";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(to right, #225cad 0%, #2799d7 100%);
}

@media (min-width: 768px) {
  .view-second-row .view-content .views-row::after {
    display: none;
  }
}

.lt-ie9 .view-second-row .view-content .views-row::after {
  display: none;
}

.view-second-row .view-content .views-row-last::after {
  display: none;
}

.view-second-row .view-content > div {
  padding: 12px 0;
  flex: 0 100%;
}

@media (min-width: 768px) {
  .view-second-row .view-content > div {
    flex: 0 25%;
  }
}

.lt-ie9 .view-second-row .view-content > div {
  flex: 0 25%;
}

@media (min-width: 768px) {
  .view-second-row .view-content > div > div,
  .view-second-row .view-content > div > h2 {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.lt-ie9 .view-second-row .view-content > div > div, .lt-ie9
.view-second-row .view-content > div > h2 {
  margin-left: 12px;
  margin-right: 12px;
}

.view-second-row .view-content > div .field-feature-image {
  display: none;
}

@media (min-width: 768px) {
  .view-second-row .view-content > div .field-feature-image {
    display: block;
  }
}

.lt-ie9 .view-second-row .view-content > div .field-feature-image {
  display: block;
}

.view-second-row .view-content > div h2 {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1em;
  margin-top: 0.2em;
  margin-bottom: 0.4em;
}

.large-fonts .view-second-row .view-content > div h2 {
  font-size: 2em;
}

@media (min-width: 360px) {
  .view-second-row .view-content > div h2 {
    padding-right: 1em;
  }
}

.lt-ie9 .view-second-row .view-content > div h2 {
  padding-right: 1em;
}

@media all and (min-width: 650px) {
  .view-second-row .view-content > div h2 {
    min-height: 1em;
    padding-right: 2em;
  }
}

@media (min-width: 768px) {
  .view-second-row .view-content > div h2 {
    min-height: 2em;
    padding-right: 1em;
  }
  .large-fonts .view-second-row .view-content > div h2 {
    padding-right: 0;
    min-height: 3em;
    font-size: 1.8em;
  }
}

.lt-ie9 .view-second-row .view-content > div h2 {
  min-height: 2em;
  padding-right: 1em;
}

.large-fonts .lt-ie9 .view-second-row .view-content > div h2 {
  padding-right: 0;
  min-height: 3em;
  font-size: 1.8em;
}

@media all and (min-width: 894px) {
  .large-fonts .view-second-row .view-content > div h2 {
    min-height: 2em;
  }
}

@media (min-width: 980px) {
  .view-second-row .view-content > div h2 {
    font-size: 2.2076em;
    padding-right: 2em;
  }
  .large-fonts .view-second-row .view-content > div h2 {
    font-size: 2em;
  }
}

.lt-ie9 .view-second-row .view-content > div h2 {
  font-size: 2.2076em;
  padding-right: 2em;
}

.large-fonts .lt-ie9 .view-second-row .view-content > div h2 {
  font-size: 2em;
}

.view-second-row .view-content > div h2 a {
  color: #5c626c;
}

.view-second-row .view-content > div img {
  width: 100%;
}

.read-more {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

.read-more:hover {
  text-decoration: underline !important;
}

.expand-collapse-button {
  display: block;
  float: right;
  position: relative;
  top: -20px;
  font-size: 0.9em;
  cursor: pointer;
}

.extra-rows {
  display: none;
}

.view-teaser-icons .views-row {
  padding: 9px 0;
  margin: 0 12px;
}

@media all and (min-width: 290px) {
  .view-teaser-icons .views-row {
    display: flex;
  }
  .view-teaser-icons .views-row .teaser-left {
    flex: 0 80px;
  }
  .view-teaser-icons .views-row .teaser-right {
    flex: 1;
    padding-left: 12px;
  }
}

.view-teaser-icons .views-row .field-image-wrap {
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
}

.view-teaser-icons .views-row .field-image-wrap img {
  border: none;
  padding: 0;
  margin: 0 auto;
}

.view-teaser-icons .views-row .icon-media-release img {
  width: 80%;
}

.view-teaser-icons .views-row .icon-commissioner-viewpoint img {
  width: 80%;
}

#block-views-events-block-6 .views-row {
  padding: 0 12px;
}

#block-views-events-block-6 .views-row:hover .field-teaser-icon img,
#block-views-events-block-6 .views-row:hover .field-feature-image img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

@media (min-width: 360px) {
  .view-teaser-images .field-feature-video,
  .view-teaser-images .field-feature-image {
    width: 140px;
    margin-right: 18px;
    background-color: #FFF;
  }
}

.lt-ie9 .view-teaser-images .field-feature-video, .lt-ie9
.view-teaser-images .field-feature-image {
  width: 140px;
  margin-right: 18px;
  background-color: #FFF;
}

@media all and (min-width: 420px) {
  .view-teaser-images .field-feature-video,
  .view-teaser-images .field-feature-image {
    margin-right: 24px;
  }
}

@media (min-width: 360px) {
  .view-teaser-logos .field-feature-video,
  .view-teaser-logos .field-feature-image {
    width: 140px;
    margin-right: 18px;
  }
}

.lt-ie9 .view-teaser-logos .field-feature-video,
.lt-ie9 .view-teaser-logos .field-feature-image {
  width: 140px;
  margin-right: 18px;
}

@media (min-width: 360px) {
  .view-teaser-sector-logos .field-feature-image {
    width: 160px;
    margin-right: 18px;
  }
}

.lt-ie9 .view-teaser-sector-logos .field-feature-image {
  width: 160px;
  margin-right: 18px;
}

.view-tags {
  margin-bottom: 3em;
}

.view-tags ul {
  color: #16c1f3;
}

.view-tags ul li {
  color: #5c626c;
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left center;
  background-size: 10px 9px;
  list-style: none;
  padding-left: 20px;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 13px;
  line-height: 16px;
}

.view-tags ul li a {
  color: #1f4ea1;
}

.view-tags ul li a:hover {
  text-decoration: underline;
}

.view-tags h3 {
  font-weight: normal;
}

.view-tags .field-term {
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left center;
  background-size: 10px 9px;
  list-style: none;
  padding-left: 20px;
}

/* Sections */
div.workbench-info-block {
  padding: 1em;
  line-height: 1.5em;
  border-style: solid;
}

div.workbench-info-block select, div.workbench-info-block input {
  width: auto;
}

#admin-menu-icon li img {
  width: 15px;
  height: 15px;
}

@media all and (max-width: 331px) {
  html body.admin-menu {
    margin-top: 101px !important;
  }
}

@media all and (min-width: 332px) and (max-width: 462px) {
  html body.admin-menu {
    margin-top: 77px !important;
  }
}

@media all and (min-width: 463px) and (max-width: 862px) {
  html body.admin-menu {
    margin-top: 58px !important;
  }
}

#admin-menu, #admin-menu .dropdown {
  z-index: 9999;
}

body {
  color: #231f20;
  background-color: #fff;
}

#content .block ul.menu {
  margin-top: 10px;
  padding-bottom: 13px;
  margin-bottom: 0;
  overflow: hidden;
  list-style: none;
}

#content .region-sidebar-first .block ul.menu ul.menu {
  margin-top: 0;
  padding-bottom: 0;
  font-size: 0.9em;
  line-height: 1.5em;
}

.block-filter {
  margin-bottom: 0 !important;
}

#block-views-commissioners-update-block-1 .field-date,
#block-views-events-block-1 .field-date {
  background: linear-gradient(to right, #63ae6b 0%, #90ba56 100%);
  position: absolute;
  font-size: 0.8461em;
  line-height: 1.89091em;
  padding: 0 8px;
  color: #fff;
}

#block-views-commissioners-update-block-1 .slick__arrow,
#block-views-events-block-1 .slick__arrow {
  background-color: #e9edf6;
  margin: 0;
  overflow: hidden;
}

#block-views-commissioners-update-block-1 .slick__arrow label,
#block-views-events-block-1 .slick__arrow label {
  margin-bottom: 11px;
}

#block-views-commissioners-update-block-1 .slick__arrow ul,
#block-views-events-block-1 .slick__arrow ul {
  margin-top: 0;
}

#block-views-commissioners-update-block-1 .slick__arrow button,
#block-views-events-block-1 .slick__arrow button {
  background-size: 10px 9px !important;
  text-transform: lowercase;
  list-style: none;
  font-size: 11px;
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 6px;
  outline: 0;
  line-height: 1.3em;
  border: none;
  color: #1f4ea1;
}

#block-views-commissioners-update-block-1 .slick__arrow button:hover,
#block-views-events-block-1 .slick__arrow button:hover {
  color: #333;
  cursor: pointer;
}

#block-views-commissioners-update-block-1 .slick__arrow button:first-child,
#block-views-events-block-1 .slick__arrow button:first-child {
  background: transparent url("../images/blue-arrow-right.svg") no-repeat scroll left center;
  float: left !important;
  padding-left: 15px;
  margin-left: 10px;
  width: 6em;
}

#block-views-commissioners-update-block-1 .slick__arrow button:last-child,
#block-views-events-block-1 .slick__arrow button:last-child {
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll right center;
  float: right !important;
  padding-right: 10px;
  margin-right: 10px;
  width: 4.3em;
}

#block-block-6 .block-inner {
  background: #e5f6fd;
  padding: 4px 12px 15px;
}

@media all and (max-width: 768px) {
  #block-block-6 .block-inner {
    padding-top: 12px;
  }
}

#block-block-6 .block-inner .sane-wrap {
  display: flex;
}

#block-block-6 .block-inner .sane-wrap .sane-text {
  display: flex;
  align-items: center;
  color: #1f4fa1;
  font-size: 17px;
  padding-right: 10px;
  flex: 1;
}

#block-block-6 .block-inner .sane-wrap .sane-button a {
  display: block;
  width: 66px;
  height: auto;
}

#block-block-6 .block-inner h2 {
  color: #00abf0;
  font-size: 22px;
  line-height: 1.3em;
  margin: 0;
}

@media all and (max-width: 768px) {
  #block-block-6 .block-inner h2 {
    font-size: 18px;
  }
}

#block-block-3 .block-inner {
  background: #f5f9eb;
  padding: 15px 12px;
}

.not-front #block-block-3 .block-inner {
  padding: 4px 12px 15px;
}

@media all and (max-width: 768px) {
  .not-front #block-block-3 .block-inner {
    padding: 15px 12px;
  }
}

#block-block-3 .block-inner .hs_recaptcha {
  display: none;
}

#block-block-3 .block-inner input[type=submit] {
  background-color: #24ace0;
  height: 3em;
  border: none;
  color: #fff;
  padding: 0;
  text-transform: uppercase;
  font-size: 0.8461em;
  line-height: 3.1em;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  -ms-border-radius: 1.5em;
  -o-border-radius: 1.5em;
  border-radius: 1.5em;
  width: 3em;
  border: none;
}

#block-block-3 .block-inner input[type=submit]:hover {
  background-color: #21a4d6;
}

#block-block-3 .block-inner #em_subscribe_form {
  width: 100%;
  font-size: inherit !important;
}

#block-block-3 .block-inner form {
  padding: 0.5em 0;
  font-size: 14px;
  width: auto !important;
  display: flex;
  align-items: center;
}

.front #block-block-3 .block-inner form {
  padding: 0;
}

#block-block-3 .block-inner form label {
  display: none;
}

#block-block-3 .block-inner form .hs_error_rollup,
#block-block-3 .block-inner form .hs-error-msgs {
  display: none !important;
}

.not-front #block-block-3 .block-inner form {
  flex-wrap: wrap;
}

#block-block-3 .block-inner form > div:first-child {
  font-size: 1.2em;
}

@media all and (max-width: 520px) {
  .not-front #block-block-3 .block-inner form > div:first-child {
    flex: 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .not-front #block-block-3 .block-inner form > div:first-child {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

.lt-ie9 .not-front #block-block-3 .block-inner form > div:first-child {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}

.front #block-block-3 .block-inner form > div:first-child {
  width: 40%;
  max-width: 40%;
  flex: 0 0 40%;
}

@media (min-width: 768px) {
  .front #block-block-3 .block-inner form > div:first-child {
    width: 41%;
    max-width: 41%;
    flex: 0 0 41%;
  }
}

.lt-ie9 .front #block-block-3 .block-inner form > div:first-child {
  width: 41%;
  max-width: 41%;
  flex: 0 0 41%;
}

@media (min-width: 980px) {
  .front #block-block-3 .block-inner form > div:first-child {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.lt-ie9 .front #block-block-3 .block-inner form > div:first-child {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
}

@media (min-width: 980px) {
  .front.large-fonts #block-block-3 .block-inner form > div:first-child {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
  }
}

.lt-ie9 .front.large-fonts #block-block-3 .block-inner form > div:first-child {
  width: 40%;
  max-width: 40%;
  flex: 0 0 40%;
}

#block-block-3 .block-inner form .hs_email {
  flex: 1 0 auto;
}

#block-block-3 .block-inner form .hs_email input {
  border: none;
  line-height: 3em;
  height: 3em;
  padding-top: 0;
  padding-bottom: 0;
  width: 100% !important;
  background-color: #fff;
}

#block-block-3 .block-inner form .hs_email input::placeholder {
  font-style: italic;
}

#block-block-3 .block-inner form .hs_submit {
  margin: 0 0 0 0.75em !important;
  flex: 0 0 39px;
  width: 39px;
  max-width: 39px;
}

#block-block-3 .block-inner form table {
  margin: 0;
  box-shadow: none;
}

#block-block-3 .block-inner form table tbody {
  border-top-color: transparent;
}

#block-block-3 .block-inner form table td {
  border: none;
  padding: 0 !important;
  border: 1px solid transparent !important;
}

#block-block-3 .block-inner form table td:first-child {
  display: none;
}

#block-block-3 .block-inner .hs-richtext p {
  line-height: 1.3em;
  margin: 0;
  padding: 6px 10px 18px 0;
  text-align: left !important;
}

@media all and (max-width: 768px) {
  #block-block-3 .block-inner .hs-richtext p {
    padding: 12px 15px 12px 0;
  }
}

.front #block-block-3 .block-inner .hs-richtext p {
  line-height: 1.1em;
  padding: 6px 10px 6px 0;
}

#block-block-3 .block-inner .hs-richtext p span {
  font-weight: 300;
  color: #8cc63e !important;
  font-size: 18px;
}

.not-front #block-block-3 .block-inner .hs-richtext p span {
  font-size: 21px;
}

@media all and (max-width: 768px) {
  .not-front #block-block-3 .block-inner .hs-richtext p span {
    font-size: 18px;
  }
}

#block-menu-menu-help-menu .block__title {
  margin-bottom: 0;
  border-bottom: none;
}

@media (min-width: 768px) {
  #block-menu-menu-help-menu .block__title {
    border-bottom: 1px solid #d7d6db;
  }
}

.lt-ie9 #block-menu-menu-help-menu .block__title {
  border-bottom: 1px solid #d7d6db;
}

#block-menu-menu-help-menu .block-inner ul.menu {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
}

@media all and (min-width: 380px) {
  #block-menu-menu-help-menu .block-inner ul.menu {
    flex-wrap: nowrap;
  }
}

@media (min-width: 768px) {
  #block-menu-menu-help-menu .block-inner ul.menu {
    display: block;
  }
}

.lt-ie9 #block-menu-menu-help-menu .block-inner ul.menu {
  display: block;
}

#block-menu-menu-help-menu .block-inner ul.menu li {
  list-style: none;
  text-align: center;
  position: relative;
  flex: 1 50%;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  #block-menu-menu-help-menu .block-inner ul.menu li {
    justify-content: flex-start;
  }
}

.lt-ie9 #block-menu-menu-help-menu .block-inner ul.menu li {
  justify-content: flex-start;
}

#block-menu-menu-help-menu .block-inner ul.menu li img {
  width: 2.846em;
  height: 2.461em;
  display: block;
  margin: 0 auto 0.5em;
}

@media (min-width: 768px) {
  #block-menu-menu-help-menu .block-inner ul.menu li img {
    margin: 0 2em 0 0;
    display: inline;
  }
}

.lt-ie9 #block-menu-menu-help-menu .block-inner ul.menu li img {
  margin: 0 2em 0 0;
  display: inline;
}

@media all and (max-width: 319px) {
  #block-menu-menu-help-menu .block-inner ul.menu li:nth-child(1)::after, #block-menu-menu-help-menu .block-inner ul.menu li:nth-child(2)::after {
    content: " ";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(to right, #225cad 0%, #2799d7 100%);
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  #block-menu-menu-help-menu .block-inner ul.menu li {
    flex: 1 25%;
  }
  #block-menu-menu-help-menu .block-inner ul.menu li::after {
    content: " ";
    width: 1px;
    height: 100%;
    right: 0;
    left: auto;
    position: absolute;
    background: transparent url("../images/blue-spacer-v.jpg") no-repeat scroll 0 0;
  }
  #block-menu-menu-help-menu .block-inner ul.menu li.last:after {
    display: none;
  }
}

@media (min-width: 768px) {
  #block-menu-menu-help-menu .block-inner ul.menu li {
    text-align: left;
  }
  #block-menu-menu-help-menu .block-inner ul.menu li::after {
    content: " ";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: transparent url("../images/blue-spacer.jpg") no-repeat scroll 0 0;
  }
  #block-menu-menu-help-menu .block-inner ul.menu li.last:after {
    display: none;
  }
}

.lt-ie9 #block-menu-menu-help-menu .block-inner ul.menu li {
  text-align: left;
}

.lt-ie9 #block-menu-menu-help-menu .block-inner ul.menu li::after {
  content: " ";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: transparent url("../images/blue-spacer.jpg") no-repeat scroll 0 0;
}

.lt-ie9 #block-menu-menu-help-menu .block-inner ul.menu li.last:after {
  display: none;
}

#block-menu-menu-help-menu .block-inner ul.menu li:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

#block-menu-menu-help-menu .block-inner ul.menu li a {
  color: #1f4ea1;
  min-height: 3.8em;
  line-height: 1.25em;
  padding: 1.5em 0;
  font-size: 0.9em;
}

@media all and (min-width: 320px) {
  #block-menu-menu-help-menu .block-inner ul.menu li a {
    padding: 0.5em;
  }
}

@media all and (min-width: 380px) {
  #block-menu-menu-help-menu .block-inner ul.menu li a {
    padding: 1em;
    font-size: 1em;
  }
}

@media (min-width: 768px) {
  #block-menu-menu-help-menu .block-inner ul.menu li a {
    display: flex;
    align-items: center;
    padding: 0.9em 0 0.9em 0.65em;
    background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 4em center;
    background-size: 0.8461em 0.8461em;
  }
}

.lt-ie9 #block-menu-menu-help-menu .block-inner ul.menu li a {
  display: flex;
  align-items: center;
  padding: 0.9em 0 0.9em 0.65em;
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 4em center;
  background-size: 0.8461em 0.8461em;
}

#block-menu-menu-help-menu .block-inner ul.menu li a:hover {
  color: #333;
}

#block-views-latest-news-block-1 .block__content {
  padding-bottom: 1em;
}

.breadcrumb {
  padding-top: 0.8em;
  font-size: 1em;
  line-height: 1.6em;
  line-height: 1.5em;
  font-family: Helvetica, Arial, sans-serif;
}

.slide__content {
  position: relative;
  padding: 0 12px;
}

/* the slides */
.slick-slide {
  margin: 0;
}

/* the parent */
.slick-list {
  margin: 0 -12px;
}

#block-views-statistics-block .slide__content {
  padding: 0;
}

footer .block {
  margin: 0;
  padding-left: 1.6em;
  padding-right: 1.6em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.region-footer .block-menu .menu a {
  padding: 0.8em;
}

.region-footer .block-menu .menu li:first-child a {
  padding-left: 0;
}

#block-views-footer-teaser-block {
  background: #F3F3F3;
}

#block-views-footer-teaser-block p {
  margin: 0;
}

#block-views-footer-teaser-block .view-footer-teaser {
  *zoom: 1;
}

#block-views-footer-teaser-block .view-footer-teaser:after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 768px) {
  #block-views-footer-teaser-block .view-footer-teaser:before, #block-views-footer-teaser-block .view-footer-teaser:after {
    content: "";
    display: table;
  }
  #block-views-footer-teaser-block .view-footer-teaser:after {
    clear: both;
  }
  #block-views-footer-teaser-block .view-footer-teaser .views-row-1 {
    clear: left;
    float: left;
    width: 10%;
    margin-left: 0%;
    margin-right: -10%;
    padding-left: 0px;
  }
  #block-views-footer-teaser-block .view-footer-teaser .views-row-2 {
    float: left;
    width: 10%;
    margin-left: 10%;
    margin-right: -20%;
  }
  #block-views-footer-teaser-block .view-footer-teaser .views-row-3 {
    float: left;
    width: 10%;
    margin-left: 20%;
    margin-right: -30%;
    padding-right: 0px;
  }
}

.lt-ie9 #block-views-footer-teaser-block .view-footer-teaser:before, .lt-ie9 #block-views-footer-teaser-block .view-footer-teaser:after {
  content: "";
  display: table;
}

.lt-ie9 #block-views-footer-teaser-block .view-footer-teaser:after {
  clear: both;
}

.lt-ie9 #block-views-footer-teaser-block .view-footer-teaser .views-row-1 {
  clear: left;
  float: left;
  width: 10%;
  margin-left: 0%;
  margin-right: -10%;
  padding-left: 0px;
}

.lt-ie9 #block-views-footer-teaser-block .view-footer-teaser .views-row-2 {
  float: left;
  width: 10%;
  margin-left: 10%;
  margin-right: -20%;
}

.lt-ie9 #block-views-footer-teaser-block .view-footer-teaser .views-row-3 {
  float: left;
  width: 10%;
  margin-left: 20%;
  margin-right: -30%;
  padding-right: 0px;
}

#block-views-footer-teaser-block .view-footer-teaser .views-row {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  min-height: 90px;
}

#block-views-footer-teaser-block .view-footer-teaser .views-row .views-field-field-image {
  position: absolute;
  top: 20px;
  right: 10px;
}

#block-views-footer-teaser-block .view-footer-teaser .views-field-field-description {
  padding-right: 85px;
}

#block-views-footer-teaser-block .view-footer-teaser .views-field-title {
  padding-top: 10px;
  padding-right: 85px;
  border-top: solid 3px #CCCCCC;
}

#block-views-footer-teaser-block .view-footer-teaser .views-field-title h2 {
  font-size: 1.23077em;
  line-height: 1.3em;
  line-height: 1.6em;
}

#block-views-footer-teaser-block .view-footer-teaser .views-field-title a {
  text-decoration: none;
}

#block-views-footer-teaser-block .view-footer-teaser .views-field-title a:hover {
  text-decoration: underline;
}

#footer-menu {
  background-color: #f05a31;
}

#footer-menu #block-block-7 {
  margin: 0 2em;
  text-align: center;
}

#footer-menu #block-block-7 p {
  color: #fff;
  padding: 1.5em 0;
  margin: 0;
}

@media (min-width: 768px) {
  #footer-menu #block-block-7 {
    margin-left: 15%;
    margin-right: 15%;
  }
}

.lt-ie9 #footer-menu #block-block-7 {
  margin-left: 15%;
  margin-right: 15%;
}

@media (min-width: 1175px) {
  #footer-menu #block-block-7 {
    margin-left: 23em;
    margin-right: 23em;
  }
}

.lt-ie9 #footer-menu #block-block-7 {
  margin-left: 23em;
  margin-right: 23em;
}

#footer-top {
  background-color: #5c626c;
  color: #FFF;
  padding-top: 1.5em;
}

#footer-top .block {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .layout-desktop #footer-top {
    border-top: 1px solid #fff;
  }
}

.layout-desktop .lt-ie9 #footer-top {
  border-top: 1px solid #fff;
}

#footer-top li.menu-mlid-2664,
#footer-top li.menu-mlid-2663 {
  display: none;
}

#footer-top .menu-block-wrapper > ul.menu > li.menu__item > a {
  color: #8cc63e;
  display: block;
  margin-bottom: 5px;
  line-height: 1.3em;
  font-size: 1.0769em;
}

@media all and (min-width: 320px) {
  #footer-top .menu-block-wrapper > ul.menu > li.menu__item > a {
    font-size: 1em;
  }
}

@media all and (min-width: 390px) {
  #footer-top .menu-block-wrapper > ul.menu > li.menu__item > a {
    padding-right: 1em;
    font-size: 1.0769em;
  }
}

#footer-top .menu-block-wrapper > ul.menu > li.menu__item > a:hover, #footer-top .menu-block-wrapper > ul.menu > li.menu__item > a.active {
  color: #97d740;
}

#footer-top .menu-block-wrapper > ul.menu > li.menu__item > ul {
  display: none;
}

@media (min-width: 980px) {
  #footer-top .menu-block-wrapper > ul.menu > li.menu__item > ul {
    display: block;
  }
}

.lt-ie9 #footer-top .menu-block-wrapper > ul.menu > li.menu__item > ul {
  display: block;
}

#footer-top a {
  text-decoration: none;
  font-weight: 500;
}

#footer-top .menu-block-wrapper > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 1em;
}

@media all and (min-width: 320px) {
  #footer-top .menu-block-wrapper > ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 5.9em;
  }
}

@media (min-width: 768px) {
  #footer-top .menu-block-wrapper > ul {
    flex-direction: row;
    height: auto;
  }
}

.lt-ie9 #footer-top .menu-block-wrapper > ul {
  flex-direction: row;
  height: auto;
}

#footer-top .menu-block-wrapper > ul > li {
  width: 50%;
  position: relative;
  font-weight: bold;
}

@media all and (min-width: 320px) {
  #footer-top .menu-block-wrapper > ul > li {
    flex: 1 33.3%;
  }
}

@media (min-width: 768px) {
  #footer-top .menu-block-wrapper > ul > li {
    flex: 1 50%;
  }
  #footer-top .menu-block-wrapper > ul > li:nth-child(even) {
    padding-left: 12px;
  }
}

.lt-ie9 #footer-top .menu-block-wrapper > ul > li {
  flex: 1 50%;
}

.lt-ie9 #footer-top .menu-block-wrapper > ul > li:nth-child(even) {
  padding-left: 12px;
}

@media (min-width: 980px) {
  #footer-top .menu-block-wrapper > ul > li {
    flex: 0 16.66%;
  }
  #footer-top .menu-block-wrapper > ul > li:nth-child(even) {
    padding-left: 0;
  }
}

.lt-ie9 #footer-top .menu-block-wrapper > ul > li {
  flex: 0 16.66%;
}

.lt-ie9 #footer-top .menu-block-wrapper > ul > li:nth-child(even) {
  padding-left: 0;
}

#footer-top .menu-block-wrapper > ul > li.is-expanded ul li {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {
  #footer-top .menu-block-wrapper > ul > li.is-expanded ul li {
    padding-right: 1.5em;
  }
}

.lt-ie9 #footer-top .menu-block-wrapper > ul > li.is-expanded ul li {
  padding-right: 1.5em;
}

#footer-top .menu-block-wrapper > ul > li.is-expanded ul li a {
  color: #eee;
  display: block;
  font-weight: 400;
  font-size: 0.923em;
  line-height: 1.3em;
  padding: 0.15em 0;
}

#footer-top .menu-block-wrapper > ul > li.is-expanded ul li a:hover, #footer-top .menu-block-wrapper > ul > li.is-expanded ul li a.active {
  color: #fff;
}

#footer-message {
  color: #5c626c;
}

#block-menu-menu-footer-sub-menu {
  margin-bottom: 0;
}

.region-footer-second {
  background: transparent url("../images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: auto 164px;
  padding-bottom: 1.5em;
  display: flex;
  flex-wrap: wrap;
}

@media all and (min-width: 320px) {
  .region-footer-second {
    flex-direction: row;
    padding-bottom: 1em;
  }
}

@media all and (max-width: 599px) {
  .region-footer-second {
    background-position: right -208px bottom 0;
  }
}

@media all and (min-width: 600px) {
  .region-footer-second {
    background-size: auto 61%;
    background-position: right bottom;
  }
  .large-fonts .region-footer-second {
    background-size: auto 67%;
  }
}

@media (min-width: 980px) {
  .region-footer-second {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.lt-ie9 .region-footer-second {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

@media (min-width: 980px) {
  .region-footer-second {
    background-size: auto 92%;
  }
  .large-fonts .region-footer-second {
    background-size: auto 87%;
  }
}

.lt-ie9 .region-footer-second {
  background-size: auto 92%;
}

.large-fonts .lt-ie9 .region-footer-second {
  background-size: auto 87%;
}

.region-footer-third {
  text-align: center;
}

#footer-bottom {
  padding-top: 10px;
  padding-bottom: 20px;
  position: relative;
  background-color: #efeff0;
}

@media (min-width: 768px) {
  #footer-bottom {
    padding-top: 15px;
    padding-bottom: 20px;
  }
}

.lt-ie9 #footer-bottom {
  padding-top: 15px;
  padding-bottom: 20px;
}

#footer-bottom .block-inner a:hover,
#footer-bottom #footer-message a:hover {
  color: #333;
  text-decoration: none;
}

#block-menu-menu-footer-contact {
  order: 1;
  flex: 1 100%;
}

@media all and (min-width: 320px) and (max-width: 767px) {
  #block-menu-menu-footer-contact {
    flex: 1 50%;
    margin-bottom: 1em;
  }
}

@media all and (min-width: 768px) and (max-width: 979px) {
  #block-menu-menu-footer-contact {
    flex: 0 50%;
  }
}

@media (min-width: 980px) {
  #block-menu-menu-footer-contact {
    padding-top: 1em;
    order: 4;
    flex: 1;
  }
  .large-fonts #block-menu-menu-footer-contact {
    padding-top: 0;
  }
}

.lt-ie9 #block-menu-menu-footer-contact {
  padding-top: 1em;
  order: 4;
  flex: 1;
}

.large-fonts .lt-ie9 #block-menu-menu-footer-contact {
  padding-top: 0;
}

#block-menu-menu-footer-contact ul.menu {
  margin: 0;
}

#block-menu-menu-footer-contact ul.menu li {
  list-style: none;
}

#block-menu-menu-footer-contact ul.menu li a {
  color: #8cc63e;
  display: block;
  margin-bottom: 5px;
  line-height: 1.3em;
  outline: none;
}

@media all and (min-width: 390px) {
  #block-menu-menu-footer-contact ul.menu li a {
    font-size: 1.07692em;
    line-height: 1.48571em;
  }
}

#block-menu-menu-footer-contact ul.menu li a:hover, #block-menu-menu-footer-contact ul.menu li a.active {
  color: #97d740;
}

#block-qmhc-footer-logo {
  padding-bottom: 1em;
  order: 3;
}

@media (min-width: 980px) {
  #block-qmhc-footer-logo {
    order: 1;
  }
}

.lt-ie9 #block-qmhc-footer-logo {
  order: 1;
}

#block-qmhc-footer-logo img {
  width: 80px;
  height: 100px;
}

@media all and (min-width: 320px) {
  #block-qmhc-footer-logo img {
    width: 90px;
    height: 113px;
  }
}

@media (min-width: 980px) {
  #block-qmhc-footer-logo img {
    width: 80px;
    height: 100px;
  }
}

.lt-ie9 #block-qmhc-footer-logo img {
  width: 80px;
  height: 100px;
}

#block-menu-menu-footer-sub-menu ul li {
  display: inline;
  padding: 0 10px;
  border-right: 1px solid #5c626c;
}

#block-menu-menu-footer-sub-menu ul li:last-child {
  border-right: none;
}

#block-qmhc-footer-address {
  order: 4;
  font-size: 0.923em;
  line-height: 2em;
  color: #eee;
  flex: 1 20%;
  display: flex;
  align-items: center;
}

#block-qmhc-footer-address a {
  color: #fff;
  text-decoration: underline;
}

#block-qmhc-footer-address a:hover {
  text-decoration: none;
}

#block-qmhc-footer-address .block-inner {
  padding: 0 0 0 2em;
}

@media (min-width: 600px) {
  #block-qmhc-footer-address {
    flex: 1 80%;
  }
  #block-qmhc-footer-address .block-inner {
    padding: 1.5em 2em 2em;
  }
}

@media (min-width: 768px) {
  #block-qmhc-footer-address {
    flex: 1 85%;
    order: 3;
  }
}

.lt-ie9 #block-qmhc-footer-address {
  flex: 1 85%;
  order: 3;
}

@media (min-width: 980px) {
  #block-qmhc-footer-address {
    flex: 0 41.5%;
    order: 2;
  }
  #block-qmhc-footer-address .block-inner {
    padding: 0 0 0 2em;
  }
  .large-fonts #block-qmhc-footer-address .block-inner {
    padding: 0 2em;
  }
}

.lt-ie9 #block-qmhc-footer-address {
  flex: 0 41.5%;
  order: 2;
}

.lt-ie9 #block-qmhc-footer-address .block-inner {
  padding: 0 0 0 2em;
}

.large-fonts .lt-ie9 #block-qmhc-footer-address .block-inner {
  padding: 0 2em;
}

@media (min-width: 1175px) {
  #block-qmhc-footer-address {
    flex: 0 43%;
  }
}

.lt-ie9 #block-qmhc-footer-address {
  flex: 0 43%;
}

#block-qmhc-footer-address .row {
  display: block;
  line-height: 1.5em;
}

#block-qmhc-footer-address .row:nth-of-type(1) {
  margin-bottom: 5px;
}

#block-qmhc-footer-address .row:nth-of-type(4) {
  margin-top: 1em;
  font-size: 0.923em;
}

#block-qmhc-footer-address p {
  margin-bottom: 0;
}

#block-menu-menu-privacy-disclaimer {
  font-size: 0.8em;
}

@media (min-width: 600px) {
  #block-menu-menu-privacy-disclaimer {
    float: right;
    width: 25%;
  }
}

@media (min-width: 768px) {
  #block-menu-menu-privacy-disclaimer {
    margin-top: 0;
  }
}

.lt-ie9 #block-menu-menu-privacy-disclaimer {
  margin-top: 0;
}

#block-menu-menu-privacy-disclaimer ul {
  margin: 0;
}

#block-menu-menu-privacy-disclaimer ul a {
  color: #404040;
}

#block-menu-menu-privacy-disclaimer ul a:hover {
  text-decoration: none;
  opacity: 0.8;
}

@media (min-width: 980px) {
  #block-menu-menu-privacy-disclaimer {
    float: left;
    width: 150px;
    margin-left: 12px;
    clear: none;
  }
}

.lt-ie9 #block-menu-menu-privacy-disclaimer {
  float: left;
  width: 150px;
  margin-left: 12px;
  clear: none;
}

#block-qmhc-footer-social-links {
  padding-bottom: 1em;
  order: 2;
  flex: 1 0 50%;
}

@media all and (min-width: 320px) {
  #block-qmhc-footer-social-links {
    padding-bottom: 1.5em;
    font-size: 1em;
  }
}

@media (min-width: 768px) {
  #block-qmhc-footer-social-links {
    padding-left: 12px;
  }
}

.lt-ie9 #block-qmhc-footer-social-links {
  padding-left: 12px;
}

@media (min-width: 980px) {
  #block-qmhc-footer-social-links {
    padding-top: 1em;
    padding-left: 0;
    order: 3;
    flex: 0 17%;
  }
  .large-fonts #block-qmhc-footer-social-links {
    padding-top: 0;
  }
}

.lt-ie9 #block-qmhc-footer-social-links {
  padding-top: 1em;
  padding-left: 0;
  order: 3;
  flex: 0 17%;
}

.large-fonts .lt-ie9 #block-qmhc-footer-social-links {
  padding-top: 0;
}

#block-qmhc-footer-social-links span.social-link {
  width: 2.2em;
  float: left;
  margin-right: 1em;
}

@media (min-width: 768px) {
  #block-qmhc-footer-social-links span.social-link {
    width: 2.666em;
  }
  .large-fonts #block-qmhc-footer-social-links span.social-link {
    margin-right: 0.8em;
  }
}

.lt-ie9 #block-qmhc-footer-social-links span.social-link {
  width: 2.666em;
}

.large-fonts .lt-ie9 #block-qmhc-footer-social-links span.social-link {
  margin-right: 0.8em;
}

@media (min-width: 980px) {
  #block-qmhc-footer-social-links span.social-link {
    margin-right: 0.4em;
    width: 2.5em;
  }
  .large-fonts #block-qmhc-footer-social-links span.social-link {
    width: 2.2em;
  }
}

.lt-ie9 #block-qmhc-footer-social-links span.social-link {
  margin-right: 0.4em;
  width: 2.5em;
}

.large-fonts .lt-ie9 #block-qmhc-footer-social-links span.social-link {
  width: 2.2em;
}

@media (min-width: 1175px) {
  #block-qmhc-footer-social-links span.social-link {
    margin-right: 1em;
  }
}

.lt-ie9 #block-qmhc-footer-social-links span.social-link {
  margin-right: 1em;
}

#block-qmhc-footer-social-links span.social-link:last-child {
  margin-right: 0;
}

#block-qmhc-footer-social-links p {
  color: #8cc63e;
  font-weight: 500;
  margin: 0 0 7px;
}

@media all and (min-width: 390px) {
  #block-qmhc-footer-social-links p {
    font-size: 1.07692em;
    line-height: 1.48571em;
  }
}

@media (min-width: 768px) {
  #block-qmhc-footer-social-links p {
    font-size: 1.0769em;
  }
}

.lt-ie9 #block-qmhc-footer-social-links p {
  font-size: 1.0769em;
}

#block-qmhc-footer-social-links img {
  display: inline;
  vertical-align: middle;
  transition: all .2s ease-in-out;
}

#block-qmhc-footer-social-links img:hover {
  transform: scale(1.1);
}

#header {
  position: relative;
  padding-bottom: 1em;
}

.header__inner div.contextual-links-wrapper {
  display: none;
}

@media (min-width: 768px) {
  .header__inner div.contextual-links-wrapper {
    right: -30px;
    display: block;
  }
}

.lt-ie9 .header__inner div.contextual-links-wrapper {
  right: -30px;
  display: block;
}

.header__inner .header__region {
  order: 2;
}

@media (min-width: 768px) {
  .header__inner .header__region {
    padding: 0 12px;
    height: 43px;
  }
}

.lt-ie9 .header__inner .header__region {
  padding: 0 12px;
  height: 43px;
}

@media (min-width: 768px) {
  .header__inner .header__region {
    order: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.lt-ie9 .header__inner .header__region {
  order: 1;
  display: flex;
  justify-content: flex-end;
}

.header__inner .header__region .form-item {
  margin: 0;
}

.header__inner .header__left {
  text-align: center;
}

@media (min-width: 768px) {
  .header__inner .header__left {
    text-align: left;
    order: 2;
    display: flex;
  }
  .large-fonts .header__inner .header__left {
    margin-top: 2em;
  }
}

.lt-ie9 .header__inner .header__left {
  text-align: left;
  order: 2;
  display: flex;
}

.large-fonts .lt-ie9 .header__inner .header__left {
  margin-top: 2em;
}

.header__logo img {
  width: 8em;
  margin: 2em auto 1em;
}

@media all and (min-width: 360px) {
  .header__logo img {
    width: 12em;
  }
}

@media (min-width: 768px) {
  .header__logo img {
    width: 80px;
    height: 80px;
    margin: 0 1.75em 0 0;
  }
}

.lt-ie9 .header__logo img {
  width: 80px;
  height: 80px;
  margin: 0 1.75em 0 0;
}

@media (min-width: 980px) {
  .header__logo img {
    width: 90px;
    height: 90px;
    margin: 0 2em 0 0;
  }
}

.lt-ie9 .header__logo img {
  width: 90px;
  height: 90px;
  margin: 0 2em 0 0;
}

.header__name-and-slogan {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #5c626c;
}

.header__site-name {
  line-height: 1.3em;
  font-size: 1.923em;
  margin: 0;
  display: none;
}

@media (min-width: 768px) {
  .header__site-name {
    display: block;
  }
}

.lt-ie9 .header__site-name {
  display: block;
}

.header__site-slogan {
  margin: 0 auto;
  width: 80%;
  line-height: 1.5em;
}

@media (min-width: 360px) {
  .header__site-slogan {
    font-size: 1.1538em;
    width: 60%;
  }
}

.lt-ie9 .header__site-slogan {
  font-size: 1.1538em;
  width: 60%;
}

@media all and (min-width: 580px) {
  .header__site-slogan {
    width: 70%;
  }
}

@media (min-width: 768px) {
  .header__site-slogan {
    width: auto;
    margin: 0;
  }
}

.lt-ie9 .header__site-slogan {
  width: auto;
  margin: 0;
}

.header__secondary-menu {
  margin: 0.8em 0;
  max-width: 50%;
}

@media (min-width: 768px) {
  .header__inner .block-search-api-page {
    margin-top: 1em;
    width: 220px;
    margin-bottom: 0;
  }
  .large-fonts .header__inner .block-search-api-page {
    width: 240px;
  }
}

.lt-ie9 .header__inner .block-search-api-page {
  margin-top: 1em;
  width: 220px;
  margin-bottom: 0;
}

.large-fonts .lt-ie9 .header__inner .block-search-api-page {
  width: 240px;
}

.header__inner .block-agov-text-resize {
  margin: 1.153em 0 0 9px;
  display: none;
}

.large-fonts .header__inner .block-agov-text-resize {
  margin-top: 1.4em;
}

@media (min-width: 768px) {
  .header__inner .block-agov-text-resize {
    display: inline-block;
  }
}

.lt-ie9 .header__inner .block-agov-text-resize {
  display: inline-block;
}

#quick-exit {
  background-color: #EFEFEC;
  font-size: 0.8461em;
  color: #333;
  border: none;
  padding: 0.2em 0.8em;
}

#quick-exit a {
  color: #000;
}

#quick-exit a:hover {
  opacity: 0.9;
  text-decoration: none;
}

div.header-menu {
  font-size: 0.923em;
  margin-bottom: 0;
  flex: 1;
}

@media all and (min-width: 350px) {
  div.header-menu {
    text-align: right;
  }
}

div.header-menu .block__content {
  background-color: #204ea3;
}

.region-footer-menu div.header-menu .block__content {
  border-top: 1px solid #8fa2cd;
}

@media (min-width: 768px) {
  .header__inner div.header-menu .block__content {
    background-color: transparent;
    padding: 0;
  }
}

.lt-ie9 .header__inner div.header-menu .block__content {
  background-color: transparent;
  padding: 0;
}

@media (min-width: 768px) {
  .header__inner div.header-menu {
    margin: 1.5em 0.833em 0;
  }
  .large-fonts .header__inner div.header-menu {
    flex: 1 50%;
  }
}

.lt-ie9 .header__inner div.header-menu {
  margin: 1.5em 0.833em 0;
}

.large-fonts .lt-ie9 .header__inner div.header-menu {
  flex: 1 50%;
}

@media (min-width: 980px) {
  .large-fonts .header__inner div.header-menu {
    flex: 1 61%;
  }
}

.large-fonts .lt-ie9 .header__inner div.header-menu {
  flex: 1 61%;
}

div.header-menu ul.menu {
  margin: 0;
}

div.header-menu li {
  display: inline-block;
  word-wrap: normal;
}

div.header-menu li.last a {
  padding-right: 0;
}

@media (min-width: 768px) {
  div.header-menu li.last a {
    padding-right: 0.8em;
  }
}

.lt-ie9 div.header-menu li.last a {
  padding-right: 0.8em;
}

div.header-menu li.first a {
  padding-left: 0;
}

div.header-menu li > a {
  color: #eee;
  padding: 0 0.8em;
  outline: none;
  border-right: 1px solid #2492d1;
}

div.header-menu li > a:hover, div.header-menu li > a.active {
  text-decoration: none;
  color: #fff;
}

@media (min-width: 768px) {
  .header__inner div.header-menu li > a {
    color: #1f4ea1;
    border-right-color: #1f4ea1;
  }
  .header__inner div.header-menu li > a:hover, .header__inner div.header-menu li > a.active {
    text-decoration: none;
    color: #333;
  }
}

.lt-ie9 .header__inner div.header-menu li > a {
  color: #1f4ea1;
  border-right-color: #1f4ea1;
}

.lt-ie9 .header__inner div.header-menu li > a:hover, .lt-ie9 .header__inner div.header-menu li > a.active {
  text-decoration: none;
  color: #333;
}

div.header-menu li.last a {
  border-right: none;
}

.header .search-form-widget {
  height: 0px;
  overflow: hidden;
  position: relative;
  clear: both;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.header .search-form-widget > div {
  margin-top: 12px;
  overflow: hidden;
}

.header .search-form-widget .form-item {
  margin: 0;
}

@media (min-width: 768px) {
  .header .search-form-widget {
    height: 65px;
    margin: 0;
    float: right;
    max-width: 100%;
  }
}

.lt-ie9 .header .search-form-widget {
  height: 65px;
  margin: 0;
  float: right;
  max-width: 100%;
}

#block-system-user-menu ul.menu {
  margin-top: 12px;
}

#block-search-api-page-site form {
  margin: 0 auto;
  position: relative;
  max-width: 400px;
}

#header .block-search-api-page .form-text {
  padding: 0 2.6em 0 0.8em;
  height: 3em;
  line-height: 3em;
  width: 100%;
  z-index: 1;
  background-color: #fff;
}

@media (min-width: 768px) {
  #header .block-search-api-page .form-text {
    border: 1px solid #ddd;
  }
}

.lt-ie9 #header .block-search-api-page .form-text {
  border: 1px solid #ddd;
}

#header .block-search-api-page .form-submit {
  background-color: transparent;
}

#header .block-search-api-page .form-submit:hover {
  opacity: 0.7;
}

#header .block-search-api-page .form-submit:hover {
  cursor: pointer;
}

.block-search-api-page form {
  position: relative;
}

.region-header .block-search-api-page .mag-glass {
  position: absolute;
  top: 3.5em;
  right: 15px;
  background-color: #efefef;
}

.region-header .block-search-api-page .mag-glass.mag-close {
  background-size: 16px 16px;
  background-color: #8CC53F;
  background-image: url(../images/hamburger-close.svg);
}

@media (min-width: 768px) {
  .region-header .block-search-api-page .mag-glass {
    position: static;
  }
}

.lt-ie9 .region-header .block-search-api-page .mag-glass {
  position: static;
}

.region-header .block-search-api-page form {
  position: absolute;
  top: 6.4em;
  left: 15px;
  right: 15px;
  z-index: 100;
  height: 0;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.region-header .block-search-api-page form > div {
  position: relative;
  padding: 3px 5px;
  font-size: 1.3em;
  background-color: #8CC53F;
}

@media (min-width: 768px) {
  .region-header .block-search-api-page form > div {
    background-color: transparent;
    padding: 0;
    font-size: 1em;
  }
}

.lt-ie9 .region-header .block-search-api-page form > div {
  background-color: transparent;
  padding: 0;
  font-size: 1em;
}

@media (min-width: 768px) {
  .region-header .block-search-api-page form {
    padding: 0;
    position: static;
    height: 2.6em !important;
    display: block;
    background-color: #fff;
  }
}

.lt-ie9 .region-header .block-search-api-page form {
  padding: 0;
  position: static;
  height: 2.6em !important;
  display: block;
  background-color: #fff;
}

.region-header .block-search-api-page form a {
  font-size: 10px;
  position: absolute;
  left: 190px;
  text-decoration: none;
}

.region-header .block-search-api-page form .form-submit {
  top: 13px;
  right: 10px;
  background-size: 16px 16px;
}

@media (min-width: 768px) {
  .region-header .block-search-api-page form .form-submit {
    top: 4px;
    right: 3px;
    background-size: 12px 12px;
  }
}

.lt-ie9 .region-header .block-search-api-page form .form-submit {
  top: 4px;
  right: 3px;
  background-size: 12px 12px;
}

.region-header .block-search-api-page form label {
  display: none;
}

.region-header .block-search-api-page form input.form-search {
  width: 100%;
  padding: 15px 43px 15px 15px;
  background-color: #E9EDEE;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #E9EDEE;
}

@media (min-width: 768px) {
  .region-header .block-search-api-page form input.form-search {
    padding: 10px 43px 10px 15px;
    height: 40px;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 213px;
  }
}

.lt-ie9 .region-header .block-search-api-page form input.form-search {
  padding: 10px 43px 10px 15px;
  height: 40px;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 213px;
}

@media (min-width: 768px) {
  .region-header .block-search-api-page form input.form-search {
    width: 250px;
  }
}

.lt-ie9 .region-header .block-search-api-page form input.form-search {
  width: 250px;
}

.mag-glass {
  height: 38px;
  width: 38px;
  position: relative;
  background: transparent url("../images/search.svg") no-repeat scroll center center;
  background-size: 28px 28px;
}

.mag-glass:hover {
  cursor: pointer;
}

@media all and (min-width: 768px) {
  .mag-glass {
    display: none;
  }
}

#block-search-api-page-default-search {
  position: static;
  margin: 1em 0 0;
}

#block-views-slideshow-block {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  #block-views-slideshow-block {
    margin-bottom: 1.5em;
  }
}

.lt-ie9 #block-views-slideshow-block {
  margin-bottom: 1.5em;
}

.view-front-slideshow {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 300px;
}

@media (min-width: 481px) {
  .view-front-slideshow {
    padding-bottom: 300px;
  }
}

.lt-ie9 .view-front-slideshow {
  padding-bottom: 300px;
}

@media (min-width: 980px) {
  .view-front-slideshow {
    padding-bottom: 270px;
  }
}

.lt-ie9 .view-front-slideshow {
  padding-bottom: 270px;
}

@media (min-width: 1500px) {
  .view-front-slideshow {
    padding-bottom: 400px;
  }
}

.lt-ie9 .view-front-slideshow {
  padding-bottom: 400px;
}

.view-front-slideshow .slick {
  min-height: 300px;
}

@media (min-width: 481px) {
  .view-front-slideshow .slick {
    min-height: 300px;
  }
}

.lt-ie9 .view-front-slideshow .slick {
  min-height: 300px;
}

@media (min-width: 1175px) {
  .view-front-slideshow .slick {
    min-height: 270px;
  }
}

.lt-ie9 .view-front-slideshow .slick {
  min-height: 270px;
}

@media (min-width: 1500px) {
  .view-front-slideshow .slick {
    min-height: 400px;
  }
}

.lt-ie9 .view-front-slideshow .slick {
  min-height: 400px;
}

.view-front-slideshow .slick-track {
  overflow: hidden;
  height: 300px;
}

@media (min-width: 481px) {
  .view-front-slideshow .slick-track {
    height: 300px;
  }
}

.lt-ie9 .view-front-slideshow .slick-track {
  height: 300px;
}

@media (min-width: 980px) {
  .view-front-slideshow .slick-track {
    height: 270px;
  }
}

.lt-ie9 .view-front-slideshow .slick-track {
  height: 270px;
}

@media (min-width: 1500px) {
  .view-front-slideshow .slick-track {
    height: 400px;
  }
}

.lt-ie9 .view-front-slideshow .slick-track {
  height: 400px;
}

.view-front-slideshow .button {
  border: 1px solid #24ace0;
  color: #fff;
  text-align: center;
  display: inline-block;
  margin-top: 2em;
  font-weight: 600;
  padding: 0.3846em 1em;
  background-position: 0.75em center;
  line-height: 2em;
  -webkit-appearance: none;
  padding-left: 2em;
  border-color: #fff;
}

.view-front-slideshow .button:hover, .view-front-slideshow .button:active {
  background-color: #fff;
  color: #2164b2;
  opacity: 0.6;
  text-decoration: none !important;
}

@media (min-width: 768px) {
  .view-front-slideshow .field-image {
    overflow: hidden;
  }
}

.lt-ie9 .view-front-slideshow .field-image {
  overflow: hidden;
}

.view-front-slideshow ul.slides {
  overflow: hidden;
}

.view-front-slideshow .slide__content {
  padding: 0;
}

.view-front-slideshow .slide__content > a {
  display: block;
  height: 300px;
}

.view-front-slideshow .slide__content > a img {
  height: 100% !important;
  width: auto;
  float: right;
}

@media (min-width: 481px) {
  .view-front-slideshow .slide__content > a {
    height: 300px;
  }
}

.lt-ie9 .view-front-slideshow .slide__content > a {
  height: 300px;
}

@media (min-width: 980px) {
  .view-front-slideshow .slide__content > a {
    height: 270px;
  }
}

.lt-ie9 .view-front-slideshow .slide__content > a {
  height: 270px;
}

@media (min-width: 1500px) {
  .view-front-slideshow .slide__content > a {
    height: 400px;
  }
}

.lt-ie9 .view-front-slideshow .slide__content > a {
  height: 400px;
}

.view-front-slideshow .slide__content > a.bg-red {
  background: linear-gradient(to right, #C72030, #F58220);
}

.view-front-slideshow .slide__content > a.bg-green {
  background: linear-gradient(to right, #4EB857, #8DC63F);
}

.view-front-slideshow .slide__content > a.bg-blue {
  background: linear-gradient(to right, #1F4EA2, #27AAE1);
}

.view-front-slideshow .slide__content > a.bg-orange {
  background: linear-gradient(to right, #F15A31, #FFCB05);
}

.view-front-slideshow .slideshow-pager {
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 1;
  padding-right: 12px;
}

.view-front-slideshow .slick-dots {
  display: none;
  float: right;
  padding-right: 1em;
}

.view-front-slideshow .slick-dots li {
  float: left;
  margin-left: 0.5em;
  list-style: none;
}

.view-front-slideshow .slick-dots li button {
  font-size: 1em;
  outline: none;
  overflow: hidden;
  text-indent: -9999px;
  background-color: transparent;
  border: 2px solid #fff;
  height: 1.2307em;
  width: 1.2307em;
  -webkit-border-radius: 0.6153em;
  -moz-border-radius: 0.6153em;
  -ms-border-radius: 0.6153em;
  -o-border-radius: 0.6153em;
  border-radius: 0.6153em;
  opacity: 0.7;
}

.view-front-slideshow .slick-dots li button:hover {
  background-color: #fff;
  opacity: 0.5;
}

.view-front-slideshow .slick-dots li.slick-active button {
  background-color: #fff;
  opacity: 0.7;
}

.view-front-slideshow .front-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.view-front-slideshow .field-title {
  line-height: 1.1em;
  color: #fff;
  margin: 0 0 13px;
  font-size: 2em;
}

@media (min-width: 980px) {
  .view-front-slideshow .field-title {
    font-size: 2.5em;
  }
}

.lt-ie9 .view-front-slideshow .field-title {
  font-size: 2.5em;
}

@media (min-width: 1500px) {
  .view-front-slideshow .field-title {
    font-size: 4.2307em;
  }
}

.lt-ie9 .view-front-slideshow .field-title {
  font-size: 4.2307em;
}

.view-front-slideshow .field-subtitle {
  line-height: 1.428em;
  color: #fff;
}

@media all and (min-width: 380px) {
  .view-front-slideshow .field-subtitle {
    font-size: 1.2307em;
  }
}

.view-front-slideshow .field-subtitle span {
  display: block;
  height: 79px;
  overflow: hidden;
}

.large-fonts .view-front-slideshow .field-subtitle span {
  height: 88px;
}

@media (min-width: 768px) {
  .view-front-slideshow .more-link {
    float: left;
  }
}

.lt-ie9 .view-front-slideshow .more-link {
  float: left;
}

#block-views-explore-jump-menu-block .block-inner {
  background: #efefef;
  padding: 15px 12px;
}

.front #block-views-explore-jump-menu-block .block-inner {
  display: flex;
}

#block-views-explore-jump-menu-block .block-inner .chosen-single {
  background-color: #fff;
  width: 100%;
}

#block-views-explore-jump-menu-block .block-inner .views-exposed-form .views-exposed-widget {
  padding: 0;
  float: none;
}

#block-views-explore-jump-menu-block .block-inner h2.block__title {
  border-bottom: none;
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 3.75em center;
  background-size: 10px 9px;
  font-weight: 400;
  font-size: 1em;
  line-height: 2.3em;
  margin: 0;
}

@media (min-width: 360px) {
  #block-views-explore-jump-menu-block .block-inner h2.block__title {
    font-size: 1.3846em;
    line-height: 1.5em;
  }
}

.lt-ie9 #block-views-explore-jump-menu-block .block-inner h2.block__title {
  font-size: 1.3846em;
  line-height: 1.5em;
}

.front #block-views-explore-jump-menu-block .block-inner h2.block__title {
  flex: 0 40%;
}

@media (min-width: 768px) {
  .front #block-views-explore-jump-menu-block .block-inner h2.block__title {
    flex: 0 41%;
  }
}

.lt-ie9 .front #block-views-explore-jump-menu-block .block-inner h2.block__title {
  flex: 0 41%;
}

@media (min-width: 980px) {
  .front #block-views-explore-jump-menu-block .block-inner h2.block__title {
    flex: 0 50%;
  }
}

.lt-ie9 .front #block-views-explore-jump-menu-block .block-inner h2.block__title {
  flex: 0 50%;
}

@media (min-width: 980px) {
  .front.large-fonts #block-views-explore-jump-menu-block .block-inner h2.block__title {
    flex: 0 40%;
  }
}

.lt-ie9 .front.large-fonts #block-views-explore-jump-menu-block .block-inner h2.block__title {
  flex: 0 40%;
}

#block-views-explore-jump-menu-block .block-inner .block__content {
  flex: 1;
}

#block-views-explore-jump-menu-block .block-inner select {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background-color: #fff !important;
  background-size: 9px 10px;
}

@media (min-width: 768px) {
  #block-addthis-addthis-block {
    margin-bottom: 1em !important;
  }
}

.lt-ie9 #block-addthis-addthis-block {
  margin-bottom: 1em !important;
}

#block-addthis-addthis-block h2.block__title {
  border-bottom: none;
  color: #1f4ea1;
  font-size: 1em;
  line-height: 1em;
  margin-top: 0;
  flex: 0 40%;
}

@media (min-width: 360px) {
  #block-addthis-addthis-block h2.block__title {
    font-size: 1.2em;
  }
}

.lt-ie9 #block-addthis-addthis-block h2.block__title {
  font-size: 1.2em;
}

@media (min-width: 980px) {
  #block-addthis-addthis-block h2.block__title {
    font-size: 1.2307em;
    flex: 0 47%;
  }
}

.lt-ie9 #block-addthis-addthis-block h2.block__title {
  font-size: 1.2307em;
  flex: 0 47%;
}

@media (min-width: 980px) {
  .front.large-fonts #block-addthis-addthis-block h2.block__title {
    flex: 0 40%;
  }
}

.lt-ie9 .front.large-fonts #block-addthis-addthis-block h2.block__title {
  flex: 0 40%;
}

#block-addthis-addthis-block .block__content {
  min-height: 40px;
}

#block-addthis-addthis-block .block-inner {
  padding: 0 12px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  #block-addthis-addthis-block .block-inner {
    padding: 0 0 0 12px;
  }
}

.lt-ie9 #block-addthis-addthis-block .block-inner {
  padding: 0 0 0 12px;
}

#block-addthis-addthis-block .addthis_toolbox {
  margin-top: 0.5em;
}

#block-addthis-addthis-block .addthis_toolbox a {
  display: inline-block;
  text-align: center;
  margin: 0;
  padding: 0 0.2em;
  border-right: 2px solid #e6e7e9;
}

@media all and (min-width: 320px) {
  #block-addthis-addthis-block .addthis_toolbox a {
    font-size: 0.85em;
  }
}

@media all and (min-width: 370px) {
  #block-addthis-addthis-block .addthis_toolbox a {
    font-size: 1.2em;
  }
}

@media (min-width: 360px) {
  #block-addthis-addthis-block .addthis_toolbox a {
    font-size: 1.4em;
  }
}

.lt-ie9 #block-addthis-addthis-block .addthis_toolbox a {
  font-size: 1.4em;
}

@media all and (min-width: 540px) {
  #block-addthis-addthis-block .addthis_toolbox a {
    font-size: 1.45em;
    padding: 0 0.55em;
  }
}

@media (min-width: 768px) {
  #block-addthis-addthis-block .addthis_toolbox a {
    font-size: 1.1em;
    padding: 0 0.4em;
  }
  .large-fonts #block-addthis-addthis-block .addthis_toolbox a {
    font-size: 0.9em;
    padding: 0 0.3em;
  }
}

.lt-ie9 #block-addthis-addthis-block .addthis_toolbox a {
  font-size: 1.1em;
  padding: 0 0.4em;
}

.large-fonts .lt-ie9 #block-addthis-addthis-block .addthis_toolbox a {
  font-size: 0.9em;
  padding: 0 0.3em;
}

@media (min-width: 980px) {
  #block-addthis-addthis-block .addthis_toolbox a {
    padding: 0 0.6em;
    font-size: 1em;
  }
  .large-fonts #block-addthis-addthis-block .addthis_toolbox a {
    font-size: 1.1em;
  }
}

.lt-ie9 #block-addthis-addthis-block .addthis_toolbox a {
  padding: 0 0.6em;
  font-size: 1em;
}

.large-fonts .lt-ie9 #block-addthis-addthis-block .addthis_toolbox a {
  font-size: 1.1em;
}

@media (min-width: 1175px) {
  .large-fonts #block-addthis-addthis-block .addthis_toolbox a {
    font-size: 0.88em;
  }
}

.large-fonts .lt-ie9 #block-addthis-addthis-block .addthis_toolbox a {
  font-size: 0.88em;
}

@media (min-width: 980px) {
  .large-fonts #block-addthis-addthis-block .addthis_toolbox a {
    flex: 0 40%;
  }
}

.lt-ie9 .large-fonts #block-addthis-addthis-block .addthis_toolbox a {
  flex: 0 40%;
}

#block-addthis-addthis-block .addthis_toolbox a > div,
#block-addthis-addthis-block .addthis_toolbox a > span {
  background: transparent url("../images/icon-facebook.svg") no-repeat scroll center center !important;
  overflow: hidden;
  text-indent: -9999px;
  width: 1.5em !important;
  height: 1.5em !important;
  background-size: 1.3em 1.3em !important;
  display: inline-block;
}

#block-addthis-addthis-block .addthis_toolbox a > div:hover,
#block-addthis-addthis-block .addthis_toolbox a > span:hover {
  background-size: 1.5em 1.5em !important;
}

@media (min-width: 980px) {
  #block-addthis-addthis-block .addthis_toolbox a > div,
  #block-addthis-addthis-block .addthis_toolbox a > span {
    width: 1.7em !important;
    height: 1.7em !important;
    background-size: 1.5em 1.5em !important;
  }
  #block-addthis-addthis-block .addthis_toolbox a > div:hover,
  #block-addthis-addthis-block .addthis_toolbox a > span:hover {
    background-size: 1.7em 1.7em !important;
  }
}

.lt-ie9 #block-addthis-addthis-block .addthis_toolbox a > div, .lt-ie9
#block-addthis-addthis-block .addthis_toolbox a > span {
  width: 1.7em !important;
  height: 1.7em !important;
  background-size: 1.5em 1.5em !important;
}

.lt-ie9 #block-addthis-addthis-block .addthis_toolbox a > div:hover, .lt-ie9
#block-addthis-addthis-block .addthis_toolbox a > span:hover {
  background-size: 1.7em 1.7em !important;
}

@media (min-width: 1175px) {
  #block-addthis-addthis-block .addthis_toolbox a > div,
  #block-addthis-addthis-block .addthis_toolbox a > span {
    width: 2.35em !important;
    height: 2.35em !important;
    background-size: 1.8em 1.8em !important;
  }
  #block-addthis-addthis-block .addthis_toolbox a > div:hover,
  #block-addthis-addthis-block .addthis_toolbox a > span:hover {
    background-size: 2.1em 2.1em !important;
  }
  .large-fonts #block-addthis-addthis-block .addthis_toolbox a > div, .large-fonts
  #block-addthis-addthis-block .addthis_toolbox a > span {
    width: 2.9em !important;
    height: 2.9em !important;
    background-size: 2em 2em !important;
  }
  .large-fonts #block-addthis-addthis-block .addthis_toolbox a > div:hover, .large-fonts
  #block-addthis-addthis-block .addthis_toolbox a > span:hover {
    background-size: 2.3em 2.3em !important;
  }
}

.lt-ie9 #block-addthis-addthis-block .addthis_toolbox a > div, .lt-ie9
#block-addthis-addthis-block .addthis_toolbox a > span {
  width: 2.35em !important;
  height: 2.35em !important;
  background-size: 1.8em 1.8em !important;
}

.lt-ie9 #block-addthis-addthis-block .addthis_toolbox a > div:hover, .lt-ie9
#block-addthis-addthis-block .addthis_toolbox a > span:hover {
  background-size: 2.1em 2.1em !important;
}

.large-fonts .lt-ie9 #block-addthis-addthis-block .addthis_toolbox a > div, .large-fonts .lt-ie9
#block-addthis-addthis-block .addthis_toolbox a > span {
  width: 2.9em !important;
  height: 2.9em !important;
  background-size: 2em 2em !important;
}

.large-fonts .lt-ie9 #block-addthis-addthis-block .addthis_toolbox a > div:hover, .large-fonts .lt-ie9
#block-addthis-addthis-block .addthis_toolbox a > span:hover {
  background-size: 2.3em 2.3em !important;
}

#block-addthis-addthis-block .addthis_toolbox a.addthis_button_twitter > span {
  background-image: url("../images/icon-twitter.svg") !important;
}

#block-addthis-addthis-block .addthis_toolbox a.addthis_button_google_plusone_share > span {
  background-image: url("../images/icon-google-plus.svg") !important;
}

#block-addthis-addthis-block .addthis_toolbox a.addthis_button_linkedin > span {
  background-image: url("../images/icon-linkedin.svg") !important;
}

#block-addthis-addthis-block .addthis_toolbox a.addthis_button_pinterest > div {
  background-image: url("../images/icon-pinterest.svg") !important;
}

#block-addthis-addthis-block .addthis_toolbox a.addthis_button_pinterest > div span {
  display: none;
}

#block-addthis-addthis-block .addthis_toolbox a.addthis_button_email {
  border-right: none;
}

#block-addthis-addthis-block .addthis_toolbox a.addthis_button_email span {
  background-image: url("../images/icon-email.svg") !important;
}

.region-content-bottom #block-media-events {
  display: none;
}

@media (min-width: 768px) {
  .region-content-bottom #block-media-events {
    display: block;
  }
}

.lt-ie9 .region-content-bottom #block-media-events {
  display: block;
}

.region-content-bottom #block-media-events h2.block__title {
  font-weight: 300;
  font-size: 2em;
  border-bottom: 1px solid #d7d6db;
  padding: 0;
  line-height: 1.75em;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.region-content-bottom #block-media-events h2.block__title a {
  color: #5c626c;
}

.region-content-bottom #block-media-events h2.block__title a:hover {
  color: #333;
}

#block-views-bulletin-board-stories-block {
  background-color: #f5f9eb;
  position: relative;
}

#block-views-bulletin-board-stories-block .block-inner .field-feature-image {
  background-color: #fff;
}

#block-views-bulletin-board-stories-block .block-inner .field-feature-image:hover img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

#block-views-bulletin-board-stories-block .field-title {
  min-height: 2.9em;
}

#block-views-bulletin-board-stories-block .field-title a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

#block-views-bulletin-board-stories-block .field-title a:hover {
  text-decoration: underline !important;
}

#block-views-bulletin-board-stories-block .block-title {
  margin-top: 0;
}

#block-views-bulletin-board-stories-block .view-footer {
  padding: 6px 12px 0;
  text-align: right;
}

#block-views-bulletin-board-stories-block .view-footer a {
  padding-left: 17px;
}

@media (min-width: 768px) {
  #block-views-bulletin-board-stories-block .more-wrap {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
}

.lt-ie9 #block-views-bulletin-board-stories-block .more-wrap {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

#block-views-events-block-6 .views-row {
  display: block;
}

#block-views-events-block-6 .views-row:hover img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

#block-views-events-block-6 .block-inner {
  background-color: #f4f4f8;
}

#block-views-events-block-6 h2.block-title {
  margin-top: 0;
  font-size: 1.2307em;
  padding: 8px 0 12px 12px;
  line-height: 2em;
}

#block-views-events-block-6 .field-body {
  min-height: 2.9em;
}

#block-views-events-block-6 .field-body a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

#block-views-events-block-6 .field-body a:hover {
  text-decoration: underline !important;
}

#block-views-events-block-6 .view-footer {
  padding: 12px 0 6px;
}

#block-views-events-block-6 .view-footer a {
  padding-left: 17px;
}

.block {
  margin-bottom: 1.5em;
}

.button-blue {
  background: linear-gradient(to right, #204ea3 0%, #27a8df 100%);
  display: block;
  text-align: right;
}

.button-blue span {
  color: #fff;
  font-size: 0.8461em;
  line-height: 1.3em;
  padding: 4px 10px 4px 16px;
  background: transparent url("../images/green-arrow.svg") no-repeat scroll left center;
  background-size: 10px 10px;
  display: inline-block;
}

.large-fonts .button-blue span {
  font-size: 0.75em;
}

.button-blue:hover {
  text-decoration: none;
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

h2.block-title a {
  color: #8cc63e;
}

h2.block-title a:hover {
  color: #6db111;
  text-decoration: none;
}

#block-views-statistics-block .views-row .field-text {
  color: #1F4EA0;
  font-size: 1.1538em;
  width: 65%;
  margin-left: 35%;
}

@media (min-width: 768px) {
  #block-views-statistics-block .views-row .field-text {
    position: relative;
    top: -15px;
  }
  .large-fonts #block-views-statistics-block .views-row .field-text {
    top: -24px;
  }
}

.lt-ie9 #block-views-statistics-block .views-row .field-text {
  position: relative;
  top: -15px;
}

.large-fonts .lt-ie9 #block-views-statistics-block .views-row .field-text {
  top: -24px;
}

#block-views-statistics-block .button-blue {
  width: 100%;
  position: absolute;
  bottom: -16px;
}

#block-views-statistics-block .u-faux-block-link__overlay {
  bottom: -16px;
}

@media (min-width: 768px) {
  #block-views-statistics-block .u-faux-block-link {
    height: 185px;
  }
  .large-fonts #block-views-statistics-block .u-faux-block-link {
    height: 255px;
  }
}

.lt-ie9 #block-views-statistics-block .u-faux-block-link {
  height: 185px;
}

.large-fonts .lt-ie9 #block-views-statistics-block .u-faux-block-link {
  height: 255px;
}

@media (min-width: 980px) {
  #block-views-statistics-block .u-faux-block-link {
    height: 188px;
  }
  .large-fonts #block-views-statistics-block .u-faux-block-link {
    height: 240px;
  }
}

.lt-ie9 #block-views-statistics-block .u-faux-block-link {
  height: 188px;
}

.large-fonts .lt-ie9 #block-views-statistics-block .u-faux-block-link {
  height: 240px;
}

#block-views-statistics-block .u-faux-block-link:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

#block-views-videos-block-1 .slick__arrow,
#block-views-events-block-1 .slick__arrow,
#block-views-news-block-4 .slick__arrow {
  background-color: #e9edf6;
  margin: 0;
  overflow: hidden;
}

#block-views-videos-block-1 .slick__arrow label,
#block-views-events-block-1 .slick__arrow label,
#block-views-news-block-4 .slick__arrow label {
  margin-bottom: 11px;
}

#block-views-videos-block-1 .slick__arrow ul,
#block-views-events-block-1 .slick__arrow ul,
#block-views-news-block-4 .slick__arrow ul {
  margin-top: 0;
}

#block-views-videos-block-1 .slick__arrow button,
#block-views-events-block-1 .slick__arrow button,
#block-views-news-block-4 .slick__arrow button {
  background-size: 10px 9px !important;
  text-transform: lowercase;
  list-style: none;
  font-size: 11px;
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 6px;
  outline: 0;
  line-height: 1.3em;
  border: none;
  color: #1f4ea1;
}

#block-views-videos-block-1 .slick__arrow button:hover,
#block-views-events-block-1 .slick__arrow button:hover,
#block-views-news-block-4 .slick__arrow button:hover {
  color: #333;
  cursor: pointer;
}

#block-views-videos-block-1 .slick__arrow button:first-child,
#block-views-events-block-1 .slick__arrow button:first-child,
#block-views-news-block-4 .slick__arrow button:first-child {
  background: transparent url("../images/blue-arrow-right.svg") no-repeat scroll left center;
  float: left !important;
  padding-left: 15px;
  margin-left: 10px;
  width: 6em;
}

#block-views-videos-block-1 .slick__arrow button:last-child,
#block-views-events-block-1 .slick__arrow button:last-child,
#block-views-news-block-4 .slick__arrow button:last-child {
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll right center;
  float: right !important;
  padding-right: 10px;
  margin-right: 10px;
  width: 4.3em;
}

#block-views-videos-block .u-faux-block-link {
  display: flex;
}

@media (min-width: 768px) {
  #block-views-videos-block .u-faux-block-link {
    display: block;
  }
}

.lt-ie9 #block-views-videos-block .u-faux-block-link {
  display: block;
}

@media (min-width: 980px) {
  #block-views-videos-block .u-faux-block-link {
    display: flex;
  }
}

.lt-ie9 #block-views-videos-block .u-faux-block-link {
  display: flex;
}

#block-views-videos-block .u-faux-block-link .teaser-left {
  padding-right: 0;
}

#block-views-videos-block .u-faux-block-link .teaser-left .field-feature-video {
  width: 135px;
  padding-right: 12px;
}

@media (min-width: 768px) {
  #block-views-videos-block .u-faux-block-link .teaser-left .field-feature-video {
    width: auto;
    padding-right: 0;
    margin-bottom: 0.4em;
  }
}

.lt-ie9 #block-views-videos-block .u-faux-block-link .teaser-left .field-feature-video {
  width: auto;
  padding-right: 0;
  margin-bottom: 0.4em;
}

@media (min-width: 980px) {
  #block-views-videos-block .u-faux-block-link .teaser-left .field-feature-video {
    width: 140px;
    padding-right: 12px;
    margin-bottom: 0;
  }
}

.lt-ie9 #block-views-videos-block .u-faux-block-link .teaser-left .field-feature-video {
  width: 140px;
  padding-right: 12px;
  margin-bottom: 0;
}

@media (min-width: 1175px) {
  #block-views-videos-block .u-faux-block-link .teaser-left .field-feature-video {
    width: 180px;
  }
}

.lt-ie9 #block-views-videos-block .u-faux-block-link .teaser-left .field-feature-video {
  width: 180px;
}

#block-views-videos-block .u-faux-block-link .teaser-left img {
  width: 100%;
}

#block-views-videos-block .u-faux-block-link .teaser-right {
  flex: 1;
}

#block-views-videos-block .views-row-3 {
  display: none;
}

@media (min-width: 980px) {
  #block-views-videos-block .views-row-3 {
    display: flex;
  }
}

.lt-ie9 #block-views-videos-block .views-row-3 {
  display: flex;
}

#block-twitter-block-1 {
  max-height: 465px;
  overflow-y: scroll;
}

#block-views-latest-news-block-2 {
  margin-bottom: 0;
}

#block-views-latest-news-block-2 .block-inner {
  background-color: #EEF9FD;
}

#block-views-latest-news-block-2 .block-inner .field-feature-image {
  background-color: #fff;
}

#block-views-latest-news-block-2 .block-inner h2.block-title {
  border: none;
  padding: 10px 0 2px 12px;
  line-height: 1em;
}

#block-views-latest-news-block-2 .view-content .views-row {
  padding: 9px 0;
  margin: 0 12px;
  border-bottom: 1px solid #fff;
}

@media all and (min-width: 290px) {
  #block-views-latest-news-block-2 .view-content .views-row {
    display: flex;
  }
  #block-views-latest-news-block-2 .view-content .views-row .teaser-left {
    flex: 0 125px;
  }
  #block-views-latest-news-block-2 .view-content .views-row .teaser-right {
    flex: 1;
    padding-left: 12px;
  }
}

#block-views-latest-news-block-2 .view-content .views-row-last {
  border-bottom: none;
}

#block-views-latest-news-block-2 h3.field-title {
  display: inline;
}

#block-views-latest-news-block-2 h3.field-title a:hover {
  text-decoration: none;
}

#block-views-latest-news-block-2 .field-date {
  color: #24ace0;
  padding-left: 0.3em;
  font-size: 1em;
}

#block-views-latest-news-block-2 .view .view-footer {
  padding: 6px 12px 0;
  text-align: right;
}

#block-views-latest-news-block-2 .view .view-footer a {
  padding-left: 17px;
}

#block-views-latest-news-block-2 .field-wrap {
  background-color: #fff;
  position: relative;
  width: 120px;
  height: 85px;
  display: flex;
  align-items: center;
}

#block-views-latest-news-block-2 .field-wrap.icon-media-release img {
  width: 85%;
}

#block-views-latest-news-block-2 .field-wrap img {
  border: none;
  padding: 0;
}

#block-views-latest-news-block-2 .more-wrap {
  padding-bottom: 12px;
}

#block-views-latest-news-block-1 .block-inner {
  background-color: #EEF9FD;
}

#block-views-latest-news-block-1 .block-inner .field-wrap {
  position: relative;
  font-size: 1.2em;
}

#block-views-latest-news-block-1 .block-inner .field-feature-image {
  background-color: #fff;
}

#block-views-latest-news-block-1 .block-inner h3.field-title {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

#block-views-latest-news-block-1 .block-inner .field-body a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

#block-views-latest-news-block-1 .block-inner .field-body a:hover {
  text-decoration: underline !important;
}

#block-views-latest-news-block-1 .block-inner .field-date {
  background: linear-gradient(to right, #63ae6b 0%, #90ba56 100%);
  position: absolute;
  font-size: 0.8461em;
  line-height: 1.89091em;
  padding: 0 8px;
  color: #fff;
  bottom: 0;
  left: 0;
  font-size: 0.72em;
}

@media (min-width: 768px) {
  #block-views-latest-news-block-1 .block-inner .views-row {
    flex: 0 25%;
  }
}

.lt-ie9 #block-views-latest-news-block-1 .block-inner .views-row {
  flex: 0 25%;
}

@media (min-width: 768px) {
  #block-views-bulletin-board-stories-block .block-inner .views-row {
    flex: 0 33.33%;
    width: 33.33%;
    max-width: 33.33%;
  }
}

.lt-ie9 #block-views-bulletin-board-stories-block .block-inner .views-row {
  flex: 0 33.33%;
  width: 33.33%;
  max-width: 33.33%;
}

#block-views-bulletin-board-stories-block .block-inner .views-row .field-title {
  min-height: 2.9em;
  margin-top: 0.3em;
}

#block-views-bulletin-board-stories-block .block-inner .views-row .field-title a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

#block-views-bulletin-board-stories-block .block-inner .views-row .field-title a:hover {
  text-decoration: underline !important;
}

#block-views-bulletin-board-stories-block .block-inner .views-row .field-title a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

#block-views-bulletin-board-stories-block .block-inner .views-row .field-title a:hover {
  text-decoration: underline !important;
}

#block-views-bulletin-board-stories-block h2.block-title,
#block-views-latest-news-block-1 h2.block-title {
  color: #1f4ea1;
  font-size: 1.2307em;
  line-height: 2em;
  border: none;
  padding: 8px 0 12px 12px;
}

#block-views-bulletin-board-stories-block .more-link,
#block-views-latest-news-block-1 .more-link {
  font-size: 0.8461en;
}

@media (min-width: 360px) {
  #block-views-bulletin-board-stories-block .view-content,
  #block-views-latest-news-block-1 .view-content {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 360px) and (min-width: 768px) {
  #block-views-bulletin-board-stories-block .view-content,
  #block-views-latest-news-block-1 .view-content {
    flex-wrap: nowrap;
  }
}

@media (min-width: 360px) {
  .lt-ie9 #block-views-bulletin-board-stories-block .view-content, .lt-ie9
  #block-views-latest-news-block-1 .view-content {
    flex-wrap: nowrap;
  }
}

.lt-ie9 #block-views-bulletin-board-stories-block .view-content, .lt-ie9
#block-views-latest-news-block-1 .view-content {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .lt-ie9 #block-views-bulletin-board-stories-block .view-content, .lt-ie9
  #block-views-latest-news-block-1 .view-content {
    flex-wrap: nowrap;
  }
}

.lt-ie9 .lt-ie9 #block-views-bulletin-board-stories-block .view-content, .lt-ie9 .lt-ie9
#block-views-latest-news-block-1 .view-content {
  flex-wrap: nowrap;
}

#block-views-bulletin-board-stories-block .views-row,
#block-views-latest-news-block-1 .views-row {
  position: relative;
}

#block-views-bulletin-board-stories-block .views-row:hover img,
#block-views-latest-news-block-1 .views-row:hover img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

@media (min-width: 360px) {
  #block-views-bulletin-board-stories-block .views-row,
  #block-views-latest-news-block-1 .views-row {
    flex: 0 50%;
  }
}

.lt-ie9 #block-views-bulletin-board-stories-block .views-row, .lt-ie9
#block-views-latest-news-block-1 .views-row {
  flex: 0 50%;
}

#block-views-bulletin-board-stories-block .views-row .field-wrap, #block-views-bulletin-board-stories-block .views-row .field-title, #block-views-bulletin-board-stories-block .views-row .field-body,
#block-views-latest-news-block-1 .views-row .field-wrap,
#block-views-latest-news-block-1 .views-row .field-title,
#block-views-latest-news-block-1 .views-row .field-body {
  margin-left: 12px;
  margin-right: 12px;
}

#block-views-bulletin-board-stories-block .more-wrap,
#block-views-latest-news-block-1 .more-wrap {
  padding-top: 0;
}

#block-views-events-block-6 .more-wrap {
  padding: 0 12px 12px;
}

ul.sf-menu > li:hover > ul,
ul.sf-menu > li.sfHover > ul {
  left: 0;
  top: 45px;
}

.large-fonts ul.sf-menu > li:hover > ul, .large-fonts
ul.sf-menu > li.sfHover > ul {
  top: 47px;
}

.not-front .region-navigation .band {
  background: linear-gradient(to right, #225cad 0%, #2799d7 100%);
  height: 10px;
  clear: both;
}

.region-navigation .contextual-links-region {
  position: static;
}

ul.sf-menu.sf-accordion.sf-expanded {
  background-color: #efefef;
  position: relative;
  z-index: 100;
  font-size: 1.066em;
  border-top: 3px solid #8CC53F;
}

@media all and (min-width: 680px) {
  ul.sf-menu.sf-accordion.sf-expanded {
    background-position: right -85px top;
  }
}

#superfish-1-toggle {
  background: #efefef url("../images/hamburger.svg") no-repeat scroll center center;
  display: block;
  height: 38px;
  width: 38px;
  text-indent: -9999px;
  overflow: hidden;
  outline: none;
  background-size: 28px 28px;
}

#superfish-1-toggle.sf-expanded {
  background-size: 16px 16px;
  background-color: #8CC53F;
  background-image: url("../images/hamburger-close.svg");
}

#superfish-1 {
  display: none;
}

@media (min-width: 768px) {
  #superfish-1 {
    display: block;
  }
}

.lt-ie9 #superfish-1 {
  display: block;
}

ul.sf-menu.sf-accordion {
  left: -9999px;
}

ul.sf-menu.sf-accordion li.menuparent > a.sf-accordion-button {
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll center center;
  background-size: 10px 10px;
  display: block;
  text-indent: -9999px;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 53px;
  height: 53px;
  z-index: 5000;
}

ul.sf-menu.sf-accordion li {
  position: relative;
}

ul.sf-menu.sf-accordion li a.sf-depth-1, ul.sf-menu.sf-accordion li a.sf-depth-2, ul.sf-menu.sf-accordion li a.sf-depth-3, ul.sf-menu.sf-accordion li a.sf-depth-4, ul.sf-menu.sf-accordion li a.sf-depth-5 {
  padding: 20px 15px 20px;
  color: #1f4ea1;
  outline: none;
  border-bottom: 1px solid #d7d6db;
}

ul.sf-menu.sf-accordion li a.sf-depth-1:hover, ul.sf-menu.sf-accordion li a.sf-depth-1:active, ul.sf-menu.sf-accordion li a.sf-depth-2:hover, ul.sf-menu.sf-accordion li a.sf-depth-2:active, ul.sf-menu.sf-accordion li a.sf-depth-3:hover, ul.sf-menu.sf-accordion li a.sf-depth-3:active, ul.sf-menu.sf-accordion li a.sf-depth-4:hover, ul.sf-menu.sf-accordion li a.sf-depth-4:active, ul.sf-menu.sf-accordion li a.sf-depth-5:hover, ul.sf-menu.sf-accordion li a.sf-depth-5:active {
  color: #5c626c;
}

ul.sf-menu.sf-accordion li.sf-expanded > a.sf-accordion-button {
  background-image: url("../images/blue-arrow-down.svg");
}

ul.sf-menu.sf-accordion li.sf-expanded a {
  background-color: #efeff0;
  border-bottom-color: #d7d6db;
}

ul.sf-menu.sf-accordion li.sf-expanded > ul > li a {
  padding-left: 30px;
  color: #8cc63e;
  font-size: 0.9em;
}

ul.sf-menu.sf-accordion li.sf-expanded > ul > li a:hover, ul.sf-menu.sf-accordion li.sf-expanded > ul > li a.active {
  color: #6db111;
}

ul.sf-menu.sf-accordion li li.sf-expanded > ul > li a {
  padding-left: 37px;
  color: #5c626c;
}

ul.sf-menu.sf-accordion li li.sf-expanded > ul > li a::before {
  content: "– ";
}

ul.sf-menu.sf-accordion li li.sf-expanded > ul > li a:hover, ul.sf-menu.sf-accordion li li.sf-expanded > ul > li a.active {
  color: #000;
}

ul.sf-menu.sf-accordion li li li.sf-expanded > ul > li a {
  padding-left: 49px;
}

ul.sf-menu.sf-accordion li li li.sf-expanded > ul > li a:hover, ul.sf-menu.sf-accordion li li li.sf-expanded > ul > li a.active {
  color: #000;
}

li#menu-613-1 > ul {
  display: none !important;
}

#block-superfish-1 {
  margin-top: 0;
  margin-bottom: 0;
}

.layout-mobile #block-superfish-1,
.layout-tablet #block-superfish-1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 6.4em;
}

.layout-tablet #block-superfish-1 {
  top: 8.5em;
}

.large-fonts.layout-tablet #block-superfish-1 {
  top: 8em;
}

.layout-mobile #block-superfish-1 #superfish-1-toggle,
.layout-tablet #block-superfish-1 #superfish-1-toggle {
  position: absolute;
}

.layout-mobile #block-superfish-1 #superfish-1-toggle {
  top: -2.9em;
  left: 12px;
}

.layout-tablet #block-superfish-1 #superfish-1-toggle {
  top: -2.83em;
  right: 12px;
  left: auto;
}

.large-fonts.layout-tablet #block-superfish-1 #superfish-1-toggle {
  top: -2.2em;
}

#superfish-1 {
  position: relative;
}

@media all and (max-width: 1100px) {
  #superfish-1 > li {
    position: static;
  }
}

#superfish-1 ul.sf-megamenu {
  font-size: 0.9em;
  max-width: 711px;
}

#superfish-1 ul.sf-megamenu li.sf-megamenu-wrapper {
  max-width: 711px;
}

#superfish-1 ul.sf-megamenu li.sf-megamenu-wrapper > ol {
  display: flex;
  flex-wrap: wrap;
  width: 711px !important;
}

#superfish-1 ul.sf-megamenu li.sf-megamenu-wrapper > ol > li {
  float: none;
  border-bottom: 1px solid #d7d6db;
  width: 33.33% !important;
  padding-bottom: 1em;
}

#superfish-1 ul.sf-megamenu {
  background-color: #efeff0;
  border-bottom: 7px solid #8dc73f;
}

#superfish-1 .sf-depth-2 .sf-megamenu-column > a,
#superfish-1 .sf-depth-2 > a {
  color: #8cc63e;
}

#superfish-1 .sf-depth-2 .sf-megamenu-column > a.active, #superfish-1 .sf-depth-2 .sf-megamenu-column > a:hover,
#superfish-1 .sf-depth-2 > a.active,
#superfish-1 .sf-depth-2 > a:hover {
  color: #6db111;
}

#superfish-1 > li.sfHover > a {
  color: #1f4ea1;
  border-bottom-color: #8dc73f;
}

#superfish-1 > li.active-trail > a {
  color: #1f4ea1;
  border-bottom-color: #8dc73f;
}

#superfish-1 > li > a {
  padding: 12px 6px 16px;
  color: #5c626c;
  line-height: 1em;
  border-bottom: 3px solid transparent;
}

@media all and (min-width: 840px) {
  #superfish-1 > li > a {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1.0769em;
  }
}

@media (min-width: 980px) {
  #superfish-1 > li > a {
    padding-left: 18px;
    padding-right: 18px;
    font-size: 1.0769em;
  }
}

.lt-ie9 #superfish-1 > li > a {
  padding-left: 18px;
  padding-right: 18px;
  font-size: 1.0769em;
}

@media (min-width: 1175px) {
  #superfish-1 > li > a {
    font-size: 1.1538em;
    padding-left: 29px;
    padding-right: 29px;
  }
}

.lt-ie9 #superfish-1 > li > a {
  font-size: 1.1538em;
  padding-left: 29px;
  padding-right: 29px;
}

#superfish-1 > li > a:hover {
  color: #1f4ea1;
  border-bottom-color: #8dc73f;
}

.large-fonts #superfish-1 > li > a {
  padding: 13px 7px;
  font-size: 0.8em;
}

@media all and (min-width: 900px) {
  .large-fonts #superfish-1 > li > a {
    padding-left: 13px;
    padding-right: 13px;
  }
}

@media (min-width: 1175px) {
  .large-fonts #superfish-1 > li > a {
    font-size: 1.07em;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.lt-ie9 .large-fonts #superfish-1 > li > a {
  font-size: 1.07em;
  padding-left: 15px;
  padding-right: 15px;
}

#superfish-1 > li li.sf-depth-2 .sf-megamenu-column ol li ul {
  display: none !important;
}

#superfish-1 > li li.sf-depth-2 .sf-megamenu-column ol li a {
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 0 center;
  background-size: 8px 8px;
  margin-left: 12px;
  padding: 4px 0 3px 17px;
  font-size: 1em;
}

#superfish-1 > li li.sf-depth-2 .sf-megamenu-column ol li a:hover, #superfish-1 > li li.sf-depth-2 .sf-megamenu-column ol li a.active {
  color: #000;
}

#superfish-1 > li li a {
  color: #5c626c;
  font-size: 1.1538em;
  line-height: 1.35em;
  padding: 8px 12px;
}

#superfish-1 > li li.menuparent > a:hover {
  background-position: right 15px top -253px;
  background-color: #534c4b;
  color: #fff;
}

.block-superfish li#menu-2664-1 {
  float: right;
}

.block-superfish li#menu-2664-1 > a {
  background-color: #93c74e;
  border-left-color: #93c74e;
  border-right-color: #93c74e;
  border-top-color: #93c74e;
  border-bottom: 3px solid #fff;
  color: #fff;
}

.block-superfish li#menu-2664-1 > a:hover {
  background-color: #83bd38;
  border-color: #83bd38;
  border-bottom-color: #fff;
}

#superfish-1 li#menu-2664-1 a {
  padding-bottom: 11px;
}

/* First-level Menu */
.region-navigation .block__content > .menu li a {
  display: block;
}

.region-navigation .block__content > .menu li a:hover {
  text-decoration: none;
}

.region-navigation .block__content > .menu > li.sfHover a:before, .region-navigation .block__content > .menu > li.sfHover + li a:before, .region-navigation .block__content > .menu > li:hover a:before, .region-navigation .block__content > .menu > li:hover + li a:before, .region-navigation .block__content > .menu > li.active-trail a:before, .region-navigation .block__content > .menu > li.active-trail + li a:before {
  border: 0;
}

#wrapper {
  clear: both;
}

.file {
  margin-bottom: 3em;
}

.field-name-field-simplenews-body .file,
.search-results .file {
  margin-bottom: 0;
}

.file-video .field-name-field-video-type {
  display: none;
}

.file-video .field-name-field-pdf {
  margin-top: 1em;
}

.page-node .field-name-field-thumbnail {
  margin-top: 0.8em;
}

.node {
  margin-bottom: 2em;
}

.node .links {
  overflow: hidden;
  clear: both;
  display: block;
  padding-top: 1.2em;
}

.node-simplenews table {
  margin: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.node-simplenews table th,
.node-simplenews table td {
  border: none;
}

.node-simplenews table th {
  background-color: none;
  background-image: none;
}

.node-simplenews table th strong {
  color: inherit !important;
  font-weight: normal !important;
}

.field-name-field-feature-image {
  width: 100%;
  padding-bottom: 1em;
}

@media (min-width: 360px) {
  .file.aspect-align-right .field-name-field-feature-image,
  .node.aspect-align-right .field-name-field-feature-image {
    width: 45%;
    float: right;
    padding-left: 1em;
  }
}

.file.aspect-align-right .lt-ie9 .field-name-field-feature-image,
.node.aspect-align-right .lt-ie9 .field-name-field-feature-image {
  width: 45%;
  float: right;
  padding-left: 1em;
}

.field-name-field-feature-image img {
  width: 100%;
  max-width: 100%;
}

@media (min-width: 360px) {
  .file.aspect-align-right .field-name-field-feature-image img,
  .node.aspect-align-right .field-name-field-feature-image img {
    float: right;
    padding: 0 0 5px 20px;
  }
}

.file.aspect-align-right .lt-ie9 .field-name-field-feature-image img,
.node.aspect-align-right .lt-ie9 .field-name-field-feature-image img {
  float: right;
  padding: 0 0 5px 20px;
}

.g-recaptcha {
  margin-top: 1.5em;
}

.u-faux-block-link {
  position: relative;
}

.u-faux-block-link a {
  position: relative;
  z-index: 1;
}

.u-faux-block-link__overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-indent: 200%;
  white-space: nowrap;
  background: transparent;
}

a.u-faux-block-link__overlay {
  position: absolute;
  z-index: 0;
  opacity: 0;
}

a.u-faux-block-link__overlay:focus {
  outline: none;
}

.u-faux-block-link--hover .u-faux-block-link__cta {
  text-decoration: underline;
}

.node-simplenews table td {
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: inherit !important;
  line-height: inherit !important;
}

.alignright {
  float: right;
  margin: 0 0 1.5em 1.5em !important;
  width: 250px;
}

.alignleft {
  float: left;
  margin: 0 1.5em 1.5em 0 !important;
  width: 250px;
}

.field-name-field-feature-image .file {
  margin-bottom: 0;
}

.accordion h5 {
  font-size: 1.3em;
  padding-left: 1.5em;
  color: #5c626c;
}

.accordion .accordion-header {
  background: transparent url(../images/blue-arrow-left.svg) no-repeat scroll left 1em center;
  background-size: 10px 9px;
  background-color: #f5f9eb;
  padding: 4px 1em 4px 2.5em;
  margin-bottom: 2px;
}

.accordion .accordion-header:hover {
  cursor: pointer;
}

.accordion .accordion-header:hover h5 {
  color: #333;
}

.accordion .accordion-content {
  padding: 2em;
}

.accordion .open .accordion-header {
  background-image: url(../images/blue-arrow-down.svg);
}

.node-simplenews img.imageCenter {
  margin: 0 auto;
  width: auto;
}

.node-simplenews .devicewidth img {
  width: auto;
}

.node-simplenews .devicewidth table[align=left] {
  float: left;
  width: 60%;
  padding-right: 20px;
  box-sizing: border-box;
  display: block;
}

.node-simplenews .devicewidth table[align=right] {
  float: right;
  width: 40%;
  display: block;
}

.page-user .tabs {
  margin-bottom: 0;
}

.page-user #content form {
  overflow: hidden;
  border: none;
  padding: 1em 0 2em;
}

.page-user #content form .form-text {
  width: 100%;
}

@media (min-width: 768px) {
  .page-user #content form .form-text {
    width: auto;
  }
}

.lt-ie9 .page-user #content form .form-text {
  width: auto;
}

.not-front #block-menu-block-2 h2.block__title {
  line-height: 1.3em;
}

@media all and (max-width: 767px) {
  .not-front #block-menu-block-2 h2.block__title {
    background: linear-gradient(to right, #204ea3 0%, #27a8df 100%);
    display: block;
    text-align: right;
    text-align: left;
    font-size: 1.3em;
    text-indent: 1em;
  }
  .not-front #block-menu-block-2 h2.block__title span {
    color: #fff;
    font-size: 0.8461em;
    line-height: 1.3em;
    padding: 4px 10px 4px 16px;
    background: transparent url("../images/green-arrow.svg") no-repeat scroll left center;
    background-size: 10px 10px;
    display: inline-block;
  }
  .large-fonts .not-front #block-menu-block-2 h2.block__title span {
    font-size: 0.75em;
  }
  .not-front #block-menu-block-2 h2.block__title:hover {
    text-decoration: none;
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
    transition: filter .1s;
  }
  .not-front #block-menu-block-2 h2.block__title a {
    color: #fff;
  }
}

@media (min-width: 768px) {
  .not-front #block-menu-block-2 h2.block__title {
    border-bottom: 5px solid #4eb856;
  }
  .not-front #block-menu-block-2 h2.block__title a {
    color: #1f4ea1;
  }
  .not-front #block-menu-block-2 h2.block__title a:hover {
    text-decoration: none;
    color: #333;
  }
}

.lt-ie9 .not-front #block-menu-block-2 h2.block__title {
  border-bottom: 5px solid #4eb856;
}

.lt-ie9 .not-front #block-menu-block-2 h2.block__title a {
  color: #1f4ea1;
}

.lt-ie9 .not-front #block-menu-block-2 h2.block__title a:hover {
  text-decoration: none;
  color: #333;
}

.not-front #block-menu-block-2 .block__content {
  padding: 0;
}

@media all and (max-width: 767px) {
  .not-front #block-menu-block-2 .block__content {
    background-color: #efefef;
  }
  .not-front #block-menu-block-2 .block__content ul.menu {
    margin: 0;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu {
    margin-top: 0;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li {
    border-bottom: 1px solid #d7d6db;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li > a {
    color: #8cc63e;
    background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left 16px center;
    background-size: 10px 9px;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li > a:hover, .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li > a.active {
    color: #6db111;
    text-decoration: none;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li.active {
    color: #6db111;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li {
    list-style: none;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li a {
    padding: 6px 15px 6px 37px;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a {
    padding-left: 40px;
    color: #5c626c;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a::before {
    content: "– ";
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a:hover, .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a.active {
    color: #000;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a {
    padding-left: 52px;
    font-size: 0.9em;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a::before {
    content: "○ ";
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a {
    padding-left: 52px;
    font-size: 0.9em;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a::before {
    content: "- ";
  }
}

@media (min-width: 768px) {
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li {
    border-bottom: 1px solid #d7d6db;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li > a {
    background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left 9px center;
    background-size: 10px 9px;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu ul.menu {
    margin: 0;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li {
    list-style: none;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li a {
    display: block;
    padding: 6px 15px 6px 27px;
    line-height: 1.7em;
    font-size: 1.15385em;
    line-height: 1.38667em;
    color: #424242;
    outline: none;
    font-weight: normal;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li a.active {
    color: #8cc63e;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li a:hover {
    background-color: #f5f9eb;
    color: #8cc63e;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a {
    padding-left: 30px;
    font-size: 1.07692em;
    line-height: 1.48571em;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a::before {
    content: "– ";
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a:hover, .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a.active {
    background-color: #c9e4ec;
    color: #1f4ea1;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a {
    padding-left: 40px;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a::before {
    content: "○ ";
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a:hover, .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a.active, .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a.active-trail {
    background-color: #daf4fc;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a {
    font-size: 1em;
    line-height: 1.6em;
    padding-left: 50px;
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a::before {
    content: "– ";
  }
  .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a:hover, .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a.active, .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a.active-trail {
    background-color: #daf4fc;
  }
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li {
  border-bottom: 1px solid #d7d6db;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu > li > a {
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll left 9px center;
  background-size: 10px 9px;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu ul.menu {
  margin: 0;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li {
  list-style: none;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li a {
  display: block;
  padding: 6px 15px 6px 27px;
  line-height: 1.7em;
  font-size: 1.15385em;
  line-height: 1.38667em;
  color: #424242;
  outline: none;
  font-weight: normal;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li a.active {
  color: #8cc63e;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li a:hover {
  background-color: #f5f9eb;
  color: #8cc63e;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a {
  padding-left: 30px;
  font-size: 1.07692em;
  line-height: 1.48571em;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a::before {
  content: "– ";
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a:hover, .lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li a.active {
  background-color: #c9e4ec;
  color: #1f4ea1;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a {
  padding-left: 40px;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a::before {
  content: "○ ";
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a:hover, .lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a.active, .lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li a.active-trail {
  background-color: #daf4fc;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a {
  font-size: 1em;
  line-height: 1.6em;
  padding-left: 50px;
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a::before {
  content: "– ";
}

.lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a:hover, .lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a.active, .lt-ie9 .not-front #block-menu-block-2 .block__content .menu-block-wrapper > ul.menu li li li li a.active-trail {
  background-color: #daf4fc;
}

#block-menu-block-3 .menu-block-wrapper > ul.menu > li ul.menu {
  margin: 0;
  padding: 0;
}

#block-menu-block-3 .menu-block-wrapper > ul.menu > li > a {
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 5px center;
  background-size: 10px 9px;
  padding-left: 1.5em;
}

#block-menu-block-3 .menu-block-wrapper > ul.menu > li a {
  outline: none;
  line-height: 1.8em;
  padding-left: 1.5em;
}

#block-menu-block-3 .menu-block-wrapper > ul.menu > li li a {
  padding-left: 2.2em;
}

#block-menu-block-3 .menu-block-wrapper > ul.menu > li li a::before {
  content: "– ";
}

.title-underline .block__title {
  margin-top: 0;
  border-bottom: 1px solid #d7d6db;
  color: #8cc63e;
  font-weight: 400;
  line-height: 1.25em;
  padding-bottom: 0.2em;
  margin-bottom: 0.5em;
}

.title-underline .block__title a {
  color: #8cc63e;
}

#block-qmhc-social-links .block__content {
  display: flex;
  margin-top: 0.75em;
}

@media (min-width: 1175px) {
  .front #block-qmhc-social-links .block__content {
    text-align: left;
  }
}

.lt-ie9 .front #block-qmhc-social-links .block__content {
  text-align: left;
}

#block-qmhc-social-links .social-link {
  width: 2.666em;
  float: left;
  margin-right: 0.6em;
}

#block-qmhc-social-links .social-link img {
  vertical-align: middle;
  display: inline;
  transition: all .2s ease-in-out;
}

#block-qmhc-social-links .social-link img:hover {
  transform: scale(1.1);
}

#block-qmhc-social-links .social-link a {
  color: #323232;
  outline: none;
  display: block;
  font-size: 1.07692em;
  line-height: 1.48571em;
}

#block-qmhc-social-links .social-link a:hover {
  color: #000;
  text-decoration: none;
}

.box-green .block-inner {
  background: #e8f4da url("../images/bg-logo-green.svg") no-repeat scroll bottom right;
  padding: 12px 12px 24px;
  position: relative;
  background-size: 80px 80px;
}

.box-green .block-inner h2 {
  border-bottom: none;
  color: #1f4ea1;
  font-size: 1.8461em;
  line-height: 1.1em;
  margin-top: 0.25em;
}

.box-green .block-inner .block__content {
  font-size: 1.1538em;
}

.box-green .block-inner a.button-blue {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 0.8666em;
}

.more-wrap {
  padding: 6px 12px 0;
  text-align: right;
}

.more-wrap a {
  padding-left: 17px;
}

#block-views-events-block-1 .view-display-id-block_7 .field-title a:hover {
  text-decoration: underline;
}

#block-views-news-block-1 {
  margin-bottom: 0;
}

#block-views-news-block-1 .block-inner h2.block-title {
  border: none;
  padding: 10px 0 2px 12px;
  line-height: 1em;
}

#block-views-news-block-1 h3.field-title {
  display: inline;
}

#block-views-news-block-1 h3.field-title a:hover {
  text-decoration: none;
}

#block-views-news-block-1 .field-date {
  color: #24ace0;
  padding-left: 0.3em;
  font-size: 1em;
  display: inline;
}

#block-views-news-block-1 .view .view-footer {
  padding: 6px 12px 0;
  text-align: right;
}

#block-views-news-block-1 .view .view-footer a {
  padding-left: 17px;
}

#block-views-news-block-1 .field-wrap {
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
}

#block-views-news-block-1 .field-wrap img {
  border: none;
  padding: 0;
  margin: 0 auto;
}

#block-views-news-block-1 .more-wrap {
  padding-bottom: 12px;
}

.block-slider .slick__slider .slide__content {
  padding: 0;
}

.block-slider .slick__slider .u-faux-block-link {
  padding: 12px;
}

.block-slider .slick__slider .u-faux-block-link:hover img {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
  transition: filter .1s;
}

.block-slider .slick__arrow {
  background-color: #e9edf6;
  margin: 0;
  overflow: hidden;
}

.block-slider .slick__arrow label {
  margin-bottom: 11px;
}

.block-slider .slick__arrow ul {
  margin-top: 0;
}

.block-slider .slick__arrow button {
  background-size: 10px 9px !important;
  text-transform: lowercase;
  list-style: none;
  font-size: 11px;
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 6px;
  outline: 0;
  line-height: 1.3em;
  border: none;
  color: #1f4ea1;
}

.block-slider .slick__arrow button:hover {
  color: #333;
  cursor: pointer;
}

.block-slider .slick__arrow button:first-child {
  background: transparent url("../images/blue-arrow-right.svg") no-repeat scroll left center;
  float: left !important;
  padding-left: 15px;
  margin-left: 10px;
  width: 6em;
}

.block-slider .slick__arrow button:last-child {
  background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll right center;
  float: right !important;
  padding-right: 10px;
  margin-right: 10px;
  width: 4.3em;
}

.block-slider .view-footer {
  padding: 6px 12px 0;
  text-align: right;
}

.block-slider .view-footer a {
  padding-left: 17px;
}

#block-views-sector-news-block-4 .slick__slider {
  background-color: #f5f9eb;
}

#block-views-sector-news-block-4 .field-image-wrap {
  position: relative;
  margin-bottom: 0.5em;
  background-color: #fff;
}

#block-views-sector-news-block-4 .field-image-wrap .field-date,
#block-views-sector-news-block-4 .field-image-wrap .field-news-type {
  background: linear-gradient(to right, #63ae6b 0%, #90ba56 100%);
  position: absolute;
  font-size: 0.8461em;
  line-height: 1.89091em;
  padding: 0 8px;
  color: #fff;
  bottom: 0;
  left: 1px;
}

#block-views-news-block-4 .slick__slider {
  background-color: #EEF9FD;
}

#block-views-news-block-4 .field-image-wrap {
  position: relative;
  background-color: #fff;
}

#block-views-news-block-4 h4.field-title {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

#block-views-news-block-4 .field-date {
  background: linear-gradient(to right, #63ae6b 0%, #90ba56 100%);
  position: absolute;
  font-size: 0.8461em;
  line-height: 1.89091em;
  padding: 0 8px;
  color: #fff;
  bottom: 0;
  left: 0;
}

#block-views-news-block-4 .field-body a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

#block-views-news-block-4 .field-body a:hover {
  text-decoration: underline !important;
}

h4.field-title {
  font-weight: normal;
  margin: 0 0 0.5em;
}

.sidebar-block .views-row > div,
.section-block .views-row > div {
  position: relative;
  background-color: #efefef;
}

.sidebar-block .views-row > div .inner,
.section-block .views-row > div .inner {
  padding: 12px;
  background: transparent url("../images/bg-logo-white.svg") no-repeat scroll bottom right;
  background-size: 80px 80px;
}

.sidebar-block .block__content ul,
.section-block .block__content ul {
  padding-left: 24px;
}

.sidebar-block .field-body,
.section-block .field-body {
  font-size: 1.1538em;
}

.sidebar-block h3.field-title,
.section-block h3.field-title {
  border-bottom: none;
  color: #1f4ea1;
  font-size: 1.84615em;
  line-height: 1.73333em;
  line-height: 1.1em;
  margin-top: 0.25em;
}

.sidebar-block .button-blue a,
.section-block .button-blue a {
  color: #fff;
  text-decoration: none;
}

.block .view-sidebar-block .bg-colour-1 {
  background-color: #f5f9eb;
}

.block .view-sidebar-block .bg-colour-1 .inner {
  background-image: url("../images/bg-logo-green.svg");
}

.block .view-sidebar-block .bg-colour-2 {
  background-color: transparent;
  background-image: -owg-linear-gradient(#8dc44a, #53b75c);
  background-image: -webkit-linear-gradient(#8dc44a, #53b75c);
  background-image: -moz-linear-gradient(#8dc44a, #53b75c);
  background-image: -o-linear-gradient(#8dc44a, #53b75c);
  background-image: linear-gradient(#8dc44a, #53b75c);
}

.block .view-sidebar-block .bg-colour-2 .inner {
  background-image: url("../images/bg-logo-dark-green.svg");
  color: #fff;
}

#block-views-images-media-centre .slick__slider {
  background-color: #EEF9FD;
}

#block-views-images-media-centre .field-image-wrap {
  position: relative;
  background-color: #fff;
  padding: 0 20px 26px;
  min-height: 9em;
}

#block-views-images-media-centre h4.field-title {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

#block-views-images-media-centre .field-date {
  background: linear-gradient(to right, #63ae6b 0%, #90ba56 100%);
  position: absolute;
  font-size: 0.8461em;
  line-height: 1.89091em;
  padding: 0 8px;
  color: #fff;
  bottom: 0;
  left: 0;
}

#block-views-images-media-centre .field-body a {
  background-image: url("../images/blue-arrow-left.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 10px 9px;
  font-size: 0.8461em;
  padding-left: 15px;
  margin-left: 3px;
  font-weight: 600;
  text-decoration: none !important;
}

#block-views-images-media-centre .field-body a:hover {
  text-decoration: underline !important;
}

/* Customized third-party CSS */
/**
 * @file
 * Print styling
 *
 * We provide some sane print styling for Drupal using Zen's layout method.
 */
/**
 * By importing this CSS file as media "all", we allow this print file to be
 * aggregated with other stylesheets, for improved front-end performance.
 */
@media print {
  /* Underline all links. */
  a:link,
  a:visited {
    text-decoration: underline !important;
    /* Don't underline header. */
  }
  a:link.header__site-link,
  a:visited.header__site-link {
    text-decoration: none !important;
  }
  #content {
    /* Add visible URL after links. */
    /* Only display useful links. */
    /* Add visible title after abbreviations. */
  }
  #content a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 13px;
  }
  #content a[href^="javascript:"]:after,
  #content a[href^="#"]:after {
    content: "";
  }
  #content abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /* Un-float the content. */
  #content {
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  /* Turn off any background colors or images. */
  body,
  #page,
  #main,
  #content {
    color: #000;
    background-color: transparent !important;
    background-image: none !important;
  }
  /* Hide sidebars and nav elements. */
  #skip-link,
  #toolbar,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .breadcrumb,
  .tabs,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .pager,
  .feed-icons {
    visibility: hidden;
    display: none;
  }
}

/* SMACSS theme rules */
/* @import "theme-A"; */
/* @import "theme-B"; */
