article,
aside,
details,
figcaption,
figure,
footer,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

* { @include box-sizing(border-box); padding: 0; margin: 0;}

html {
  font-family: $base-font-family;
  font-size: 100% * ($base-font-size / 16px);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  // Establish a vertical rhythm unit using $base-line-height.
  @include adjust-leading-to(1);
  word-spacing: -0.05em;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  height: 100%;
}

*:focus {
    outline: none;
}

a:link {

}

a:visited {
}

a:hover,
a:focus {
}

a:active {
}

a:focus {
  outline: thin dotted;
}

a:active,
a:hover {
  outline: 0;
}

p,
pre {
  margin: rhythm(0.5) 0;
}

blockquote {
  margin: rhythm(0.5);
  background-color: #F4F4F4;
  padding: rhythm(0.5)rhythm(1);
}

abbr[title] {
  border-bottom: 1px dotted;
}

#page {
 b,
  strong {
    font-weight: bold;
    color: #1f4ea1;
  }
}

dfn {
  font-style: italic;
}

hr {
  height: 1px;
  border: 1px solid #d7d6db;
  padding-bottom: -1px;
  margin: rhythm(1) 0;
  clear: both;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp,
tt,
var {
  font-family: $font-monospace; // The value of $font-monospace ends with ", serif".
  @include adjust-font-size-to( 1 * $base-font-size );
}

pre {
  white-space: pre-wrap;
}

q {
  quotes: "\201C" "\201D" "\2018" "\2019";
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl,
menu,
ol,
ul {
  margin: rhythm(0.5) 0;
  padding: 0 0 0 0;
}

.node {
  ul {
    list-style-position: outside;
    padding-left: 2em;
    list-style-type: square;
    ul {
      margin: 0;
    }
  }
}

.node {
  ol {
    list-style-position: outside;
    ol {
      margin: 0;
    }
  }
}


dl {
  padding: 0;
}

dd {
  margin: 0 0 0 30px;
}

@if $legacy-support-for-ie7 {
  nav ul,
  nav ol {
    list-style: none;
    list-style-image: none;
  }
}

img {
  border: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  @if $legacy-support-for-ie7 {

    -ms-interpolation-mode: bicubic;
  }
  display: block;
  width: 100%;
  height: auto;
}

.node-1285 {
  .align-right {
    float: right;
    margin: 0 0 1.5em 1.5em;
    width: auto;  
  }
}
.node,
.file {
  img {
      &.align-original {
        width: auto;
      }
  }
  .float-right {
    float: right;
    margin: 0 0 1.5em 1.5em;
    width: auto;
  }
  .align-center {
    text-align: center;
    img {
      display: inline;
      width: auto;
      max-width: 100%;
    }
  }
  @media all and (min-width: 481px) {
    .align-right {
      margin: 0 0 1.5em 1.5em;
    }
  }
}

figure > figcaption {
  padding: .6em 1em;
}

#page {
   img:hover {
    //@include image-hover();
   }
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #d7d6db;
}

legend {
  border: 0;
  padding: 0 3px;
}

.media-vimeo-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0.7979em 1.1986em;
  vertical-align: middle;
  font-size: 0.8461em;
  line-height: 1.5em;
  border: none;
  @include border-radius(0);
  background-color: #EFEFEC;
}

button,
select {
    width: 100%;
  text-transform: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

select {
    background: #EFEFEC url("../images/blue-arrow-down.png") no-repeat scroll right 10px center !important;
    background-size: 10px 9px !important;
}

html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  border: none;
  background-color: #24ace0;
  color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  @if $legacy-support-for-ie7 {
    *overflow: visible;
  }
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  position: relative;
  top: -2px;
  @if $legacy-support-for-ie7 {
    *height: 13px;
    *width: 13px;
  }
}

input[type="search"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  @include box-sizing(content-box);
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  display: none;

  .no-js & {
    display: block;
  }
}

textarea {
  overflow: auto;
  vertical-align: top;
}

label {
  display: block;
  color: #333;
  font-size: 1.1em;
  line-height: 1.6em;
}

#block-mailchimp-signup-subscribe label,
#block-mailchimp-subscribe label {
  display: none;
}

.comment-form label {
  display: inline-block;
  width: 12em;
  margin-bottom: 0.4em;
  font-weight: 600;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  @include leader(0.65);
  @include trailer(0.65);
}

#imce-content {
  img {
    width: auto;
    height: auto;
  }
}

body > #popup{
  display: none;
}
