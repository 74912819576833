/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */

/* Import Sass mixins, variables, Compass modules, etc. */
@import "init";
@import "breakpoints";
@import "colors";
@import "mixins";

/* HTML element (SMACSS base) rules */
@import "normalize";

/* Layout rules */
@import "layout";

/* Typographic rules */
@import "typography";

/* Global form elements */
@import "forms";

/* Component */
@import "components/fields";
@import "components/links";
@import "components/listings";
@import "components/misc";
@import "components/pager";
@import "components/palette";
@import "components/search";
@import "components/service-links";
@import "components/tables";
@import "components/tabs";
@import "components/text-resize";
@import "components/view-modes";
@import "components/views";

/* Paragraphs */
@import "paragraphs/base";
@import "paragraphs/view";

/* Sections */
@import "sections/admin";
@import "sections/base";
@import "sections/blocks";
@import "sections/breadcrumbs";
@import "sections/carousels";
@import "sections/footer";
@import "sections/header";
@import "sections/homepage";
@import "sections/navigation";
@import "sections/page-file";
@import "sections/page-node";
@import "sections/page-user";
@import "sections/sidebar";

/* Customized third-party CSS */

// Optionally, add your own components here.
@import "print";

/* SMACSS theme rules */
/* @import "theme-A"; */
/* @import "theme-B"; */