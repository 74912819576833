/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */

/**
 * Wireframes.
 */
.with-wireframes {
  #header,
  #main,
  #content,
  #navigation,
  .region-sidebar-first,
  .region-sidebar-second,
  #footer,
  .region-bottom {
    outline: 1px solid #ccc;
  }
}

/**
 * Accessibility features.
 */

/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
%element-invisible {
  @include element-invisible;
}

/* Turns off the element-invisible effect. */
%element-invisible-off {
  @include element-invisible-off;
}

.element-focusable,
%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0;

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff;
  }
}



/**
 * Branding header.
 */

a#logo {
    outline: none;
}

/* The link around the name of the website. */
.header__site-link {

  &:link,
  &:visited {
    color: #5c626c;
    outline: none;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: #333;
  }
}

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: right; /* LTR */
}

/**
 * Navigation bar.
 */
#navigation {
  /* Sometimes you want to prevent overlapping with main div. */
  /* overflow: hidden; */

  .block {
    margin-bottom: 0;
  }

  .block-menu .block__title,
  .block-menu-block .block__title {
    @extend %element-invisible;
  }

  /* Main menu and secondary menu links and menu block links. */
  .links,
  .menu {
    margin: 0;
    padding: 0;
    text-align: left; /* LTR */

    li {
      /* A simple method to get navigation links to appear in one line. */
      float: left; /* LTR */
      padding: 0 10px 0 0; /* LTR */
      list-style-type: none;
      list-style-image: none;
    }
  }
}

/**
 * Breadcrumb navigation.
 */
.breadcrumb {
  ol {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

/**
 * Messages.
 */
.messages {
  margin: rhythm(1) 0;
  padding: 10px 10px 10px 50px; /* LTR */
  background-image: inline-image("message-24-ok.png");
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}
.messages--status {
  @extend .messages;
  @extend %ok;
}
.messages--warning {
  @extend .messages;
  @extend %warning;
  background-image: inline-image("message-24-warning.png");
  border-color: #ed5;
}
.messages--error {
  @extend .messages;
  @extend %error;
  background-image: inline-image("message-24-error.png");
  border-color: #ed541d;
}
.messages__list {
  margin: 0;
}
.messages__item {
  list-style-image: none;
}
.messages img {
  width: auto;
}

/* Core/module installation error messages. */
.messages--error  p.error {
  color: #333;
}

/* System status report. */
.ok,
%ok {
  background-color: #f8fff0;
  color: #234600;
}
.warning,
%warning {
  background-color: #fffce5;
  color: #840;
}
.error,
%error {
  background-color: #fef5f1;
  color: #8c2e0b;
}

/**
 * Inline styles.
 */
/* List of links generated by theme_links(). */
.inline { @include inline-list; }

/**
 * "More" links.
 */
.more-link {
  @include bp-nq($medium) {
    text-align: right;
  }
}
.more-help-link {
  text-align: right; /* LTR */
}
.more-help-link a {
  background-image: inline-image("help.png");
  background-position: 0 50%; /* LTR */
  background-repeat: no-repeat;
  padding: 1px 0 1px 20px; /* LTR */
}

/**
 * Titles.
 */

.block__title {
  font-weight: 400;
  margin-bottom: 0;
}

h2.block__title {
  line-height: 1.2em;
   @include adjust-font-size-to(18px);
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}


/**
 * Blocks.
 */

/* The active item in a Drupal menu. */
.menu a.active {
  color: #000;
}

/**
 * Marker.
 */

/* The "new" or "updated" marker. */
.new,
.update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
}

/**
 * Unpublished note.
 */

/* The word "Unpublished" displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #dfcdcd;
   @include adjust-font-size-to(19px);
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  text-align: right;
  word-wrap: break-word;
  margin: 0 auto;
  display: block;
  top: 5px;
  right: 5px;
  position: relative;
}

/**
 * Comments.
 */

/* Wrapper for the list of comments and its title. */
.comments {
  margin: rhythm(1) 0;
}

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea;
}

/* Wrapper for a single comment. */
.comment {

  /* Comment's permalink wrapper. */
  .permalink {
    text-transform: uppercase;
    display: block;
    float: right;
    font-size: 0.9em;
  }
}

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: 30px; /* LTR */
}

/* Buttons used by contrib modules like Media. */
a.button {
  margin-top: 0.5em;
  display: inline-block;
  background: $color-highlight;
  color: $color-highlight-contrast;
  text-decoration: none;
  margin-right: 0.5em;
  padding: 0.5em 1em;

  &:active {
    background: darken($color-highlight, 10%);
  }
}

/* Password confirmation. */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */
#user-login-form {
  text-align: left; /* LTR */
}

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */

/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0;
}

/* The "Log in using OpenID" and "Cancel OpenID login" links. */
.openid-link,
.user-link {
  margin-top: rhythm(1);
}
html.js #user-login-form li.openid-link,
#user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px; /* LTR */
}
#user-login ul {
  margin: rhythm(1) 0;
}

/**
 * Drupal admin tables.
 */
form {
  th {
    text-align: left; /* LTR */
    padding-right: 1em; /* LTR */
    border-bottom: 3px solid #ccc;
  }
  tbody {
    border-top: 1px solid #ccc;
  }
  table ul {
    margin: 0;
  }
}
tr.even,
tr.odd {
  background-color: #eee;
  padding: 0.1em 0.6em;
}
tr.even {
  background-color: #fff;
}

/* Markup generated by theme_tablesort_indicator(). */
td.active {
  background-color: #ddd;
}

/* Center checkboxes inside table cell. */
td.checkbox,
th.checkbox {
  text-align: center;
}

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */

/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: bold;

  .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    @include border-radius(3px);
  }
  .filled {
    background-color: #0072b9;
    background-image: url("../images/progress.gif");
  }
}

// @todo: This is not a good selector.
#region-content .content ul li {
  list-style: disc;
  margin: 0 0 5px 15px;
}

#sweaver {
  // Sweaver forces its toolbar to the left unnecessarily.
  left: inherit;

  .close a,
  .tab a {
    // Fix button heights.
    height: auto !important;
  }

  // Raise it above the IPE when present.
  .panels-ipe & {
    bottom:54px;
  }
}

form#sweaver-frontend > div {

  // Fix an issue where Sweaver would cover the bottom of the page.
  position: static;
  height: 300px;
}


