
#block-views-slideshow-block {
  margin-bottom: 0;

  @include bp-nq($medium) {
    margin-bottom: 1.5em;
  }
}
.view-front-slideshow {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 300px;

      @include bp-nq($xminor) {
        padding-bottom: 300px;
      }
      @include bp-nq($large) {
        padding-bottom: 270px;
      }
      @include bp-nq($xxlarge) {
        padding-bottom: 400px;
      }

  .slick {
     min-height: 300px;
    @include bp-nq($xminor) {
      min-height: 300px;
    }
    @include bp-nq($xlarge) {
      min-height: 270px;
    }
    @include bp-nq($xxlarge) {
      min-height: 400px;
    }
  }
  .slick-track {
    overflow: hidden;
    height: 300px;

    @include bp-nq($xminor) {
      height: 300px;
    }
    @include bp-nq($large) {
      height: 270px;
    }
    @include bp-nq($xxlarge) {
      height: 400px;
    }
  }
  .button {
    @include standard-button();
    padding-left: 2em;
    border-color: #fff;


    &:hover,
    &:active {
      background-color: #fff;
      color: #2164b2;
      opacity: 0.6;
      text-decoration: none !important;
    }
  }
  @include bp-nq($medium) {
    .field-image {
      overflow: hidden;
    }
  }

  ul.slides {
    overflow: hidden;
  }
  .slide__content {
    padding: 0;

    > a {
      display: block;
      height: 300px;

        img {
          height: 100% !important;
          width: auto;
          float: right;
        }

      @include bp-nq($xminor) {
        height: 300px;
      }
      @include bp-nq($large) {
        height: 270px;
      }
      @include bp-nq($xxlarge) {
        height: 400px;
      }


      &.bg-red {
         background: linear-gradient(to right, #C72030, #F58220);
      }
      &.bg-green {
          background: linear-gradient(to right, #4EB857, #8DC63F);
      }
      &.bg-blue {
         background: linear-gradient(to right, #1F4EA2, #27AAE1);
      }
      &.bg-orange {
          background: linear-gradient(to right, #F15A31, #FFCB05);
      }
    }
  }

  .slideshow-pager {
    position: absolute;
    bottom: 20px;
    width: 100%;
    z-index: 1;
    padding-right: 12px;

  }
  .slick-dots {
    display: none;
    float: right;
    padding-right: 1em;
     li {
        float: left;
        margin-left: 0.5em;
        list-style: none;

        button {
        font-size: 1em;
        outline: none;
        overflow: hidden;
        text-indent: -9999px;
         background-color: transparent;
         border: 2px solid #fff;
         height: 1.2307em;
         width: 1.2307em;
         @include border-radius(0.6153em);
         opacity: 0.7;

          &:hover {
           background-color: #fff;
           opacity: 0.5;
          }
        }
        &.slick-active button {
           background-color: #fff;
           opacity: 0.7;
        }
     }
  }
  .front-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .field-title {
    line-height: 1.1em;
    color: #fff;
    margin: 0 0 13px;
     font-size: 2em;
    @include bp-nq($large) {
       font-size: 2.5em;
    }
    @include bp-nq($xxlarge) {
      font-size: 4.2307em;
    }
  }


  .field-subtitle {
    line-height: 1.428em;
    color: #fff;

    @media all and (min-width: 380px) {
      font-size: 1.2307em;
      }

    span {
    display: block;
    height: 79px;
    overflow: hidden;

     .large-fonts & {
      height: 88px;
     }
    }
  }
  @include bp-nq($medium) {
    .more-link {
      float: left;
    }
  }
}

#block-views-explore-jump-menu-block {
  .block-inner {
    background: #efefef;
    padding: 15px 12px;

     .front & {
      display: flex;
     }

     .chosen-single {
      background-color: #fff;
      width: 100%;
     }

    .views-exposed-form .views-exposed-widget {
      padding: 0;
      float: none;
    }

    h2.block__title {
      border-bottom: none;
      background: transparent url("../images/blue-arrow-left.svg") no-repeat scroll 3.75em center;
      background-size: 10px 9px;
      font-weight: 400;
      font-size: 1em;
      line-height: 2.3em;
      margin: 0;

      @include bp-nq($minor) {
        font-size: 1.3846em;
        line-height: 1.5em;
      }

      .front & {
        flex: 0 40%;

       @include bp-nq($medium) {
         flex: 0 41%;
       }
        @include bp-nq($large) {
         flex: 0 50%;
       }
      }
      .front.large-fonts & {
       @include bp-nq($large) {
         flex: 0 40%;
       }
      }
    }
    .block__content {
      flex: 1;
    }
    select {
      border: none;
      @include border-radius(0);
      background-color: #fff !important;
      background-size: 9px 10px;
    }
  }
}

#block-addthis-addthis-block {
  @include bp-nq($medium) {
    margin-bottom: 1em !important;
  }
  h2.block__title {
    border-bottom: none;
    color: #1f4ea1;
    font-size: 1em;
    line-height: 1em;
    margin-top: 0;
     flex: 0 40%;

     @include bp-nq($minor) {
      font-size: 1.2em;
     }
    @include bp-nq($large) {
      font-size: 1.2307em;
       flex: 0 47%;
    }
    .front.large-fonts & {
       @include bp-nq($large) {
         flex: 0 40%;
       }
    }
  }
  .block__content {
    min-height: 40px;
  }
  .block-inner {
    padding: 0 12px;
    display: flex;
    align-items: center;

    @include bp-nq($medium) {
      padding: 0 0 0 12px;
    }
  }
  .addthis_toolbox {
    margin-top: 0.5em;
    a {
      display: inline-block;
      text-align: center;
      margin: 0;
      padding: 0 0.2em;
      border-right: 2px solid #e6e7e9;

      @media all and (min-width: 320px) {
        font-size: 0.85em;
      }
      @media all and (min-width: 370px) {
         font-size: 1.2em;
      }
       @include bp-nq($minor) {
        font-size: 1.4em;
        }
        @media all and (min-width: 540px) {
          font-size: 1.45em;
          padding: 0 0.55em;
        }
        @include bp-nq($medium) {
          font-size: 1.1em;
          padding: 0 0.4em;

          .large-fonts & {
            font-size: 0.9em;
            padding: 0 0.3em;
          }
        }
         @include bp-nq($large) {
          padding: 0 0.6em;
          font-size: 1em;

          .large-fonts & {
            font-size: 1.1em;
           }
         }
         @include bp-nq($xlarge) {
          .large-fonts & {
            font-size: 0.88em;
           }
         }
          .large-fonts & {
         @include bp-nq($large) {
           flex: 0 40%;
         }
        }

       > div,
       > span {
        background: transparent url("../images/icon-facebook.svg") no-repeat scroll center center !important;
        overflow: hidden;
        text-indent: -9999px;
        width: 1.5em !important;
        height: 1.5em !important;
        background-size: 1.3em 1.3em !important;
        display: inline-block;

          &:hover {
           background-size: 1.5em 1.5em !important;
          }
        @include bp-nq($large) {
          width: 1.7em !important;
          height: 1.7em !important;

          background-size: 1.5em 1.5em !important;

          &:hover {
           background-size: 1.7em 1.7em !important;
          }
        }
        @include bp-nq($xlarge) {
          width: 2.35em !important;
          height: 2.35em !important;

          background-size: 1.8em 1.8em !important;

          &:hover {
           background-size: 2.1em 2.1em !important;
          }

          .large-fonts & {
          width: 2.9em !important;
          height: 2.9em !important;

            background-size: 2em 2em !important;

            &:hover {
             background-size: 2.3em 2.3em !important;
            }
          }
        }
      }
      &.addthis_button_twitter > span {
        background-image: url("../images/icon-twitter.svg") !important;
      }
      &.addthis_button_google_plusone_share > span {
        background-image: url("../images/icon-google-plus.svg") !important;
      }
      &.addthis_button_linkedin > span {
        background-image: url("../images/icon-linkedin.svg") !important;
      }
      &.addthis_button_pinterest > div {
        background-image: url("../images/icon-pinterest.svg") !important;

         span {
          display: none;
         }
      }
      &.addthis_button_email {
        border-right: none;
        span {
        background-image: url("../images/icon-email.svg") !important;
        }
      }
    }
  }
}

.region-content-bottom #block-media-events {

  display: none;

  @include bp-nq($medium) {
    display: block;
  }
  h2.block__title {
    font-weight: 300;
    font-size: 2em;
    border-bottom: 1px solid #d7d6db;
    padding: 0;
    line-height: 1.75em;
    margin-top: 0;
    margin-bottom: 0;
    display: block;

      a {
        color: #5c626c;

         &:hover {
          color: #333;
         }
      }
  }
}

#block-views-bulletin-board-stories-block {
  background-color: #f5f9eb;
  position: relative;
  .block-inner {
    .field-feature-image {
      background-color: #fff;

         &:hover img {
      @include image-hover();
     }
    }
  }
  .field-title {
    @include field-title();
  }
  .block-title {
    margin-top: 0;
  }
  .view-footer {
    @include footer-link();
  }
  @include bp-nq($medium) {
  .more-wrap {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
  }
}

#block-views-events-block-6 {
  .views-row {
    display: block;

      &:hover img {
      @include image-hover();
     }
  }
  .block-inner {
    background-color: #f4f4f8;
  }
  h2.block-title {
    margin-top: 0;
    font-size: 1.2307em;
    padding: 8px 0 12px 12px;
    line-height: 2em;
  }
  .field-body {
    @include field-title();
  }
  .view-footer {
    padding: 12px 0 6px;
    a {
      padding-left: 17px;
    }
  }
}

.block {
  margin-bottom: 1.5em;
}

.button-blue {
  @include button-blue();
}

h2.block-title a {
  color: #8cc63e;

    &:hover {
     color: #6db111;
     text-decoration: none;
   }
}

#block-views-statistics-block {

  .views-row {
    .field-text {
      color: #1F4EA0;
      font-size: 1.1538em;
      width: 65%;
      margin-left: 35%;

      @include bp-nq($medium) {
        position: relative;
        top: -15px;

         .large-fonts & {
          top: -24px;
         }
      }
    }
  }

  .button-blue {
    width: 100%;
    position: absolute;
    bottom: -16px;
  }

  .u-faux-block-link__overlay {
    bottom: -16px;
  }

  .u-faux-block-link{
    @include bp-nq($medium) {
      height: 185px;

      .large-fonts & {
        height: 255px;
      }
    }
    @include bp-nq($large) {
      height: 188px;

        .large-fonts & {
        height: 240px;
      }
    }
    &:hover {
      @include image-hover();
    }
  }
}

#block-views-videos-block-1,
#block-views-events-block-1,
#block-views-news-block-4 {
  .slick__arrow {
    @include control-nav();
  }
}

#block-views-videos-block {
  .u-faux-block-link {
    display: flex;

     @include bp-nq($medium) {
      display: block;
     }
     @include bp-nq($large) {
       display: flex;
     }

     .teaser-left {
      padding-right: 0;

       .field-feature-video {
        width: 135px;
        padding-right: 12px;

         @include bp-nq($medium) {
          width: auto;
          padding-right: 0;
          margin-bottom: 0.4em;
         }
         @include bp-nq($large) {
        width: 140px;
        padding-right: 12px;
         margin-bottom: 0;
         }
         @include bp-nq($xlarge) {
          width: 180px;
         }

       }
        img {
          width: 100%;
        }
     }
     .teaser-right {
      flex: 1;
     }
  }
  .views-row-3 {
    display: none;

     @include bp-nq($large) {
      display: flex;
     }
  }
}


#block-twitter-block-1 {
  max-height: 465px;
  overflow-y: scroll;
}

#block-views-latest-news-block-2 {
  margin-bottom: 0;
  .block-inner {
      background-color: #EEF9FD;

      .field-feature-image {
        background-color: #fff;
      }
       h2.block-title {
        border: none;
        padding: 10px 0 2px 12px;
        line-height: 1em;
       }
    }
  .view-content {
    .views-row {
      padding: 9px 0;
      margin: 0 12px;
      border-bottom: 1px solid #fff;
      @media all and (min-width: 290px) {
        display: flex;
        .teaser-left {
          flex: 0 125px;
        }
        .teaser-right {
          flex: 1;
          padding-left: 12px;
        }
      }
    }
    .views-row-last {
      border-bottom: none;
    }
  }
  h3.field-title {
    display: inline;

    a:hover {
      text-decoration: none;
    }
   }
   .field-date {
    color: #24ace0;
    padding-left: 0.3em;
    font-size: 1em;
   }
  .view .view-footer {
    @include footer-link();
   }
  .field-wrap {
    background-color: #fff;
    position: relative;
    width: 120px;
    height: 85px;
    display: flex;
    align-items: center;

     &.icon-media-release img {
      width: 85%;
     }

     img {
      border: none;
      padding: 0;
     }
  }
  .more-wrap {
    padding-bottom: 12px;
  }
}

#block-views-latest-news-block-1 {
  .block-inner {
    background-color: #EEF9FD;

       .field-wrap {
        position: relative;
        font-size: 1.2em;
       }
      .field-feature-image {
        background-color: #fff;
      }
      h3.field-title {
         margin-top: 0.3em;
         margin-bottom: 0.3em;
       }
       .field-body {
         a {
          @include read-more();
         }
       }
      .field-date {
         @include green-box();
        bottom: 0;
        left: 0;
        font-size: 0.72em;
      }
      .views-row {
        @include bp-nq($medium) {
          flex: 0 25%;
        }
      }
  }
}
#block-views-bulletin-board-stories-block {
  .block-inner {

    .views-row {
      @include bp-nq($medium) {
        flex: 0 33.33%;
        width: 33.33%;
        max-width: 33.33%;
      }
      .field-title {
      @include field-title();
      margin-top: 0.3em;
        a {
        @include read-more();
       }
     }
    }
  }
}

#block-views-bulletin-board-stories-block,
#block-views-latest-news-block-1 {

     h2.block-title {
      color: #1f4ea1;
      font-size: 1.2307em;
      line-height: 2em;
      border: none;
      padding: 8px 0 12px 12px;

     }
      .more-link {
      font-size: 0.8461en;
     }
      .view-content {

      @include bp-nq($minor) {

        display: flex;
        flex-wrap: wrap;

        @include bp-nq($medium) {
          flex-wrap: nowrap;
        }
    }
  }
   .views-row {
    position: relative;

       &:hover img {
      @include image-hover();
     }

      @include bp-nq($minor) {
        flex: 0 50%;
      }
      .field-wrap, .field-title, .field-body {
        margin-left: 12px;
        margin-right: 12px;
      }
   }
  .more-wrap {
    padding-top: 0;
  }
}

#block-views-events-block-6 {
  .more-wrap {
    padding: 0 12px 12px;
  }
}
