body {
  color: #231f20;
  background-color: $color-tertiary-light;
}

#content {
  .block ul.menu {
    margin-top: 10px;
    padding-bottom: 13px;
    margin-bottom: 0;
    overflow: hidden;
    list-style: none;
  }

  .region-sidebar-first .block ul.menu ul.menu {
    margin-top: 0;
    padding-bottom: 0;
    font-size: 0.9em;
    line-height: 1.5em;
  }
}