footer .block {
  margin: 0;
  padding-left: rhythm(1);
  padding-right: rhythm(1);
  padding-top: rhythm(0.5);
  padding-bottom: rhythm(0.5);
}

.region-footer .block-menu .menu {
  @extend %inlineLinks--bordered;
  a {
    padding: rhythm(0.5);
  }
  li:first-child a {
    padding-left: 0;
  }
}

.region-footer .block-title {
  @extend %element-invisible;
}

// Layout for footer promos
// Todo: Currently named "footer-teaser" should be changed to "promos" or something later.
#block-views-footer-teaser-block {
  background: #F3F3F3;
  p { margin: 0; }

  .view-footer-teaser {
    @include pie-clearfix;
    @include bp-nq($medium) {
      $zen-column-count:  3;
      @include zen-grid-container();
      .views-row-1 {
        @include zen-clear();
        @include zen-grid-item(1, 1);
        padding-left: 0px;
      }
      .views-row-2 {
        @include zen-grid-item(1, 2);
      }
      .views-row-3 {
        @include zen-grid-item(1, 3);
        padding-right: 0px;
      }
    }
    .views-row {
      margin-top: 10px;
      margin-bottom: 10px;
      position: relative;
      min-height: 90px; // Avoid height not recognizing image.
      .views-field-field-image {
        position: absolute;
        top: 20px;
        right: 10px;
      }
    }
    .views-field-field-description {
      padding-right: 85px;
    }
    // Added border/margin to title to avoid an extra container.
    .views-field-title {
      padding-top: 10px;
      padding-right: 85px;
      border-top: solid 3px #CCCCCC;
      h2 {
        @include adjust-font-size-to(16px);
        @include reset-baseline;
      }
      a {
        @include underlineOnHover;
      }
    }
  }
}


#footer-menu {
  background-color: #f05a31;

  #block-block-7 {
    margin: 0 2em;
    text-align: center;
    p {
      color: #fff;
      padding: 1.5em 0;
      margin: 0;
    }

    @include bp-nq($medium) {
      margin-left: 15%;
      margin-right: 15%;
    }
    @include bp-nq($xlarge) {
      margin-left: 23em;
      margin-right: 23em;
    }
  }
}


#footer-top {
  background-color: #5c626c;
  color: $color-footer-contrast;
  padding-top: 1.5em;

  .block {
    margin-bottom: 0;
  }
  @include bp-nq($medium) {
    .layout-desktop & {
      border-top: 1px solid #fff;
    }
  }

      li.menu-mlid-2664,
       li.menu-mlid-2663 {
        display: none;
       }

  .menu-block-wrapper > ul.menu > li.menu__item {

    > a {
      color: #8cc63e;
      display: block;
      margin-bottom: 5px;
      line-height: 1.3em;
      font-size: 1.0769em;
      @media all and (min-width: 320px) {
        font-size: 1em;
      }

      @media all and (min-width: 390px) {
        padding-right: 1em;
        font-size: 1.0769em;
      }
        &:hover,
        &.active {
          color: #97d740;
        }
      }
    > ul {
      display: none;

      @include bp-nq($large) {
        display: block;
      }
    }
    }
      a {
        text-decoration: none;
        font-weight: 500;
      }

  .menu-block-wrapper > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 1em;
      @media all and (min-width: 320px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 5.9em;
      }
      @include bp-nq($medium) {
        flex-direction: row;
        height: auto;
      }

    > li {
      width: 50%;

      @media all and (min-width: 320px) {
        flex: 1 33.3%;
      }

      @include bp-nq($medium) {

         flex: 1 50%;
        &:nth-child(even) {
          padding-left: 12px;
        }
      }
      @include bp-nq($large) {
         &:nth-child(even) {
          padding-left: 0;
        }
        flex: 0 16.66%;
      }
      position: relative;
      font-weight:bold;
      &.is-expanded ul {
        li {
          display: block;
          width: 100%;
          @include bp-nq($medium) {
            padding-right: 1.5em;
          }

          a {
          color: #eee;
          display: block;
          font-weight: 400;
          font-size: 0.923em;
          line-height: 1.3em;
          padding: 0.15em 0;

          &:hover,
          &.active {
            color: #fff;
          }
        }
        }
      }
    }
  }
}

#footer-message {
  color: #5c626c;
}

#block-menu-menu-footer-sub-menu {
  margin-bottom: 0;
}

.region-footer-second {
  background: transparent url("../images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: auto 164px;
  padding-bottom: 1.5em;
    display: flex;
    flex-wrap: wrap;

  @media all and (min-width: 320px) {
    flex-direction: row;
    padding-bottom: 1em;
  }
  @media all and (max-width: 599px) {
    background-position: right -208px bottom 0;
  }
  @media all and (min-width: 600px) {
    background-size: auto 61%;
    background-position: right bottom;

      .large-fonts & {
        background-size: auto 67%;
      }
  }
  @include bp-nq($large) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  @include bp-nq($large) {
     background-size: auto 92%;
      .large-fonts & {
        background-size: auto 87%;
      }
  }
}

.region-footer-third {
  text-align: center;
}

#footer-bottom {
  padding-top: 10px;
  padding-bottom: 20px;
  position: relative;
  background-color: #efeff0;

  @include bp-nq($medium) {
  padding-top: 15px;
  padding-bottom: 20px;
  }

  .block-inner,
  #footer-message {
    a:hover {
      color: #333;
      text-decoration: none;
    }
  }
}

#block-menu-menu-footer-contact {
  order: 1;
  flex: 1 100%;

   @media all and (min-width: 320px) and (max-width: 767px) {
    flex: 1 50%;
    margin-bottom: 1em;
  }
  @media all and (min-width: 768px) and (max-width: 979px) {
    flex: 0 50%;
  }
  @include bp-nq($large) {
    padding-top: 1em;
   order: 4;
    flex: 1;

  .large-fonts & {
    padding-top: 0;
  }
 }
  ul.menu {
      margin: 0;

    li {
      list-style: none;
      a {
      color: #8cc63e;
      display: block;
      margin-bottom: 5px;
      line-height: 1.3em;
      outline: none;
      @media all and (min-width: 390px) {
        @include adjust-font-size-to(14px);
      }

       &:hover,
       &.active {
        color: #97d740;
       }
      }
    }
  }
}
#block-qmhc-footer-logo {
  padding-bottom: 1em;
  order: 3;

  @include bp-nq($large) {
    order: 1;
  }

   img {
    width: 80px;
    height: 100px;

    @media all and (min-width:320px) {
      width: 90px;
      height: 113px;
    }
     @include bp-nq($large) {
      width: 80px;
      height: 100px;
     }
   }
}

#block-menu-menu-footer-sub-menu {
  ul li {
    display: inline;
    padding: 0 10px;
    border-right: 1px solid #5c626c;

    &:last-child {
      border-right: none;
    }
  }
}


#block-qmhc-footer-address {
   order: 4;
  font-size: 0.923em;
  line-height: 2em;
  color: #eee;

    a {
      color: #fff;
      text-decoration: underline;

       &:hover {
        text-decoration: none;
       }
    }

   flex: 1 20%;
  display: flex;
  align-items: center;

    .block-inner {
      padding: 0 0 0 2em;
    }

  @media (min-width:600px) {
    flex: 1 80%;
    .block-inner {
      padding: 1.5em 2em 2em;
    }
  }
  @include bp-nq($medium) {
    flex: 1 85%;
    order: 3;
  }
   @include bp-nq($large) {
    flex: 0 41.5%;
    order: 2;
     .block-inner {
      padding: 0 0 0 2em;

       .large-fonts & {
           padding: 0 2em;
       }
    }
   }
    @include bp-nq($xlarge) {
       flex: 0 43%;
    }

  .row {
    display: block;
    line-height: 1.5em;

     &:nth-of-type(1) {
      margin-bottom: 5px;
     }
      &:nth-of-type(4) {
         margin-top: 1em;
         font-size: 0.923em;
      }
  }
  p{
  margin-bottom: 0;
  }
}


#block-menu-menu-privacy-disclaimer {
  font-size: 0.8em;

   @media (min-width:600px) {float: right; width: 25%;}
  @include bp-nq($medium) { margin-top: 0;}

   ul {
    margin: 0;

    a {
      color: #404040;

        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }
    }
   }
   @include bp-nq($large) {
    float: left;
    width: 150px;
    margin-left: 12px;
    clear: none;
  }
}

#block-qmhc-footer-social-links {
  padding-bottom: 1em;
     order: 2;
    flex: 1 0 50%;

  @media all and (min-width: 320px) {
    padding-bottom: 1.5em;
     font-size: 1em;
  }
  @include bp-nq($medium) {
      padding-left: 12px;
  }
  @include bp-nq($large) {
    padding-top: 1em;
    padding-left: 0;
     order: 3;
    flex: 0 17%;

    .large-fonts & {
      padding-top: 0;
    }
  }
  span.social-link {
    width: 2.2em;
    float: left;
    margin-right: 1em;

    @include bp-nq($medium) {
      width: 2.666em;
     
      .large-fonts & {
        margin-right: 0.8em;
      }
    }
    @include bp-nq($large) {
      margin-right: 0.4em;
      width: 2.5em;
      .large-fonts & {
        width: 2.2em;
      }
    }
    @include bp-nq($xlarge) {
       margin-right: 1em;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  p {
    color: #8cc63e;
    font-weight: 500;
    margin: 0 0 7px;

    @media all and (min-width: 390px) {
      @include adjust-font-size-to(14px);
    }

    @include bp-nq($medium) {
      font-size: 1.0769em;
    }
  }
  img {
  display: inline;
  vertical-align: middle;
    transition: all .2s ease-in-out;

     &:hover {
      transform: scale(1.1);
     }
  }
}