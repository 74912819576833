@mixin adaptivelyCentered {
  margin: auto;
  @include bp-nq($medium) { max-width: $medium; }
  @include bp-nq($large) { max-width: $large; }
  @include bp-nq($xlarge) { max-width: $xlarge; }
}

.front #content-middle {
  @include bp-nq($medium) {
    display: flex;
    margin: 0 -12px;

    > div {
       flex: 0 50%;
       width: 50%;
       max-width: 50%;
       padding: 0 12px;
    }
  }
  .region-content-middle-first{
    @include bp-nq($medium) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    > div {
      margin-bottom: 1em;

      @include bp-nq($medium) {
         margin-bottom: 0;
      }
    }
  }
  .region-content-middle-second {

    @include bp-nq($medium) {
      display: flex;

      > div {
         width: 50%;
         flex: 0 50%;
         max-width: 50%;
         margin-bottom: 0;

         &:first-child {
          padding-right: 12px;
         }
          &:last-child {
          padding: 0 12px;
         }
      }
    }
  }
}

.front .region-content-bottom {
  margin-top: 1em;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
#page {
  flex: 1;
  position: relative;
}

#footer {
  position: relative;
  z-index: 1000;
}

.not-front #main {
  padding-top: rhythm(0.75);
  @include bp-nq($medium) { padding-top: 32px; clear: both; }
    padding-bottom: 1.5em;
}

.sidebars {
  .block {
    .block-inner {
      @media (max-width: 480px) { height: auto !important; }
       @include bp-nq($xlarge) { height: auto !important; }
    }
  }
  #block-views-about-us-block {
    font-size: 0.9333em;
    line-height: 1.4285em;

    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.front .region-bottom-first {

  @include bp-nq($medium) {
     display: flex;
     margin-bottom: 1.5em;
   .block:nth-of-type(1) {
    flex: 0 74%;
    width: 74%;
    max-width: 74%;
    margin-bottom: 0;
   }
    .block:nth-of-type(2) {
    flex: 1 26%;
    width: 26%;
    max-width: 26%;
    padding: 0 12px;
    margin: 0 -12px;

      @include bp-nq($medium) {
        padding: 0 0 0 24px;
        margin: 0;
      }
   }
 }
}

.region-bottom-second {
    margin: 0 -12px;

  @include bp-nq($medium) {
        display: flex;
    .block {
      flex: 0 33.33%;
      width: 33.33%;
      max-width: 33.33%;
      padding: 0 12px;
    }
  }
}

/**
 * @file
 * Positioning for a responsive layout.
 *
 * Define CSS classes to create a fluid grid layout with optional sidebars
 * depending on whether blocks are placed in the left or right sidebars.
 *
 * This layout uses the Zen Grids plugin for Compass: http://zengrids.com
 */

// We are going to create a fluid grid with 1, 3, or 5 columns and 20px gutters
// between columns (applied as 10px of left/right padding on each column).
$zen-column-count: 1;
$zen-gutter-width:  24px;

// If you need IE6/7 support for box-sizing: border-box (default), see _init.scss
//$zen-box-sizing: content-box;

// You can generate more efficient CSS if you manually apply the
// zen-grid-item-base mixin to all grid items from within a single ruleset.
$zen-auto-include-item-base: false;
// $zen-auto-include-flow-item-base: false;

// Suppress this section of CSS for RTL layouts since it contains no LTR-specific styles.

/**
 * Center the page.
 *
 */
.region-footer-menu,
.header__inner,
.no-sidebars .tabs-primary,
.not-front #main,
.front #content,
.region-navigation .block,
.slideshow-pager-inner,
.not-front .region-hero-first,
.front-slide-inner,
.region-hero-second,
.front .region-content,
.region-bottom-first,
.region-bottom-second,
.breadcrumb,
#footer .region {
  margin-left: auto;
  margin-right: auto;

   @include bp-nq($large) {
    max-width: 980px;
   }
   @include bp-nq($xlarge) {
    max-width: 1175px;
   }
}

.tablet-layout .tabs {
  @include bp-nq($medium) {
  margin-bottom: 2em;
  }
}

.region-footer-menu,
.region-footer-first,
.region-footer-second,
.region-footer-third,
.no-sidebars .tabs-primary,
.header__inner,
.front-slide-inner,
.not-front .region-hero-first,
.not-front .region-hero-second,
.front .region-content,
.region-bottom-first,
.region-bottom-second,
.region-sidebar-first,
.region-sidebar-second,
.region-sidebar-third {
  // See the note about $zen-auto-include-item-base above.

  @include bp-nq($medium) {
    $zen-gutter-width:  40px;
    @include zen-grid-item-base();
  }
  @include bp-nq($xlarge) {
    $zen-gutter-width:  50px;
  }
}


.region-footer-menu,
.header-menu .block__content,
#content,
.not-front .region-hero-first,
.not-front .region-hero-second,
.front-slide-inner,
.region-sidebar-first,
.region-sidebar-second,
.region-sidebar-third,
.region-footer-first,
.region-footer-second,
.region-footer-third,
.region-bottom-first,
.region-bottom-second,
.region-highlight,
.no-sidebars .tabs-primary,
.slideshow-pager-inner,
.header__inner .header-menu .block__content,
#block-superfish-1 {
    $zen-gutter-width: 30px;
   @include zen-grid-item-base();
}

/* Containers for grid items and flow items. */
#header,
#main,
#footer {
  @include zen-grid-container();
}

#block-views-latest-news-block-2 {
  margin-left: -15px;
  margin-right: -15px;
}

@media all and (min-width: 480px) and (max-width: 979px) {

  /* Start a new row and span all 3 columns. */
    .region-sidebar-second {
      clear: both;
    }
}

@media all and (max-width: 767px) {
  .has-sidebar-block .region-sidebar-second {
    height: auto !important;
  }
}

@media all and (min-width: 980px) {
  .has-sidebar-block .region-sidebar-second {
    height: auto !important;
  }
}

/**
 * Use 3 grid columns for smaller screens.
 */
@media all and (min-width: 768px) and (max-width: 979px) {


  .two-sidebars .region-sidebar-second {
    display: flex;
    flex-direction: column;
    height: 30em;
    flex-wrap: wrap;

     > div {
      padding: 0 12px;
     }
  }

  $zen-column-count: 4 !global;

  /**
   * The layout when there is only one sidebar, the left one.
   */
  .sidebar-first {
    /* Span 2 columns, starting in 2nd column from left. */
    #content {
      @include zen-grid-item(3, 2);
    }

    /* Span 1 column, starting in 1st column from left. */
    .region-sidebar-first {
      @include zen-grid-item(1, 1);
    }
  }


  /**
   * The layout when there is only one sidebar, the right one.
   */
  .not-front.sidebar-second {
    /* Span 2 columns, starting in 1st column from left. */
    #content {
      @include zen-grid-item(3, 1);
      padding-right: 0;
    }

    /* Span 1 column, starting in 3rd column from left. */
    .region-sidebar-second,
    .region-sidebar-third {
      @include zen-grid-item(1, 4);
      margin: 0;
      float: right;
      clear: right;
    }
  }

  /**
   * The layout when there are two sidebars.
   */
  .two-sidebars {
    /* Span 2 columns, starting in 2nd column from left. */
    #highlight,
    #content {
      @include zen-grid-item(3, 2);
      padding-right: 25px;
      padding-left: 12px;
    }

    /* Span 1 column, starting in 1st column from left. */
    .region-sidebar-first {
      @include zen-grid-item(1, 1);
    }
    /* Span 1 column, starting in 1st column from left. */
    .region-sidebar-second {
      @include zen-grid-item(4, 1);
    }
  }
}


/**
 * Use 10 grid columns for large screens
 */
@media all and (min-width: 980px) and (max-width: 1174px) {

  $zen-column-count: 10 !global;

    .front {
    &.sidebar-second {
      /* Span 10 columns, starting in 1st column from left. */
      #content {
        @include zen-grid-item(10, 1);
      }
    }
  }


  /**
   * The layout when there is only one sidebar, the left one.
   */
  .sidebar-first {
    /* Span 4 columns, starting in 2nd column from left. */
    #content {
      @include zen-grid-item(8, 3);
      padding-left: 12px;
    }

    /* Span 1 column, starting in 1st column from left. */
    .region-sidebar-first {
      @include zen-grid-item(2, 1);
    }
  }


  /**
   * The layout when there is only one sidebar, the right one.
   */
  .sidebar-second {
    /* Span 4 columns, starting in 1st column from left. */
    #content {
      @include zen-grid-item(7.5, 1);
      padding-right: 12px;
    }

    /* Span 1 column, starting in 5th column from left. */
    .region-sidebar-second,
    .region-sidebar-third {
      @include zen-grid-item(2.5, 8.5);
      margin: 0;
      float: right;
      clear: right;
    }
    .region-sidebar-third {
       margin-top: 3em;
    }
  }

  /**
   * The layout when there are two sidebars.
   */
  .two-sidebars {
    /* Span 3 columns, starting in 2nd column from left. */
    #content {
      @include zen-grid-item(5, 3.5);
      padding: 0 12px;
    }

    /* Span 1 column, starting in 1st column from left. */
    .region-sidebar-first {
      @include zen-grid-item(2.5, 1);
    }

    /* Span 1 column, starting in 5th column from left. */
    .region-sidebar-second,
    .region-sidebar-third {
      @include zen-grid-item(2.5, 8.5);
      margin: 0;
      float: right;
      clear: right;
    }
    .region-sidebar-second {
       margin-top: 3em;
    }
  }
}


/**
 * Use 10 grid columns for x-large screen
 */
@media all and (min-width: 1175px) {

  $zen-column-count: 10 !global;

  /**
   * The layout when there is only one sidebar, the left one.
   */
  .sidebar-first {
    /* Span 4 columns, starting in 2nd column from left. */
    #content {
      @include zen-grid-item(8, 3);
      padding-left: 12px;
    }

    /* Span 1 column, starting in 1st column from left. */
    .region-sidebar-first {
      @include zen-grid-item(2, 1);
    }
  }

  /**
   * The layout when there is only one sidebar, the right one.
   */
  .sidebar-second {
    /* Span 4 columns, starting in 1st column from left. */
    #content {
      @include zen-grid-item(7.5, 1);
      padding-right: 12px;
    }

    /* Span 1 column, starting in 5th column from left. */
    .region-sidebar-second,
    .region-sidebar-third {
      @include zen-grid-item(2.5, 8.5);
      clear: none;
      margin: 0;
      float: right;
      clear: right;
    }
  }

  /**
   * The layout when there are two sidebars.
   */
  .two-sidebars {
    /* Span 3 columns, starting in 2nd column from left. */
    #content {
      @include zen-grid-item(5, 3.5);
      padding: 0 12px;
    }

    /* Span 1 column, starting in 1st column from left. */
    .region-sidebar-first {
      @include zen-grid-item(2.5, 1);
    }

    /* Span 1 column, starting in 5th column from left. */
    .region-sidebar-second,
    .region-sidebar-third {
      @include zen-grid-item(2.5, 8.5);
      margin: 0;
      float: right;
      clear: right;
    }
    .region-sidebar-second {
      margin-top: 3em;
    }
    .region-sidebar-third {
      margin-bottom: 3em;
    }
  }
}